import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import style from './WithdrawMoney.module.scss';
import { ReactComponent as Bitcoin } from '../../assets/image/common/bitcoinCurrency.svg';

import CurrencyInput from '../CurrencyInput/CurrencyInput';
import useWindowSize from '../../hooks/useWindowSize';

/**
 * Represents Withdraw money component
 * @returns {JSX.Element}
 */

const WithdrawMoney = () => {
  const { t } = useTranslation();
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [amount, setAmount] = useState('0.0002');
  const { isMobile } = useWindowSize();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'withdrawAddress') {
      setWithdrawAddress(value);
    }

    if (name === 'amount' && (/^\d+(\.?\d*)?$/.test(value) || value === '')) {
      setAmount(value);
    }
  };

  const setMaxValue = () => {
    setAmount('100');
  };

  return (
    <form className={style.withdrawForm}>
      <div className={style.inputWrapper}>
        <CurrencyInput />
        <div>
          <p className={style.textLabel}>{t('comission')}</p>
          <p className={style.commissionInput}>5%</p>
        </div>
      </div>
      <div className={style.addressBlock}>
        <p className={style.textLabel}>BTC address</p>
        <input
          type='text'
          className={style.addressInput}
          value={withdrawAddress}
          onChange={handleChange}
          inputMode='text'
          name='withdrawAddress'
          autoComplete='off'
        />
      </div>
      <div className={style.amountWrapper}>
        <div>
          <p className={style.textLabel}>Amount (Min 0.0002, Max 100)</p>
          <div className={style.numberInput}>
            <Bitcoin className={style.currencyIcon} />
            <input
              className={style.amountInput}
              type='text'
              value={amount}
              name='amount'
              onChange={handleChange}
              autoComplete='off'
            />
          </div>
        </div>
        {!isMobile && (
          <button
            type='button'
            className={cn(style.withdrawButton, style.maxButton)}
            onClick={setMaxValue}
          >
            {t('max')}
          </button>
        )}
      </div>
      <button type='button' className={cn(style.withdrawButton, style.requestButton)}>
        {t('request')}
      </button>
      {isMobile && <p className={style.mobileText}>{t('withdraw_text')}</p>}
    </form>
  );
};

export default WithdrawMoney;
