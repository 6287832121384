import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import style from './NavigationMobile.module.scss';
import { ReactComponent as Main } from '../../../assets/image/common/main.svg';
import { ReactComponent as Trades } from '../../../assets/image/common/trades.svg';
import { ReactComponent as Markets } from '../../../assets/image/common/markets.svg';
import { ReactComponent as Balance } from '../../../assets/image/common/balance.svg';
import { ReactComponent as Profile } from '../../../assets/image/common/profile.svg';

/**
 * Represents Navigation in footer on Mobile version
 * @returns {JSX.Element}
 */

const NavigationMobile = () => {
  const { t } = useTranslation();

  return (
    <nav className={style.navigation}>
      <ul className={style.list}>
        <li className={style.item}>
          <NavLink to='/' className={style.link} activeClassName={style.active} exact>
            <Main />
            <p>{t('main')}</p>
          </NavLink>
        </li>
        <li className={style.item}>
          <NavLink
            to='/trades/active'
            className={style.link}
            activeClassName={style.active}
            isActive={(_, { pathname }) => /^\/trades\/\w*/.test(pathname)}
            exact
          >
            <Trades />
            <p>{t('trades')}</p>
          </NavLink>
        </li>
        <li className={style.item}>
          <NavLink
            to='/products/crypto'
            className={style.link}
            activeClassName={style.active}
            isActive={(_, { pathname }) => /^\/products\/\w*/.test(pathname)}
            exact
          >
            <Markets />
            <p>Markets</p>
          </NavLink>
        </li>
        <li className={style.item}>
          <NavLink
            to='/balance'
            className={style.link}
            activeClassName={style.active}
            isActive={(_, { pathname }) => pathname.includes('balance')}
            exact
          >
            <Balance />
            <p>{t('balance')}</p>
          </NavLink>
        </li>
        <li className={style.item}>
          <NavLink
            to='/profile'
            className={style.link}
            activeClassName={style.active}
            isActive={(_, { pathname }) => pathname.includes('profile')}
            exact
          >
            <Profile />
            <p>{t('profile')}</p>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationMobile;
