import React from 'react';
import cn from 'classnames';
import style from './ButtonWithIcon.module.scss';
import Button from '../Button/Button';
import emptyFunction from '../../../util/emptyFunction';

/**
 * Represents Button with icon
 * @param {string} text - the name of button
 * @param {CSSStyleRule} iconClassName - className for icon
 * @param {CSSStyleRule} buttonClassName - className for button
 * @param {CSSStyleRule} wrapperClassName - className for button wrapper
 * @param {function} onClick - click handler
 * @param {JSX.Element} Icon - icon for button
 * @param {SizeType} size - button size
 * @param {ColorType} color - color for button
 * @param {WidthType} width - width for button
 * @param {ref} wrapperRef - ref for wrapper element
 * @param {boolean} disabled - disable
 * @returns {JSX.Element}
 */
const ButtonWithIcon = ({
  text = '',
  iconClassName,
  buttonClassName,
  wrapperClassName,
  onClick,
  icon: Icon,
  size = 'large',
  color = 'default',
  width = 'normal',
  wrapperRef,
  disabled,
}) => {
  return (
    <div
      ref={wrapperRef}
      role='button'
      onKeyDown={emptyFunction}
      className={cn(style.buttonWithIcon, wrapperClassName)}
      onClick={onClick}
      tabIndex={0}
    >
      <div className={cn(style.leftIcon, iconClassName)}>
        <Icon />
      </div>
      <Button
        text={text}
        size={size}
        color={color}
        width={width}
        className={cn(style.button, buttonClassName)}
        fontWeight='bold'
        disabled={disabled}
      />
    </div>
  );
};

export default ButtonWithIcon;
