import React from 'react';
import style from './ProductItemTraderSentiment.module.scss';
import TraderSentiment from '../../../../../components/TraderSentiment/TraderSentiment';

/**
 * Represents Trader sentiment tab in Instrument details on Mobile version
 * @returns {JSX.Element}
 */

const ProductItemTraderSentiment = () => {
  return <TraderSentiment className={style.traderSentiment} />;
};

export default ProductItemTraderSentiment;
