import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './ActiveSessions.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import ActiveSession from '../../../../pages/Profile/ActiveSession/ActiveSession';

/**
 * Represents Active sessions component in Mobile version
 * @returns {JSX.Element}
 */

const ActiveSessions = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/profile' />
        <p className={style.title}>{t('sessions')}</p>
      </div>
      <ActiveSession />
    </div>
  );
};

export default ActiveSessions;
