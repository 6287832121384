import React from 'react';
import style from './CoinFormat.module.scss';
import string from '../../util/string';
import { ReactComponent as Coin } from '../../assets/image/common/coin.svg';

/**
 * Represents Coin Format
 * @param {number} value - value for formatting
 * @param {boolean} withoutSign - is value with/without sign ( + or - ) before value
 * @returns {JSX.Element}
 */

const CoinFormat = ({ value, withoutSign }) => {
  return (
    <p className={style.quantityCoin}>
      <span>{string.formatMoney(value, { withoutSign: withoutSign })}</span>
      <Coin />
    </p>
  );
};

export default CoinFormat;
