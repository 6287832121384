import React from 'react';
import cn from 'classnames';
import style from './MenuItem.module.scss';

/**
 * Represents button for menu
 * @param {string} text - text for the button
 * @param {JSX.Element} IconLeft - left icon for the button
 * @param {CSSStyleRule} iconLeftClassName - className for button's left icon
 * @param {JSX.Element} ElementRight - right element for button
 * @param {CSSStyleRule} elementRightClassName - className for button's right element
 * @returns {JSX.Element}
 */
const MenuItem = ({
  text = '',
  iconLeft: IconLeft,
  iconLeftClassName,
  elementRight: ElementRight,
  elementRightClassName,
}) => {
  return (
    <div className={style.menuButton}>
      <div className={cn(style.textWithIconWrapper, iconLeftClassName)}>
        {IconLeft && <IconLeft />}
        <span className={style.text}>{text}</span>
      </div>
      <div className={cn(elementRightClassName)}>{ElementRight && <ElementRight />}</div>
    </div>
  );
};

export default MenuItem;
