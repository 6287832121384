import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import numeral from 'numeral';
import { CSSTransition } from 'react-transition-group';

import style from './NumberInputWithDropdown.module.scss';
import useOnClick from '../../../hooks/useOnClick';
import getAnimationClasses from '../../../util/getAnimationClasses';
import useChangeCursor from '../../../hooks/useChangeCursor';
import useWindowSize from '../../../hooks/useWindowSize';

import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDown.svg';
import { ReactComponent as Coin } from '../../../assets/image/common/coin.svg';
import { useRootModel } from '../../../models/RootStore';
import CoinFormat from '../../CoinFormat/CoinFormat';

/**
 * Represents Number input with dropdown
 * @param {string} id - id for label and input
 * @param {function} onChange - onChange handler
 * @param {boolean} label - is label
 * @param {string} labelTitle - label title
 * @param {CSSStyleRule} wrapperClassName - wrapperClassName for wrapper div
 * @returns {JSX.Element}
 */

const NumberInputWithDropdown = ({ id, onChange, label, labelTitle, wrapperClassName }) => {
  const { isMobile } = useWindowSize();
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const refs = useRef({});

  const [showContent, setShowContent] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const [inputValue, setInputValue] = useState('');

  const items = [100, 500, 1000, 2500, 5000, 10000, 15000, 20000, 25000, 30000, 35000];

  const {
    finance: {
      trade: { setIncreaseAmount },
    },
  } = useRootModel();

  const setCurrentValue = (val) => {
    setCurrentItem(String(val));
    setInputValue(String(val));
  };
  const toggleShowContent = () => setShowContent((prev) => !prev);
  const closeMenu = () => setShowContent(false);
  const focusFirstElement = () => {
    const elem = refs.current[0];
    if (elem) {
      elem.focus();
      setCurrentValue(elem.textContent);
    }
  };

  useOnClick(wrapperRef, closeMenu);

  useEffect(() => {
    if (onChange) onChange(currentItem);
  }, [currentItem]);

  const handleWrapperKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        refs.current[0]?.focus();
        e.preventDefault();
        break;
      case 'Enter':
        toggleShowContent();
        if (!showContent) inputRef.current.focus();
        break;
      case 'Escape':
        closeMenu();
        break;
      default:
    }
  };

  const handleInputClick = (e) => {
    setShowContent(true);
    e.stopPropagation();
  };

  const handleInputKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        break;
      case 'ArrowDown':
        if (showContent) {
          e.preventDefault();
          focusFirstElement();
        }
        break;
      case 'Escape':
      case 'Tab':
        closeMenu();
        break;

      default:
    }
  };

  const handleItemChange = (e, val) => {
    e.stopPropagation();

    if (onChange) onChange(val);
    setCurrentItem(val);
    setInputValue(numeral(val).format('0,0.00'));
    closeMenu();
  };

  const handleInputFocus = () => {
    if (isMobile) inputRef.current.scrollIntoView();
  };

  const handleInputOnChange = (e) => {
    const { value } = e.target;

    const formattedValue = value.replace(/[$,]/g, '');

    if (!(/^\d+(\.?\d{0,2})?$/.test(formattedValue) || formattedValue === '')) return;

    setInputValue(formattedValue);
    if (onChange) onChange(formattedValue);
  };

  const handleInputBlur = (e) => {
    const { value } = e.target;

    setInputValue(numeral(value).format('0,00.00'));
  };

  useEffect(() => {
    setIncreaseAmount(Number(inputValue.replaceAll(',', '')));
  }, [inputValue]);

  return (
    <div className={cn(style.wrapper, wrapperClassName)}>
      {label ? <label htmlFor={id}>{labelTitle}</label> : ''}
      <div
        role='button'
        className={style.dropdown}
        tabIndex={-1}
        onClick={toggleShowContent}
        onKeyDown={handleWrapperKeyDown}
        ref={wrapperRef}
      >
        <div className={style.value}>
          <p className={style.icon}>
            <Coin />
          </p>
          <input
            id={id}
            type='text'
            value={inputValue}
            className={style.input}
            onChange={handleInputOnChange}
            onClick={handleInputClick}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            onFocus={handleInputFocus}
            ref={inputRef}
            placeholder='0.00'
            inputMode='numeric'
            autoComplete='off'
          />

          <button type='button' tabIndex={-1} className={cn(style.arrow)}>
            {showContent ? <ArrowUp /> : <ArrowDown />}
          </button>
        </div>
        <CSSTransition
          timeout={700}
          in={showContent}
          classNames={getAnimationClasses(style)}
          unmountOnExit
        >
          <div
            role='menu'
            className={cn(style.dropdownContent, { [style.showContent]: showContent })}
          >
            {items.map((item, idx) => {
              return (
                <button
                  key={item}
                  type='button'
                  ref={(elem) => {
                    refs.current[idx] = elem;
                  }}
                  tabIndex={-1}
                  className={cn(style.item)}
                  onKeyDown={(e) => useChangeCursor(e, idx, refs, closeMenu, setCurrentValue)}
                  onClick={(e) => handleItemChange(e, item)}
                >
                  <CoinFormat key={item} value={item} withoutSign={true} />
                </button>
              );
            })}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default observer(NumberInputWithDropdown);
