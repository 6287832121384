import React from 'react';
import cn from 'classnames';
import style from './ModalHeader.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/image/common/close-circle.svg';

/**
 * Represents Modal header
 * @param {string} title - title for header
 * @param {function} handleClose - close handler
 * @param {CSSStyleRule} wrapperClassName - class name for wrapper
 * @returns {JSX.Element}
 */

const ModalHeader = ({ title, handleClose, wrapperClassName }) => {
  return (
    <div className={cn(style.header, wrapperClassName)}>
      <p className={style.text}>{title}</p>
      <button type='button' onClick={handleClose}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default ModalHeader;
