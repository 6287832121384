import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import style from './TableTitle.module.scss';

import { ReactComponent as ArrowUp } from '../../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/image/common/arrowDown.svg';
import emptyFunction from '../../../../util/emptyFunction';

/**
 * Represents title for table with arrows up and down
 * @param {string} title - title
 * @param {function} handleOnClick - click handler
 * @param {string} labelKey - title key for sorting
 * @param {function} setSortDirection - callback for set sort direction
 * @param {('asc' | 'desc')} sortDirection - sort direction
 * @param {function} switchSortDirection - callback for switch sort direction
 * @param {boolean} isActive - is active column
 * @param {CSSStyleRule} className - className for title
 * @returns {JSX.Element}
 */
const TableTitle = ({
  title = '',
  handleOnClick,
  labelKey = '',
  setSortDirection = emptyFunction,
  sortDirection,
  switchSortDirection = emptyFunction,
  isActive,
  className,
}) => {
  const [clickCounter, setClickCounter] = useState(1);
  const handleSwitch = () => {
    if (clickCounter === 1 && !isActive) {
      setClickCounter(2);
      setSortDirection('asc');
    }

    if (clickCounter === 2) {
      switchSortDirection();
      setClickCounter(3);
    }

    const setDefault = () => {
      handleOnClick('');
      setClickCounter(1);
    };

    clickCounter === 3 ? setDefault() : handleOnClick(labelKey);
  };

  const isSortUp = sortDirection === 'asc';
  const isSortDown = sortDirection === 'desc';

  useEffect(() => {
    if (!isActive) setClickCounter(1);
  }, [isActive]);

  return (
    <div className={style.tableTitleWithSorting}>
      <button
        type='button'
        className={cn(style.title, className, { [style.activeTitle]: isActive })}
        onClick={handleSwitch}
      >
        {title}
      </button>
      <p className={cn(style.button, style.arrowUp, { [style.active]: isActive && isSortUp })}>
        <ArrowUp />
      </p>
      <p className={cn(style.button, style.arrowDown, { [style.active]: isActive && isSortDown })}>
        <ArrowDown />
      </p>
    </div>
  );
};

export default TableTitle;
