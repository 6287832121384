import React from 'react';
import cn from 'classnames';

import style from './CurrentBalanceItem.module.scss';
// import string from '../../../util/string';
import CoinFormat from '../../CoinFormat/CoinFormat';

/**
 * Represents CurrentBalanceItem
 * @param {string} title - item's title
 * @param {string} value - item's value
 * @param {CSSStyleRule} itemClassName - item className
 * @param {CSSStyleRule} valueClassName - value className
 * @param {CSSStyleRule} titleClassName - title className
 * @returns {JSX.Element}
 */
const CurrentBalanceItem = ({ title, value, itemClassName, valueClassName, titleClassName }) => {
  return (
    <div className={cn(style.item, itemClassName)}>
      <span className={cn(style.title, titleClassName)}>{title}</span>
      <div className={cn(style.value, value > 0 ? style.positive : style.negative, valueClassName)}>
        <CoinFormat value={value} withoutSign={value >= 0} />
      </div>
    </div>
  );
};

export default CurrentBalanceItem;
