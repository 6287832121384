import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import useWindowSize from '../../../../hooks/useWindowSize';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import CancelTradeContent from './CancelTradeContent/CancelTradeContent';

/**
 * Represents CancelTradeModal component
 * @returns {JSX.Element}
 * @constructor
 */
const CancelTradeModal = () => {
  const { isLaptop } = useWindowSize();
  const { t } = useTranslation();
  const {
    modal: {
      cancelTrade: { isOpen, close },
    },
  } = useRootModel();
  return (
    <>
      {isOpen && (
        <Modal
          wrapperClassname={style.modalWrapper}
          handleClose={close}
          header={isLaptop}
          headerTitle={`${t('cancel_order')}?`}
        >
          <ModalBody className={style.modal}>
            <CancelTradeContent handleClose={close} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(CancelTradeModal);
