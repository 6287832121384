import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useRootModel } from '../../../../../../models/RootStore';
import style from './OpenTradeButtons.module.scss';
import AlertContext from '../../../../../../context/alert/alertContext';

import { ReactComponent as Down } from '../../../../../../assets/image/common/curveDown.svg';
import { ReactComponent as Up } from '../../../../../../assets/image/common/curveUp.svg';

const OpenTradeButtons = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    modal: {
      openTrade: { close },
    },
    finance: {
      item,
      trade: { amount, setOperation, openTrade },
    },
  } = useRootModel();

  const handleBuy = async () => {
    try {
      await setOperation('BUY');
      openTrade();
      alert.show(t('trade_open'), 'success');
      close();
    } catch (err) {
      alert.show(err.message);
    }
  };

  const handleSell = async () => {
    try {
      await setOperation('SELL');
      openTrade();
      alert.show(t('trade_open'), 'success');
      close();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.footerBlock}>
      <div className={style.buttonContainer}>
        <button
          type='button'
          className={cn(style.button, style.red)}
          onClick={handleSell}
          disabled={amount < 1}
        >
          <p>Sell</p>
          <Down />
        </button>
        <button
          type='button'
          className={cn(style.green, style.button)}
          onClick={handleBuy}
          disabled={amount < 1}
        >
          <p>Buy</p>
          <Up />
        </button>
      </div>
      <div className={style.footer}>
        <p>Bid: {item?.bid}</p>
        <p className={style.greyColor}>Spread: {Number((item?.bid - item?.ask).toFixed(10))}</p>
        <p>Ask: {item?.ask}</p>
      </div>
    </div>
  );
};

export default observer(OpenTradeButtons);
