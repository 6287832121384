import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './ActiveTradesItemInfo.module.scss';
import emptyFunction from '../../../../../../util/emptyFunction';

import { ReactComponent as Chart } from '../../../../../../assets/image/common/chart.svg';
import { ReactComponent as InfoPic } from '../../../../../../assets/image/common/moreInfo.svg';
import { ReactComponent as Close } from '../../../../../../assets/image/common/closeInCircle.svg';

/**
 * Represents ActiveTradesItemInfo component
 * @param {function} onClickIncrease - onClickIncrease handler
 * @param {function} onClickInfo - onClickInfo handler
 * @param {function} onClickCloseTrade - onClickCloseTrade handler
 * @returns {JSX.Element}
 */
const ActiveTradesItemInfo = ({ onClickIncrease, onClickInfo, onClickCloseTrade }) => {
  const { t } = useTranslation();

  return (
    <div className={style.info}>
      <div
        role='button'
        tabIndex={-1}
        onKeyDown={emptyFunction}
        onClick={onClickIncrease}
        className={style.buttonBlock}
      >
        <Chart />
        <p className={style.text}>{t('increase')}</p>
      </div>
      <div
        role='button'
        tabIndex={-1}
        onKeyDown={emptyFunction}
        onClick={onClickInfo}
        className={cn(style.buttonBlock, style.buttonBlock__borders)}
      >
        <InfoPic />
        <p className={style.text}>{t('info')}</p>
      </div>
      <div
        role='button'
        tabIndex={-1}
        onKeyDown={emptyFunction}
        onClick={onClickCloseTrade}
        className={style.buttonBlock}
      >
        <Close />
        <p className={style.text}>{t('close_trade')}</p>
      </div>
    </div>
  );
};

export default ActiveTradesItemInfo;
