import React, { useState } from 'react';
import cn from 'classnames';
import style from './TabsOnMainPage.module.scss';

/**
 * Represents Tabs component for Mobile version
 * @returns {JSX.Element}
 */

const TabsOnMainPage = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const onTabSelected = (id) => {
    setActiveTab(id);
  };

  return (
    <>
      <nav className={style.navigation}>
        {tabs.map(({ id, tabLabel }, i) => (
          <button
            type='button'
            role='tab'
            key={id}
            className={cn(style.button, { [style.active]: activeTab === id })}
            onClick={() => onTabSelected(i)}
          >
            {tabLabel}
          </button>
        ))}
      </nav>
      {tabs[activeTab].component}
    </>
  );
};

export default TabsOnMainPage;
