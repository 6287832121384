import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import style from './BalancePage.module.scss';
import CurrentBalance from '../../../components/CurrentBalance/CurrentBalance';
import { ReactComponent as ArrowIcon } from '../../../assets/image/common/arrowRight.svg';

/**
 * Represents Balance page in Mobile version
 * @returns {JSX.Element}
 */

const BalancePage = () => {
  const { t } = useTranslation();
  return (
    <section className={style.section}>
      <h3 className={style.title}>{t('balance')}</h3>
      <CurrentBalance className={style.currentBalance} />
      <nav>
        <Link to='/balance/deposit' className={style.item}>
          {t('deposit_money')}
          <ArrowIcon />
        </Link>
        <Link to='/balance/withdraw' className={style.item}>
          {t('withdraw_money')}
          <ArrowIcon />
        </Link>
        <Link to='/balance/payment' className={style.item}>
          {t('payment_history')}
          <ArrowIcon />
        </Link>
      </nav>
    </section>
  );
};

export default BalancePage;
