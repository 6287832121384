import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './ActiveTradesContent.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import useWindowSize from '../../../../../hooks/useWindowSize';

import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';
import { ReactComponent as Dots } from '../../../../../assets/image/common/dots.svg';
import { ReactComponent as Close } from '../../../../../assets/image/common/closeInCircleWhite.svg';
import { ReactComponent as Dollar } from '../../../../../assets/image/common/dollar.svg';
import { ReactComponent as Plus } from '../../../../../assets/image/common/plusInCircle.svg';
import TabsForModal from '../../../../ui/TabsForModal/TabsForModal';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import CommissionsAndReports from './CommissionsAndReports/CommissionsAndReports';
import Dropdown from '../../../../ui/Dropdown/Dropdown';
import TVChartForModal from '../../../../TVChartForModal/TVChartForModal';

/**
 * Represents Active Trade Content in Modal after click on Active trades block
 * @param {function} handleClose - handle close Modal button
 * @returns {JSX.Element}
 */
const ActiveTradesContent = ({ handleClose }) => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const {
    modal: { activeTrade, increaseTrade, openTrade, closeTrade },
  } = useRootModel();

  const tabs = [
    {
      id: 1,
      tabLabel: t('general_information'),
      component: GeneralInformation,
    },
    {
      id: 2,
      tabLabel: t('commissions_and_reports'),
      component: CommissionsAndReports,
    },
  ];

  const MainComponent = () => (
    <button type='button' className={style.secondaryButton}>
      <Dots />
    </button>
  );

  const openIncreaseTradeContent = () => {
    increaseTrade.open();
    activeTrade.close();
  };

  const openOpenTradeContent = () => {
    openTrade.open();
    activeTrade.close();
  };

  const openCloseTradesContent = () => {
    closeTrade.open();
    activeTrade.close();
  };

  return (
    <div className={style.wrapper}>
      <div className={style.tvChartModal}>
        <TVChartForModal />
      </div>
      <div className={style.container}>
        {isDesktop && (
          <button type='button' onClick={handleClose} className={style.closeButton}>
            <CloseIcon />
          </button>
        )}

        <TabsForModal
          tabs={tabs}
          className={style.tabsWrapper}
          itemClassName={style.itemTabs}
          textClassName={style.text}
        />

        <div className={style.buttonContainer}>
          <button
            type='button'
            className={cn(style.button, style.red)}
            onClick={openCloseTradesContent}
          >
            <Close />
            <p>{t('close_trade_position')}</p>
          </button>

          <Dropdown
            mainComponent={MainComponent}
            changeMainComponent={false}
            showArrow={false}
            sideToOpen='up'
            className={style.dropdownButtonClass}
            itemClassName={style.item}
            contentClassName={style.content}
          >
            <button
              type='button'
              className={style.dropdownButton}
              onClick={openIncreaseTradeContent}
            >
              <Dollar />
              <p className={style.buttonText}>{t('increase_trade')}</p>
            </button>
            <button type='button' className={style.dropdownButton} onClick={openOpenTradeContent}>
              <Plus />
              <p className={style.buttonText}>{t('new_trade_position')}</p>
            </button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default observer(ActiveTradesContent);
