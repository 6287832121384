import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './LimitsMobile.module.scss';
import { useRootModel } from '../../../../../../models/RootStore';
import Modal from '../../../../../Modal/Modal';
import ModalBody from '../../../../../Modal/ModalBody/ModalBody';
// eslint-disable-next-line max-len
import LimitProfitsAndLostContent from '../../../../../LimitProfitsAndLost/LimitProfitAndLostContent/LimitProfitAndLostContent';
import Button from '../../../../../ui/Button/Button';

const LimitsMobile = ({ setStatus }) => {
  const { t } = useTranslation();
  const {
    finance: {
      trade: { amount, setLoss, setProfit },
    },
  } = useRootModel();

  const handleCloseModal = () => {
    setProfit(null);
    setLoss(null);
    setStatus(false);
  };

  const handleSetLimits = () => {
    setStatus(false);
  };

  return (
    <Modal>
      <ModalBody className={style.modal}>
        <p className={style.modalHeader}>Limit profits and loss</p>
        <div className={style.content}>
          <LimitProfitsAndLostContent amount={amount} />
        </div>
        <div className={style.buttonBlock}>
          <Button text={t('cancel')} color='secondary' onClick={handleCloseModal} />
          <Button text={t('set')} color='primary' onClick={handleSetLimits} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(LimitsMobile);
