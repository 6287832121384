import React from 'react';
import cn from 'classnames';

import style from './FavoritesButton.module.scss';
import { ReactComponent as EmptyStarIcon } from '../../../assets/image/common/starEmpty.svg';
import { ReactComponent as StarIcon } from '../../../assets/image/common/star.svg';
import { ReactComponent as Preloader } from '../../../assets/image/common/preloaderMini.svg';

/**
 * Represents FavoritesButton component
 * @param {CSSStyleRule} className - button className
 * @param {function} onClick - onClick handler
 * @param {boolean} isFavorite - isFavorite
 * @param {boolean} isLoading - isLoading
 * @returns {JSX.Element}
 */
const FavoritesButton = ({ className, onClick, isFavorite = true, isLoading = false }) => {
  if (isLoading)
    return (
      <div className={cn(style.loader, className)}>
        <Preloader />
      </div>
    );

  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <button type='button' className={cn(style.star, className)} onClick={handleClick}>
      {isFavorite ? <StarIcon /> : <EmptyStarIcon />}
    </button>
  );
};

export default FavoritesButton;
