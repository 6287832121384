import React from 'react';
import { observer } from 'mobx-react';

import style from './InstrumentsContent.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import Error from '../../../../components/Error/Error';
import InstrumentsContentItem from './InstrumentsContentItem/InstrumentsContentItem';

/**
 * Represents Instruments Content in Left Side Block on Main Page component
 * @param {boolean} isLoading - is content loading
 * @param {function} handleOnClick - on item click handler
 * @returns {JSX.Element}
 */
const InstrumentsContent = ({ isLoading, handleOnClick }) => {
  const {
    finance: { items },
  } = useRootModel();

  return (
    <div className={style.content}>
      {!isLoading && !items.length && <Error className={style.errorWrapper} />}
      {items?.map(({ alias, s, lp, cpd, oldPrice, type }) => {
        const onClick = () => handleOnClick(s);
        return (
          <InstrumentsContentItem
            key={s}
            symbol={s}
            alias={alias}
            lastPrice={lp}
            changePerDay={cpd}
            oldPrice={oldPrice}
            type={type}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};

export default observer(InstrumentsContent);
