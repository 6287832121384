import { types } from 'mobx-state-tree';
import { flow } from 'mobx';
import axios from 'axios';
import error from '../util/error';

const SearchItemModel = types
  .model('SearchItemModel', {
    s: types.string,
    alias: types.string,
    type: types.string,
    lp: types.number,
    h: types.number,
    l: types.number,
    cpd: types.number,
    t: types.number,
    oldPrice: types.optional(types.number, 0),
  })
  .actions((item) => ({
    update({ lp, h, l, cpd, t, oldPrice }) {
      item.lp = lp;
      item.cpd = cpd;
      item.t = t;
      item.h = h || item.h;
      item.l = l || item.l;
      item.oldPrice = oldPrice || item.oldPrice;
    },
  }));

const SearchModel = types
  .model('SearchModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    items: types.array(SearchItemModel),
    term: types.optional(types.string, ''),
  })
  .actions((search) => ({
    setIsLoading(loading) {
      search.isLoading = loading;
    },
    setError(err) {
      search.error = err;
    },
    clearError() {
      search.error = '';
    },
    setTerm(term) {
      search.term = term;
    },
    clearTerm() {
      search.term = '';
    },
    setItems(items) {
      search.items = items;
    },
    clearData() {
      search.setItems([]);
    },
    updateData(data) {
      const { items } = search;
      items.forEach((i) => {
        if (i.s === data.s) i.update(data);
      });
    },
    getSearch: flow(function* getSearch() {
      const { term, setIsLoading, setError, clearError, setItems } = search;
      if (!term) return;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`/services/trading/api/symbols/search`, {
          params: { occurrence: term },
        });
        setItems(data);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
  }));

export default SearchModel;
