import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './FavoritesItem.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import string from '../../../../../util/string';

/**
 * Represents row in FavoritesInstruments block
 * @param {string} symbol - instrument symbol
 * @param {string} alias - instrument title
 * @param {string} price - instrument price
 * @param {number} oldPrice - instrument oldPrice
 * @param {string} type - instrument type
 * @returns {JSX.Element}
 */
const FavoritesItem = ({ symbol, alias, price, oldPrice, type }) => {
  const {
    finance: { setActiveSymbol, setCurrentCategory },
    modal: { openTrade },
  } = useRootModel();

  const handleOnClick = async () => {
    await setActiveSymbol(symbol);
    await setCurrentCategory(type);
    openTrade.open();
  };

  return (
    <button type='button' className={style.item} onClick={handleOnClick}>
      <p className={style.instrument}>{alias}</p>
      <p
        className={cn(style.price, {
          [style.textRed]: oldPrice > price,
          [style.textGreen]: oldPrice < price,
        })}
      >
        {string.formatNumber(price)}
      </p>
    </button>
  );
};

export default observer(FavoritesItem);
