import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './MainTable.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import instrumentIcon from '../../../../assets/instrumentIcon';
import MainTableItem from './MainTableItem/MainTableItem';

/**
 * Represents Main page table in Mobile version
 * @param {string} filter - instruments category
 * @returns {JSX.Element}
 */

const MainTable = ({ filter }) => {
  const {
    finance: { getItems, getUpdateData, items, clearData, closeDataStream },
  } = useRootModel();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      await getItems(filter);
      getUpdateData();
    };
    fetchData();

    return () => {
      clearData();
      closeDataStream();
    };
  }, [filter]);

  return (
    <>
      <div className={style.header}>
        <p className={style.headerTitle}>{t('popular_instruments')}</p>
        <p className={style.headerTitle}>{t('daily')}</p>
      </div>
      {items.map(
        ({
          alias: title,
          s,
          lp: price,
          cpd: day,
          oldPrice,
          type,
          favorite,
          toggleFavorite,
          isLoading,
        }) => {
          const instType = instrumentIcon[type];
          const Icon = instType ? instrumentIcon[type][s] : null;
          return (
            <MainTableItem
              key={title}
              title={title}
              s={s}
              price={price}
              day={day}
              oldPrice={oldPrice}
              type={type}
              icon={Icon}
              isFavorite={favorite}
              toggleFavorite={toggleFavorite}
              isLoading={isLoading}
            />
          );
        },
      )}
    </>
  );
};

export default observer(MainTable);
