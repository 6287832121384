import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './IncreaseTradeContent.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import { OPERATION } from '../../../../../constant/commonConstants';
import AlertContext from '../../../../../context/alert/alertContext';
import useWindowSize from '../../../../../hooks/useWindowSize';

import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';
import { ReactComponent as Tip } from '../../../../../assets/image/common/tip.svg';
import { ReactComponent as DownArrow } from '../../../../../assets/image/common/downArr.svg';
import { ReactComponent as UpArrow } from '../../../../../assets/image/common/upArr.svg';
import Button from '../../../../ui/Button/Button';
import TVChartForModal from '../../../../TVChartForModal/TVChartForModal';
import NumberInput from '../../../../ui/NumberInputWithDropdown/NumberInputWithDropdown';
import CoinFormat from '../../../../CoinFormat/CoinFormat';

/**
 * Represents Increase Trade content after click on Increase button
 * @param {function} handleClose - handle close Modal button
 * @returns {JSX.Element}
 */
const IncreaseTradeContent = ({ handleClose }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const { isMobile, isLaptop } = useWindowSize();
  const {
    modal: {
      increaseTrade: { close },
    },
    finance: {
      activeTrades: { activeTrade, getActiveTrades },
      trade: { increase, increaseAmount },
    },
  } = useRootModel();

  const { id, operation, amount } = activeTrade;

  const handleIncrease = async () => {
    try {
      await increase(id);
      alert.show(t('order_increased'), 'success');
      close();
      getActiveTrades();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.wrapper}>
      {!isMobile && (
        <div className={style.tvChart}>
          <TVChartForModal />
        </div>
      )}
      <div className={style.container}>
        {!isLaptop && (
          <button type='button' onClick={handleClose} className={style.closeButton}>
            <CloseIcon />
          </button>
        )}
        <div className={style.header}>
          <p>{t('increase_trade')}</p>
          <div className={style.tipBlock}>
            <Tip />
            <p className={style.tipContent}>
              {t('you_can_increase')} <br />
              {t('increase_speed')} <br />
              {t('avoid_loss')} <br />
              {t('increasing_your_trade')}
            </p>
          </div>
        </div>
        <div className={style.main}>
          <div className={style.mainContent}>
            <p className={style.operation}>
              {t('operation')}: {t(operation.toLowerCase())}
              <span className={style.arrow}>
                {operation === OPERATION.buy ? <UpArrow /> : <DownArrow />}
              </span>
            </p>
            <div>
              <CoinFormat value={amount} withoutSign={true} />
            </div>
          </div>
          <NumberInput label labelTitle={t('add')} />
          <p className={style.commissions}>{t('commissions')}</p>
          <div className={style.bottomBlock}>
            <p className={style.text}>{t('commission_for_increasing')}</p>
            <p>0.0000%</p>
          </div>
        </div>
        <div className={style.buttonContainer}>
          <Button text={t('cancel')} color='secondary' fontWeight='bold' onClick={handleClose} />
          <Button
            text={t('increase')}
            color='primary'
            fontWeight='bold'
            onClick={handleIncrease}
            disabled={increaseAmount <= 0}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(IncreaseTradeContent);
