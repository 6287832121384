import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import style from './HeaderButtons.module.scss';
import { useRootModel } from '../../../models/RootStore';
import { PAGES_WITHOUT_SIDEBAR } from '../../../constant/commonConstants';
import useWindowSize from '../../../hooks/useWindowSize';

import { ReactComponent as SearchIcon } from '../../../assets/image/common/search.svg';
import { ReactComponent as BrightnessIcon } from '../../../assets/image/common/brightness.svg';

import Tooltip from '../../ui/Tooltip/Tooltip';
import HeaderNotifications from '../HeaderNotifications/HeaderNotifications';

/**
 * Represents HeaderButtons
 * @returns {JSX.Element}
 */
const HeaderButtons = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    user: { isAuthorized },
    settings: { toggleTheme, isLightMode },
    modal: { search },
  } = useRootModel();
  const { isMobile } = useWindowSize();

  const isSearch = isAuthorized && !PAGES_WITHOUT_SIDEBAR.some((page) => pathname.includes(page));

  return (
    <div
      className={cn(style.options, {
        [style.withoutAuth]: !isAuthorized,
        [style.withoutSearch]: !isSearch,
      })}
    >
      {isAuthorized && <HeaderNotifications />}

      {!isMobile && (
        <>
          {isSearch && (
            <Tooltip content={t('find')} position='bottom'>
              <button
                type='button'
                className={cn(style.optionsItem, { [style.lightThemeItem]: isLightMode })}
                onClick={search.open}
              >
                <SearchIcon />
              </button>
            </Tooltip>
          )}
          {!isAuthorized && (
            <Tooltip content={isLightMode ? t('dark') : t('light')} position='bottom'>
              <button
                type='button'
                className={cn(style.optionsItem, style.optionsItemBrightness, {
                  [style.lightThemeItemBrightness]: isLightMode,
                })}
                onClick={toggleTheme}
              >
                <BrightnessIcon />
              </button>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default observer(HeaderButtons);
