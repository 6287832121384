import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './PriceInput.module.scss';
import { useRootModel } from '../../../models/RootStore';
import string from '../../../util/string';

import TextInput from '../TextInput/TextInput';
import ArrowDouble from '../ArrowDouble/ArrowDouble';

const PriceInput = ({ id, labelTitle }) => {
  const {
    finance: {
      item,
      trade: { setPendingPrice },
    },
  } = useRootModel();

  const [amount, setAmount] = useState(string.formatNumber(item?.lp ?? 0));

  const handleBlur = (e) => {
    const { value } = e.target;

    if (+value === 0) {
      setAmount(string.formatNumber(item.lp));
    } else {
      setAmount(string.formatNumber(value));
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    const newValue = value.replace(/,/g, '');

    if (!(/^\d+(\.?\d{0,8})?$/.test(newValue) || newValue === '')) return;
    setAmount(newValue);
  };

  useEffect(() => {
    const newAmount = amount.replace(/,/g, '');
    setPendingPrice(+newAmount);
  }, [amount]);

  const handleClickUp = () => {
    const num = string.decimalAmount(amount);
    const plus = num > 0 ? 1 / 10 ** num : 1;
    setAmount(String((Number(amount) + plus).toFixed(num)));
  };

  const handleClickDown = () => {
    const num = string.decimalAmount(amount);
    const div = num > 0 ? 1 / 10 ** num : 1;
    setAmount(String((Number(amount) - div).toFixed(num)));
  };

  const Arrows = () => <ArrowDouble onClickUp={handleClickUp} onClickDown={handleClickDown} />;

  return (
    <div className={cn(style.wrapper)}>
      <div className={style.label}>
        <label htmlFor={id}>{labelTitle}</label>
      </div>
      <TextInput
        id={id}
        inputType='number'
        value={amount}
        placeholder='0.00'
        onChange={handleChange}
        onBlur={handleBlur}
        inputClassName={style.input}
        inputMode='numeric'
        maxLength={20}
        rightIcon={Arrows}
        rightIconClassName={style.arrowsContainer}
      />
    </div>
  );
};

export default observer(PriceInput);
