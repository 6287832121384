import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../util/error';
import HistoryItem from './TradesModels/HistoryItemModel';
import PeriodForTables from '../common/PeriodForTables';

const CurrentTradeModel = types
  .model('CurrentTradeModel', {
    id: types.optional(types.number, 1),
  })
  .actions((currentTrade) => ({
    setId(id) {
      currentTrade.id = id;
    },
  }));

const TradeCommission = types.model('TradeCommission', {
  forMakingOpsCmsnAmount: types.maybeNull(types.number),
  swapAccrualAmount: types.optional(types.number, 0),
  totalAmount: types.optional(types.number, 0),
});

const TradeModel = types
  .model('TradeModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    current: types.optional(CurrentTradeModel, {}),
    amount: types.optional(types.number, 0),
    multiplier: types.optional(types.integer, 1),
    maxMultiplier: types.optional(types.integer, 1),
    operation: types.optional(types.string, 'BUY'),
    pendingPrice: types.maybeNull(types.number),
    stopLoss: types.maybeNull(types.number),
    takeProfit: types.maybeNull(types.number),
    increaseAmount: types.optional(types.number, 0),
    historyItems: types.array(HistoryItem),
    period: PeriodForTables,
    commission: types.optional(TradeCommission, {}),
  })
  .actions((trade) => ({
    setError(err) {
      trade.error = err;
    },
    clearError() {
      trade.error = '';
    },
    setIsLoading(loading) {
      trade.isLoading = loading;
    },
    setAmount(amount) {
      trade.amount = amount;
    },
    setOperation(operation) {
      trade.operation = operation;
    },
    setMultiplier(multiplier) {
      trade.multiplier = multiplier;
    },
    setLoss(amount) {
      trade.stopLoss = amount;
    },
    setProfit(amount) {
      trade.takeProfit = amount;
    },
    setPendingPrice(amount) {
      trade.pendingPrice = amount;
    },
    setIncreaseAmount(amount) {
      trade.increaseAmount = amount;
    },
    changePeriod(period) {
      trade.period = period;
    },
    setCommission(data) {
      trade.commission = data;
    },
    openTrade: flow(function* openTrade() {
      const { activeSymbol } = getParent(trade);
      const {
        setIsLoading,
        clearError,
        setError,
        amount,
        multiplier,
        pendingPrice,
        operation,
        stopLoss,
        takeProfit,
      } = trade;
      setIsLoading(true);
      clearError();
      try {
        const tradeData = {
          amount,
          multiplier,
          operation,
          symbol: activeSymbol,
          pendingPrice,
          stopLoss,
          takeProfit,
        };

        yield axios.post(`/services/trading/api/trades/open`, tradeData);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    closeTrade: flow(function* closeTrade(id) {
      const { setIsLoading, clearError, setError } = trade;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/${id}/close`);
        const {
          activeTrades: { getActiveTrades },
        } = getParent(trade);
        getActiveTrades();
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    cancelTrade: flow(function* cancelTrade(id) {
      const { setIsLoading, setError, clearError } = trade;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/${id}/cancel`);
        const {
          pendingTrades: { removeItem },
        } = getParent(trade);
        removeItem(id);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    closeTrades: flow(function* closeTrades(option = 'ALL') {
      const { setIsLoading, clearError, setError } = trade;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/close?profit-status=${option}`);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    changeLimits: flow(function* changeLimit(id) {
      const { clearError, setError, stopLoss, takeProfit } = trade;
      clearError();
      try {
        yield axios.patch(`/services/trading/api/trades/${id}`, {
          stopLoss,
          takeProfit,
        });
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      }
    }),
    increase: flow(function* increase(id) {
      const { setIsLoading, clearError, setError, increaseAmount } = trade;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/${id}/increase`, {
          amount: increaseAmount,
        });
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getHistory: flow(function* getHistory(id) {
      const { setIsLoading, clearError, setError, period } = trade;
      setIsLoading(true);
      clearError();
      try {
        const params = {
          period,
        };
        const { data } = yield axios.get(`/services/trading/api/trades/${id}/history`, {
          params,
        });
        trade.historyItems = data;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getMaxMultiplier: flow(function* getMaxMultiplier() {
      const { activeSymbol } = getParent(trade);
      const { setIsLoading, clearError } = trade;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(
          `/services/trading/api/instruments/${activeSymbol}/multiplier`,
        );
        trade.maxMultiplier = data.maxMultiplier;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        // eslint-disable-next-line no-console
        console.log(err);
        // eslint-disable-next-line no-console
        console.log(message);
        // setError(message);
        // error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getCommission: flow(function* getCommission(id) {
      const { setIsLoading, clearError, setError, setCommission } = trade;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`/services/trading/api/trades/${id}/commissions`);

        setCommission(data);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
  }))
  .views((trade) => ({
    get order() {
      const { pendingTrades, activeTrades } = getParent(trade);
      const { current } = trade;
      const activeItem = activeTrades?.items?.find((i) => i?.id === current?.id);
      if (activeItem) return activeItem;
      const pendingItem = pendingTrades?.items?.find((i) => i?.id === current?.id);
      if (pendingItem) return pendingItem;

      return {};
    },
    get multipliedSum() {
      const { amount, multiplier } = trade;
      return amount * multiplier;
    },
  }));

export default TradeModel;
