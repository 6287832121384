import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import style from './PeriodFilterTrades.module.scss';
import Dropdown from '../../../components/ui/Dropdown/Dropdown';
import { PERIOD, SORT_BY_TIME } from '../../../constant/commonConstants';
import { useRootModel } from '../../../models/RootStore';
import CoinFormat from '../../../components/CoinFormat/CoinFormat';

/**
 * Represents Period filter component in Trades on Mobile version
 * @returns {JSX.Element}
 */

const PeriodFilterTrades = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const {
    finance: {
      activeTrades: { changePeriod: changePeriodActive, period, total },
      pendingTrades: { changePeriod: changePeriodPending, totalAmountPending },
      closedTrades: {
        getClosedTrades,
        changePeriod: changePeriodClosed,
        summary: { profit },
      },
      flowOfFunds: { changePeriod: changePeriodFlow, totalProfit },
    },
  } = useRootModel();

  const trades = {
    active: total.profit,
    pending: totalAmountPending,
    closed: profit,
    'flow-funds': totalProfit,
  };

  const handleChangeSortByDate = (time) => {
    Object.entries(PERIOD).forEach(([key, value]) => {
      if (t(key) === time) {
        changePeriodActive(value);
        changePeriodPending(value);
        changePeriodClosed(value);
        getClosedTrades();
        changePeriodFlow(value);
      }
    });
  };

  useEffect(() => {
    return () => {
      changePeriodActive('ALL_TIME');
      changePeriodPending('ALL_TIME');
      changePeriodClosed('ALL_TIME');
      changePeriodFlow('ALL_TIME');
    };
  }, []);

  return (
    <div className={style.periodFilter}>
      <Dropdown
        className={style.dropdownSortTime}
        contentClassName={style.content}
        currentItemWrapperClassName={style.currentItemWrapperClassName}
        sideToOpen='up'
        onChange={handleChangeSortByDate}
        mainComponent={SORT_BY_TIME[period]}
      >
        {Object.values(SORT_BY_TIME).reverse()}
      </Dropdown>
      <div className={cn(style.total, { [style.totalPlus]: trades[type] > 0 })}>
        <CoinFormat value={trades[type]} />
      </div>
    </div>
  );
};

export default observer(PeriodFilterTrades);
