import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../../util/error';
import financeUtils from '../../../util/financeUtils';
import TradesSymbolModel from './TradesSymbolModel';
import sort from '../../../util/sort';
import { sortByQuantity } from '../../../constant/commonConstants';
import PeriodForTables from '../../common/PeriodForTables';
import eventSourceUtil from '../../../eventSource/eventSource';

const TotalModel = types.model('ActiveTradesTotalModel', {}).views((total) => ({
  get profit() {
    const { filteredWithSearch } = getParent(total);
    return filteredWithSearch.reduce((acc, current) => acc + current.profit, 0);
  },
  get result() {
    const { filteredWithSearch } = getParent(total);
    return filteredWithSearch.reduce((acc, current) => acc + current.result, 0);
  },
  get amount() {
    const { filteredWithSearch } = getParent(total);
    return filteredWithSearch.reduce((acc, current) => acc + current.amount, 0);
  },
}));

const ActiveTradesItemModel = types
  .model('ActiveTradesItemModel', {
    amount: types.optional(types.number, 0),
    id: types.optional(types.number, 0),
    lastPrice: types.maybeNull(types.number),
    multiplier: types.optional(types.integer, 0),
    openedAt: types.optional(types.string, ''),
    openingPrice: types.optional(types.number, 0),
    operation: types.optional(types.string, ''),
    stopLoss: types.maybeNull(types.number),
    symbol: types.optional(TradesSymbolModel, {}),
    takeProfit: types.maybeNull(types.number),
    userId: types.optional(types.integer, 0),
    alias: types.optional(types.string, ''),
    favorites: types.optional(types.boolean, false),
  })
  .actions((tradesItem) => ({
    update(data) {
      tradesItem.lastPrice = data.lp;
      // tradesItem.favorites = favorites;
    },
  }))
  .views((item) => ({
    get profitPercent() {
      const { profitPercent } = financeUtils.countValues(item);
      return profitPercent;
    },
    get profit() {
      const { profit } = financeUtils.countValues(item);
      return profit;
    },
    get result() {
      const { result } = financeUtils.countValues(item);
      return result;
    },
  }));

const ActiveTradesModel = types
  .model('ActiveTradesModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    activeTradeId: types.optional(types.number, 1),
    items: types.array(ActiveTradesItemModel),
    multiplier: types.optional(types.integer, 1),
    total: types.optional(TotalModel, {}),
    searchName: types.optional(types.string, ''),
    page: types.optional(types.number, 1),
    pageSize: types.optional(types.number, sortByQuantity[0]),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'asc'),
    period: PeriodForTables,
  })
  .actions((trades) => ({
    setIsLoading(loading) {
      trades.isLoading = loading;
    },
    setError(err) {
      trades.error = err;
    },
    clearError() {
      trades.error = '';
    },
    setActiveTradeId(id) {
      trades.activeTradeId = id;
    },
    setMultiplier(multiplier) {
      trades.multiplier = multiplier;
    },
    setItems(items) {
      trades.items = items;
    },
    setName(search) {
      trades.searchName = search;
    },
    setSortBy(key) {
      trades.sortBy = key;
    },
    setSortDirection(direction) {
      trades.sortDirection = direction;
    },
    switchSortDirection() {
      trades.sortDirection = trades.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    setPageSize(quantity) {
      trades.page = 1;
      trades.pageSize = parseInt(quantity, 10);
    },
    setPage(page) {
      trades.page = page;
    },
    changePeriod(period) {
      trades.period = period;
    },
    closeDataStreamTrades() {
      eventSourceUtil.closeStreamTrades();
    },
    updateActiveTradesState(data) {
      const { items } = trades;
      items.forEach((item) => {
        let newData = data.find((i) => i.s === item.symbol.s);
        if (newData) item.update(newData);
      });
    },
    updateActiveTrades() {
      const { updateActiveTradesState } = trades;
      const eventSource = eventSourceUtil.createEventSourceTrades();
      eventSource.onmessage = (e) => {
        updateActiveTradesState(JSON.parse(e.data));
      };
    },
    getActiveTrades: flow(function* getActiveTrades() {
      const { setIsLoading, setError, clearError, setItems, updateActiveTrades } = trades;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`/services/trading/api/trades/active`);
        const newData = data.map((item) => ({ ...item, alias: item.symbol.alias }));
        setItems(newData);
        updateActiveTrades();
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    clearData() {
      trades.setItems([]);
    },
  }))
  .views((trades) => ({
    get sortedByTime() {
      const { items } = trades;
      return items.slice().sort((a, b) => (a.openedAt < b.openedAt ? 1 : -1));
    },
    get profitableTradesSum() {
      const { items } = trades;
      return items
        .filter(({ profit }) => profit > 0)
        .reduce((acc, item) => acc + ((item && item.profit) || 0), 0);
    },
    get profitableTradesAmount() {
      const { items } = trades;
      return items.filter(({ profit }) => profit > 0).length;
    },
    get lossTradesSum() {
      const { items } = trades;
      return items
        .filter(({ profit }) => profit < 0)
        .reduce((acc, item) => acc + ((item && item.profit) || 0), 0);
    },
    get lossTradesAmount() {
      const { items } = trades;
      return items.filter(({ profit }) => profit < 0).length;
    },
    get allTradesSum() {
      const { items } = trades;
      return items.reduce((acc, item) => acc + ((item && item.profit) || 0), 0);
    },
    get allTradesAmount() {
      const { items } = trades;
      return items.length;
    },
    get activeTrade() {
      const { items, activeTradeId } = trades;
      return items.find((i) => i.id === activeTradeId);
    },
    get filteredByPeriod() {
      const { items, period } = trades;
      return sort.sortArrayByPeriod(period, items);
    },
    get sortedTable() {
      const { filteredByPeriod, sortDirection, sortBy, items } = trades;
      if (!sortBy) return items;
      return sort.sortArrayBy(filteredByPeriod, sortDirection, sortBy);
    },
    get filteredWithSearch() {
      const { sortedTable, searchName } = trades;
      return sortedTable.filter((item) =>
        item.symbol?.alias?.toLowerCase().includes(searchName.toLowerCase()),
      );
    },
    get filteredWithSearchAndQuantity() {
      const { filteredWithSearch, pageSize } = trades;
      return filteredWithSearch.slice(0, pageSize);
    },
    get filteredWithSearchAndPagination() {
      const { page, pageSize, filteredWithSearch } = trades;
      return filteredWithSearch.slice((page - 1) * pageSize, pageSize * page);
    },
    get quantity() {
      const { filteredWithSearch } = trades;
      return filteredWithSearch.length > 99 ? '99+' : filteredWithSearch.length;
    },
  }));

export default ActiveTradesModel;
