import React from 'react';
import { observer } from 'mobx-react';
import { Redirect, useParams } from 'react-router-dom';
import InstrumentsMobile from './InstrumentsMobile/InstrumentsMobile';
import TabsScrollLink from '../../../components/ui/TabsScrollLink/TabsScrollLink';
import { tabs } from '../../../constant/tabsConstants';
import ProductItemDetailMobile from './ProductItemDetailMobile/ProductItemDetailMobile';

/**
 * Represents Markets page in Mobile version
 * @returns {JSX.Element}
 */

const MarketsPage = () => {
  const { category, s } = useParams();

  const products = {
    crypto: <InstrumentsMobile category={category} />,
    index: <InstrumentsMobile category={category} />,
    'oil-gas': <InstrumentsMobile category={category} />,
    metal: <InstrumentsMobile category={category} />,
    stock: <InstrumentsMobile category={category} />,
    forex: <InstrumentsMobile category={category} />,
    agriculture: <InstrumentsMobile category={category} />,
    etf: <InstrumentsMobile category={category} />,
  };

  if (s) return <ProductItemDetailMobile />;

  if (!Object.keys(products).includes(category)) return <Redirect to='/404' />;

  return (
    <>
      <TabsScrollLink tabs={tabs} />
      {products[category]}
    </>
  );
};

export default observer(MarketsPage);
