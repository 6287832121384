import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './PersonalDetailsMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import PersonalDetails from '../../../../pages/Profile/PersonalDetails/PersonalDetails';

/**
 * Represents Personal details component in Mobile version
 * @returns {JSX.Element}
 */

const PersonalDetailsMobile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/profile' />
        <p className={style.title}>{t('personal_details')}</p>
      </div>
      <PersonalDetails />
    </div>
  );
};

export default PersonalDetailsMobile;
