import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { observer } from 'mobx-react';

import style from './PendingRightSideItem.module.scss';
import AlertContext from '../../../../../context/alert/alertContext';
import { useRootModel } from '../../../../../models/RootStore';
import { OPERATION } from '../../../../../constant/commonConstants';
import string from '../../../../../util/string';

import { ReactComponent as UpArrow } from '../../../../../assets/image/common/upArr.svg';
import { ReactComponent as DownArrow } from '../../../../../assets/image/common/downArr.svg';
import { ReactComponent as Plus } from '../../../../../assets/image/common/plusBlue.svg';
import { ReactComponent as Close } from '../../../../../assets/image/common/closeInCircle.svg';
import { ReactComponent as Active } from '../../../../../assets/image/common/active.svg';
import CoinFormat from '../../../../CoinFormat/CoinFormat';

/**
 * Represents PendingRightSideItem
 * @param {number} id - pending trade id
 * @param {string} symbol - pending trade symbol
 * @param {number} multiplier - pending trade multiplier
 * @param {number} amount - pending trade amount
 * @param {number} pendingPrice - pending trade pendingPrice
 * @param {'BUY' | 'SELL'} operation - pending trade operation
 * @returns {JSX.Element}
 */
const PendingRightSideItem = ({ id, symbol, multiplier, amount, pendingPrice, operation }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    finance: {
      setActiveSymbol,
      trade: {
        current: { setId },
      },
      pendingTrades: { getPendingTrades, setActive },
      activeTrades: { getActiveTrades },
    },
    modal: { openTrade, cancelTrade },
  } = useRootModel();

  const handleClickOpenTrade = () => {
    setActiveSymbol(symbol);
    openTrade.open();
  };

  const handleClickCloseTrade = () => {
    setId(id);
    setActiveSymbol(symbol);
    cancelTrade.open();
  };

  const handleSetActive = async () => {
    try {
      await setActive(id);
      getPendingTrades();
      getActiveTrades();
      alert.show(t('order_set_active'), 'success');
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.instrumentBlock}>
      <div
        role='button'
        className={style.instrument}
        onClick={handleClickOpenTrade}
        onKeyDown={handleClickOpenTrade}
        tabIndex={-1}
      >
        <div className={style.instrument__block}>
          <p className={style.instrument__text}>{symbol}</p>
          <span className={style.instrument__operation}>
            {operation === OPERATION.buy ? <UpArrow /> : <DownArrow />}
          </span>
        </div>
        <div className={style.priceBlock}>
          <p className={style.price}>{string.formatNumber(pendingPrice)}</p>
          <div className={style.bottomPrice}>
            <CoinFormat value={amount} withoutSign={true} />x{multiplier}
          </div>
        </div>
      </div>
      <div>
        <div className={style.info}>
          <button
            type='button'
            onClick={handleSetActive}
            className={cn(style.buttonBlock, style.buttonBorder)}
          >
            <Active />
            <p className={style.text}>{t('set_as_active')}</p>
          </button>
          <button
            type='button'
            onClick={handleClickOpenTrade}
            className={cn(style.buttonBlock, style.buttonBorder)}
          >
            <Plus />
            <p className={style.text}>{t('open_new')}</p>
          </button>
          <button type='button' onClick={handleClickCloseTrade} className={style.buttonBlock}>
            <Close />
            <p className={style.text}>{t('cancel_order')}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(PendingRightSideItem);
