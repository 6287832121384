import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './NotificationsFooter.module.scss';
import { useRootModel } from '../../../models/RootStore';

import { ReactComponent as NextPageIcon } from '../../../assets/image/common/arrowNextPage.svg';

/**
 * Represents NotificationsFooter component
 * @returns {JSX.Element}
 */
const NotificationsFooter = () => {
  const {
    user: {
      notifications: {
        setNextPage,
        getNotifications,
        setPreviousPage,
        amountChecked,
        isFirstPage,
        isLastPage,
      },
    },
  } = useRootModel();

  const handleSetPreviousPage = async () => {
    await setPreviousPage();
    getNotifications();
  };
  const handleSetNextPage = async () => {
    await setNextPage();
    getNotifications();
  };

  return (
    <div className={style.notificationsFooter}>
      <p className={style.text}>
        Selected: {amountChecked} item{amountChecked !== 1 && 's'}
      </p>
      <div className={style.buttonsWrapper}>
        <button
          type='button'
          className={cn(style.button, style.arrowLeft)}
          onClick={handleSetPreviousPage}
          disabled={isFirstPage}
        >
          <NextPageIcon />
        </button>
        <button
          type='button'
          className={style.button}
          onClick={handleSetNextPage}
          disabled={isLastPage}
        >
          <NextPageIcon />
        </button>
      </div>
    </div>
  );
};

export default observer(NotificationsFooter);
