import React from 'react';
import { observer } from 'mobx-react';
import style from './HeaderMobile.module.scss';
import HeaderBalance from '../../../components/Header/HeaderBalance/HeaderBalance';
import Logo from '../../../components/ui/Logo/Logo';
// eslint-disable-next-line max-len
import HeaderNotifications from '../../../components/Header/HeaderNotifications/HeaderNotifications';

/**
 * Represents Header on Mobile version
 * @returns {JSX.Element}
 */

const HeaderMobile = () => {
  return (
    <header className={style.header}>
      <Logo />
      <HeaderBalance />
      <HeaderNotifications />
    </header>
  );
};

export default observer(HeaderMobile);
