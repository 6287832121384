import React from 'react';
import cn from 'classnames';

import style from './ArrowDouble.module.scss';

import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDown.svg';

/**
 * Represents ArrowDouble component
 * @param onClickUp
 * @param onClickDown
 * @returns {JSX.Element}
 */
const ArrowDouble = ({ onClickUp, onClickDown }) => {
  return (
    <div className={style.arrowDouble}>
      <button type='button' className={cn(style.button, style.arrowUp)} onClick={onClickUp}>
        <ArrowUp />
      </button>
      <button
        type='button'
        className={cn(style.button, style.borderTop, style.arrowDown)}
        onClick={onClickDown}
      >
        <ArrowDown />
      </button>
    </div>
  );
};

export default ArrowDouble;
