import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './OpenTradeButtonMobile.module.scss';
import { ReactComponent as ArrDown } from '../../../assets/image/common/arrDown.svg';
import NowMobile from '../../AppModals/Modals/OpenTradeModal/OpenTradeContent/NowMobile/NowMobile';
// eslint-disable-next-line max-len
import PriceMobile from '../../AppModals/Modals/OpenTradeModal/OpenTradeContent/PriceMobile/PriceMobile';
import TabsForModal from '../TabsForModal/TabsForModal';
import { useRootModel } from '../../../models/RootStore';
import Preloader from '../Preloader/Preloader';

/**
 * Represents Open Trade Button for Mobile version
 * @returns {JSX.Element}
 */

const OpenTradeButtonMobile = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const {
    finance: {
      trade: { isLoading },
    },
  } = useRootModel();

  const tabs = [
    {
      id: 0,
      tabLabel: t('now'),
      component: NowMobile,
    },
    {
      id: 1,
      tabLabel: t('at_price'),
      component: PriceMobile,
    },
  ];

  const handleChange = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    contentRef.current.style.maxHeight = isOpen ? `100%` : '30px';
  }, [contentRef, isOpen]);

  return (
    <div className={style.container} ref={contentRef}>
      <button type='button' className={style.buttonDown} onClick={handleChange}>
        {isOpen ? <ArrDown className={style.up} /> : <ArrDown className={style.down} />}
      </button>
      {isLoading ? (
        <div className={style.loader}>
          <Preloader />
        </div>
      ) : (
        <TabsForModal tabs={tabs} />
      )}
    </div>
  );
};

export default observer(OpenTradeButtonMobile);
