import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from '../../HeaderUserInfo.module.scss';
import { ReactComponent as Profile } from '../../../../../assets/image/common/profile-mini.svg';

/**
 * Represents ButtonProfile component
 * @returns {JSX.Element}
 */
const ButtonProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleSwitchToProfile = () => {
    history.push('/profile');
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Profile />
      </i>
      <button type='button' className={style.button} onClick={handleSwitchToProfile}>
        {t('profile')}
      </button>
    </div>
  );
};

export default ButtonProfile;
