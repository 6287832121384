import React from 'react';
import { observer } from 'mobx-react';

import style from './HeaderNavigation.module.scss';
import { useRootModel } from '../../../models/RootStore';
import useWindowSize from '../../../hooks/useWindowSize';

import UserInfo from '../HeaderUserInfo/HeaderUserInfo';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import HeaderLanguageWithoutAuth from '../HeaderLanguageWithoutAuth/HeaderLanguageWithoutAuth';

/**
 * Represents HeaderNavigation
 * @returns {JSX.Element}
 */
const HeaderNavigation = () => {
  const { isMobile } = useWindowSize();
  const {
    user: { isAuthorized },
  } = useRootModel();

  return (
    <div className={style.navigation}>
      <HeaderButtons />
      {isAuthorized && !isMobile && <UserInfo />}
      {!isAuthorized && <HeaderLanguageWithoutAuth />}
    </div>
  );
};

export default observer(HeaderNavigation);
