import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import numeral from 'numeral';

import style from './LimitProfitsAndLost.module.scss';
import { useRootModel } from '../../models/RootStore';
import AlertContext from '../../context/alert/alertContext';

import ButtonWithArrows from '../ui/ButtonWithArrows/ButtonWithArrows';
import Button from '../ui/Button/Button';
import Checkbox from '../ui/Checkbox/Checkbox';
import { ReactComponent as Coin } from '../../assets/image/common/coin.svg';

/**
 * Represents LimitProfitsAndLost component
 * isButton {boolean} - is save button
 * @returns {JSX.Element}
 */
const LimitProfitsAndLost = ({ amount, isButton = false }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    finance: {
      trade: { setLoss, setProfit, stopLoss, takeProfit, changeLimits },
      activeTrades: { activeTrade },
    },
  } = useRootModel();
  const [openedList, setOpenedList] = useState(false);

  const [isProfit, setIsProfit] = useState(false);
  const [isLoss, setIsLoss] = useState(false);

  const [profitLimit, setProfitLimit] = useState('');
  const [lossLimit, setLossLimit] = useState('');

  const handleToggleList = () => setOpenedList((prev) => !prev);

  const handleChangeProfit = () => {
    setIsProfit(!isProfit);
    if (isProfit) {
      setProfit(null);
      setProfitLimit('');
    }
  };

  const handleChangeLoss = () => {
    setIsLoss(!isLoss);
    if (isLoss) {
      setLoss(null);
      setLossLimit('');
    }
  };

  const handleChangeLimits = (e) => {
    const { name, value } = e.target;

    if (!(/^\d+(\.?\d{0,2})?$/.test(value) || value === '')) return;

    if (name === 'profitLimit') {
      setProfitLimit(value);
      if (+value === 0) setProfit(null);
      setProfit(+value);
    }
    if (name === 'lossLimit') {
      setLossLimit(value);
      if (+value === 0) setLoss(null);
      setLoss(+value);
    }
  };

  const handleUpdateLimits = async () => {
    try {
      await changeLimits(activeTrade.id);
      alert.show(t('changes_saved'), 'success');
    } catch (err) {
      alert.show(err);
    }
  };

  useEffect(() => {
    return () => {
      setProfit(null);
      setLoss(null);
    };
  }, []);

  return (
    <div className={style.profit}>
      <ButtonWithArrows text={t('limit')} openedList={openedList} handleToggle={handleToggleList} />
      {openedList && (
        <div className={style.wrapper}>
          <div>
            <div className={style.inputContainer}>
              <Checkbox
                wrapperClassName={style.checkbox}
                label={t('profit')}
                id='profitCheckbox'
                checked={isProfit}
                handleChange={handleChangeProfit}
              />
              <div className={style.inputWrapper}>
                <p className={style.inputSign}>
                  <Coin />
                </p>
                <input
                  className={isProfit ? style.input : style.inputDisabled}
                  name='profitLimit'
                  type='text'
                  disabled={!isProfit}
                  inputMode='numeric'
                  value={!isProfit ? '' : profitLimit}
                  onChange={handleChangeLimits}
                  autoComplete='off'
                />
              </div>
            </div>
            {isProfit && takeProfit > 0 && amount > 0 && (
              <p className={style.percent}>+{numeral(takeProfit / amount).format('0.00%')}</p>
            )}
          </div>
          <div>
            <div className={style.inputContainer}>
              <Checkbox
                wrapperClassName={style.checkbox}
                label={t('loss')}
                id='lossCheckbox'
                checked={isLoss}
                handleChange={handleChangeLoss}
                maxLength='20'
              />
              <div className={style.inputWrapper}>
                <p className={style.inputSign}>
                  <Coin />
                </p>
                <input
                  className={isLoss ? style.input : style.inputDisabled}
                  name='lossLimit'
                  type='text'
                  disabled={!isLoss}
                  inputMode='numeric'
                  maxLength='20'
                  value={!isLoss ? '' : lossLimit}
                  onChange={handleChangeLimits}
                  autoComplete='off'
                />
              </div>
            </div>
            {isLoss && stopLoss > 0 && amount > 0 && (
              <p className={style.percent}>-{numeral(stopLoss / amount).format('0.00%')}</p>
            )}
          </div>
          {isButton && (
            <Button
              text={t('save')}
              disabled={!isProfit && !isLoss}
              className={style.saveButton}
              onClick={handleUpdateLimits}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default observer(LimitProfitsAndLost);
