import ReactDOM from 'react-dom';

/**
 * Represents modal wrapper
 * @param {ChildNode} children - children
 * @return {ReactPortal}
 */
const Portal = ({ children }) => {
  const modalRoot = document.getElementById('modal');
  return ReactDOM.createPortal(children, modalRoot);
};

export default Portal;
