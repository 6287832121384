import React from 'react';
import cn from 'classnames';
import style from './TabScrollItem.module.scss';

/**
 * Represents one tab
 * @param {number} activeTab - id of active tab
 * @param {string} label - label for tab
 * @param {function} onClick - handler onClick
 * @param {number} id - tab id
 * @returns {JSX.Element}
 */

const TabScrollItem = ({ activeTab, label, onClick, id }) => {
  const handleOnClick = () => {
    onClick(id);
  };
  const isActive = activeTab === id;

  return (
    <button
      type='button'
      className={cn(style.item, {
        [style.active]: isActive,
        [style.notActive]: !isActive,
      })}
      onClick={handleOnClick}
    >
      <span className={style.text}>{label}</span>
    </button>
  );
};

export default TabScrollItem;
