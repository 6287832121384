import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './Documents.module.scss';

import { useRootModel } from '../../../models/RootStore';

import Button from '../../../components/ui/Button/Button';
import UploadFile from './UploadFile/UploadFile';
import AlertContext from '../../../context/alert/alertContext';
import PersonalDetailsForm from '../PersonalDetailsForm/PersonalDetailsForm';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * Represents Documents
 * @param {function} closeModal - close modal callback
 * @returns {JSX.Element}
 */
const Documents = ({ closeModal }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const { isMobile } = useWindowSize();
  const {
    user: { getUser, loadPassportPage },
  } = useRootModel();

  // useEffect(() => {
  //   getPassportPage();
  // }, []);

  const [passportPage, setPassportPage] = useState(null);
  const [registrationPage, setRegistrationPage] = useState(null);
  const [formik, setFormik] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isMobile) {
      const changeUserData = async () => {
        await formik.handleSubmit();
      };
      changeUserData();
    }

    try {
      const formData = new FormData();
      if (passportPage) formData.append('passport', passportPage);
      if (registrationPage) formData.append('address', registrationPage);

      await loadPassportPage(formData);
      await getUser();
      setPassportPage(null);
      setRegistrationPage(null);
      alert.show(t('documents_uploaded_successfully'), 'success');
    } catch (err) {
      alert.show(err.message);
    }

    if (!isMobile) closeModal();
  };

  // const isApproved = userData?.documents?.first?.status === 'APPROVED';
  // const isApprovedId =
  //   userData?.documents?.first?.status === 'APPROVED' &&
  //   userData?.documents?.registration?.status === null;

  // let passportImage = userData?.documents?.slice(0, 1);
  // let addressImage = userData?.documents?.slice(-1);

  return (
    <form className={style.documents} onSubmit={handleSubmit}>
      {!isMobile && <PersonalDetailsForm setFormik={setFormik} />}
      <p className={style.mainDescription}>{t('upload_description')}</p>

      <hr className={style.line} />

      <UploadFile
        title={t('passport')}
        description={t('passport_description')}
        //image={passportImage[0]?.resource ?? ''}
        image={passportPage}
        imageDescription={t('passport_first_page_image')}
        onChange={setPassportPage}
        // status={userData?.documents?.first?.status}
      />

      <hr className={style.line} />

      {/*{!isApprovedId && (*/}
      <UploadFile
        title={t('additional_photo')}
        description={t('address')}
        //image={addressImage[0]?.resource ?? ''}
        image={registrationPage}
        imageDescription={t('passport_registration_page_image')}
        onChange={setRegistrationPage}
        // status={userData?.documents?.registration?.status}
      />
      {/*)}*/}

      {/*{!isApproved && (*/}
      <Button
        text={t('upload_documents')}
        className={style.submitButton}
        submit
        color='primary'
        width='full'
        disabled={!passportPage}
      />
      {/*)}*/}
    </form>
  );
};

export default observer(Documents);
