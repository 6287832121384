import React from 'react';
import style from './ButtonWithArrows.module.scss';
import { ReactComponent as ArrowUp } from '../../../assets/image/common/doubleArrow-Up.svg';

/**
 * Represents button with left-side image
 * @param {string} text - button title
 * @param {function} handleToggle - toggle handler for changing state
 * @param {boolean} openedList - component state
 * @returns {JSX.Element}
 */

const ButtonWithArrows = ({ text, handleToggle, openedList }) => {
  return (
    <button type='button' onClick={handleToggle} className={style.button}>
      {openedList ? <ArrowUp /> : <ArrowUp className={style.buttonDown} />}
      <span>{text}</span>
    </button>
  );
};

export default ButtonWithArrows;
