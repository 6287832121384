import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import cn from 'classnames';

import style from './UploadFile.module.scss';
import useWindowSize from '../../../../hooks/useWindowSize';
import AlertContext from '../../../../context/alert/alertContext';
import { DOCUMENT_FILE_SIZE, DOCUMENT_FILE_TYPES } from '../../../../constant/commonConstants';

import { ReactComponent as FullscreenIcon } from '../../../../assets/image/common/dashedSquare.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/image/common/upload.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/image/common/closeIconBig.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/image/common/info.svg';

import Button from '../../../../components/ui/Button/Button';
import ButtonWithIcon from '../../../../components/ui/ButtonWithIcon/ButtonWithIcon';
import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody/ModalBody';
import Tooltip from '../../../../components/ui/Tooltip/Tooltip';

/**
 * Represents UploadFile component
 * @param {string} title - main title
 * @param {string} description - main description
 * @param {string} image - image url
 * @param {string} imageDescription - image description
 * @param {function} onChange - onChange handler
 * @param {('IN_PROCESSING' | 'APPROVED' | 'REJECTED')} status - status uploaded documents
 * @returns {JSX.Element}
 */
const UploadFile = ({
  title = '',
  description = '',
  image = '',
  imageDescription = '',
  onChange,
  // status,
}) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const { isMobile } = useWindowSize();
  const inputFileRef = useRef(null);
  const [file, setFile] = useState(null);

  const [showFullPhoto, setShowFullPhoto] = useState(false);
  const toggleFullPhoto = () => setShowFullPhoto((prev) => !prev);

  const handleClickInputFileButton = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleInputFileChange = (e) => {
    const picture = e.target.files[0];
    if (!DOCUMENT_FILE_TYPES.includes(picture.type) || picture.size > DOCUMENT_FILE_SIZE) {
      alert.show(t('documents_upload_info'), 'warning');
      return;
    }
    setFile(picture);
  };

  useEffect(() => {
    if (onChange) onChange(file);
  }, [file]);

  // const isProgress = status === 'IN_PROCESSING';
  // const isApproved = status === 'APPROVED';
  // const isRejected = status === 'REJECTED';

  useEffect(() => {
    setFile(image);
  }, [image]);

  return (
    <>
      <div className={style.titleWrapper}>
        <h5 className={style.title}>{title}</h5>
        <Tooltip
          tooltipClassName={style.tooltip}
          content={t('documents_upload_info')}
          position={isMobile ? 'left' : 'bottom'}
        >
          <InfoIcon className={style.icon} />
        </Tooltip>
      </div>
      <p className={style.description}>{description}</p>
      <input
        value=''
        type='file'
        className={style.inputFile}
        ref={inputFileRef}
        onChange={handleInputFileChange}
        accept='.jpeg,.jpg,.png'
      />
      {image || file ? (
        <div className={style.imageContainer}>
          <div className={style.imageWrapper}>
            <img
              src={(file && URL.createObjectURL(file)) || image}
              alt={imageDescription}
              className={style.passportImage}
            />
            {isMobile && (
              <button type='button' className={style.fullscreenButton} onClick={toggleFullPhoto}>
                <FullscreenIcon />
              </button>
            )}
          </div>
          {/*<div*/}
          {/*  className={cn(style.uploadStatus, {*/}
          {/*    [style.progress]: isProgress,*/}
          {/*    [style.approved]: isApproved,*/}
          {/*    [style.rejected]: isRejected,*/}
          {/*  })}*/}
          {/*>*/}
          {/*  <span>{t('documents_progress')}</span>*/}
          {/*  {isProgress && <span>{t('documents_progress')}</span>}*/}
          {/*  {isApproved && <span>{t('documents_approved')}</span>}*/}
          {/*  {isRejected && <span>{t('documents_rejected')}</span>}*/}
          {/*</div>*/}
          {/*{!isApproved && (*/}
          <Button
            text={t('upload_new')}
            size='medium'
            className={style.buttonUpload}
            onClick={handleClickInputFileButton}
            width='full'
            color='primary'
          />
          {/*)}*/}
        </div>
      ) : (
        <ButtonWithIcon
          text={t('upload_file')}
          icon={UploadIcon}
          wrapperClassName={style.inputFileButtonWrapper}
          buttonClassName={style.inputFileButton}
          onClick={handleClickInputFileButton}
        />
      )}
      {showFullPhoto && (
        <Modal handleClose={toggleFullPhoto}>
          <ModalBody className={style.modalBody}>
            <img
              src={(file && URL.createObjectURL(file)) || image}
              alt={imageDescription}
              className={style.modalImage}
            />
            <button type='button' className={style.closeIcon} onClick={toggleFullPhoto}>
              <CloseIcon />
            </button>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default UploadFile;
