import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from '../../../translations/i18n';

import style from './AllInstrumentsDropdown.module.scss';

import { ReactComponent as Favorites } from '../../../assets/image/common/favorites.svg';
import { ReactComponent as Crypto } from '../../../assets/image/common/cryptocurrencies.svg';
import { ReactComponent as Indices } from '../../../assets/image/common/indices.svg';
import { ReactComponent as Oil } from '../../../assets/image/common/oil.svg';
import { ReactComponent as Metals } from '../../../assets/image/common/metals.svg';
import { ReactComponent as Shares } from '../../../assets/image/common/shares.svg';
import { ReactComponent as Currencies } from '../../../assets/image/common/currencies.svg';
import { ReactComponent as Agriculture } from '../../../assets/image/common/agriculture.svg';
import { ReactComponent as Etf } from '../../../assets/image/common/etf.svg';
import Dropdown from '../../ui/Dropdown/Dropdown';

const links = [
  { id: 1, to: '/favorites', icon: Favorites, title: i18n.t('favorites') },
  { id: 2, to: '/products/crypto', icon: Crypto, title: i18n.t('crypto') },
  { id: 3, to: '/products/index', icon: Indices, title: i18n.t('indices') },
  { id: 4, to: '/products/oil-gas', icon: Oil, title: i18n.t('oil') },
  { id: 5, to: '/products/metal', icon: Metals, title: i18n.t('metals') },
  { id: 6, to: '/products/stock', icon: Shares, title: i18n.t('shares') },
  { id: 7, to: '/products/forex', icon: Currencies, title: i18n.t('currencies') },
  { id: 8, to: '/products/agriculture', icon: Agriculture, title: i18n.t('agriculture') },
  { id: 9, to: '/products/etf', icon: Etf, title: i18n.t('etfs') },
];

/**
 * Represents All Instruments Dropdown in Header
 * @returns {JSX.Element}
 */
const AllInstrumentsDropdown = () => {
  const { t } = useTranslation();
  return (
    <Dropdown
      mainComponent={() => <span className={style.name}>{t('all_instruments')}</span>}
      changeMainComponent={false}
      className={style.dropdown}
      currentItemWrapperClassName={style.currentItemWrapper}
      itemClassName={style.item}
      contentClassName={style.content}
      activeClassName={style.active}
    >
      {links.map(({ id, to, icon: Icon, title }) => (
        <Link className={style.productsLink} to={to} key={id}>
          <Icon />
          <p>{t(title)}</p>
        </Link>
      ))}
    </Dropdown>
  );
};

export default AllInstrumentsDropdown;
