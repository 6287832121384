import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './ActiveTradesMobile.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import instrumentIcon from '../../../../assets/instrumentIcon';

import ActiveTradesMobileItem from './ActiveTradesMobileItem/ActiveTradesMobileItem';
import NotFoundMobile from '../../../components/NotFoundMobile/NotFoundMobile';
import Preloader from '../../../../components/ui/Preloader/Preloader';

/**
 * Represents Active Trades component in Mobile version
 * @returns {JSX.Element}
 */

const ActiveTradesMobile = () => {
  const { t } = useTranslation();
  const {
    finance: {
      activeTrades: {
        getActiveTrades,
        closeDataStreamTrades,
        filteredWithSearch,
        quantity,
        isLoading,
      },
    },
  } = useRootModel();

  useEffect(() => {
    getActiveTrades();

    return () => {
      closeDataStreamTrades();
    };
  }, []);

  if (isLoading)
    return (
      <div className={style.preloader}>
        <Preloader />
      </div>
    );

  return (
    <>
      {filteredWithSearch?.length > 0 ? (
        <section className={style.container}>
          <div className={style.header}>
            <div className={style.instruments}>
              <p>{t('instruments')}</p>
              <p className={style.notifications}>{quantity}</p>
            </div>
            <div className={style.headerPrice}>
              <p>{t('price')}</p>
              <p>{t('profit')}</p>
            </div>
          </div>
          {filteredWithSearch?.map(
            ({
              id,
              amount,
              symbol: { alias, s, type },
              openingPrice,
              multiplier,
              profit,
              profitPercent,
              operation,
            }) => (
              <ActiveTradesMobileItem
                key={id}
                id={id}
                alias={alias}
                code={s}
                price={openingPrice}
                funds={amount}
                multiplier={multiplier}
                profit={profit}
                profitPercent={profitPercent}
                icon={instrumentIcon[type.toLowerCase()][s]}
                operation={operation}
              />
            ),
          )}
        </section>
      ) : (
        <NotFoundMobile />
      )}
    </>
  );
};

export default observer(ActiveTradesMobile);
