import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './NotificationsHeader.module.scss';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';

import { ReactComponent as BasketIcon } from '../../../assets/image/common/basket.svg';
import { ReactComponent as Preloader } from '../../../assets/image/common/preloaderMini.svg';
import Checkbox from '../../ui/Checkbox/Checkbox';
import Tooltip from '../../ui/Tooltip/Tooltip';

/**
 * Represents NotificationsHeader component
 * @returns {JSX.Element}
 */
const NotificationsHeader = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    user: {
      notifications: {
        toggleCheckedAll,
        amountChecked,
        isCheckedAll,
        deleteNotifications,
        getNotifications,
        isLoading,
        setAllIsRead,
        isLoadingItems,
      },
    },
  } = useRootModel();

  const handleDelete = async () => {
    try {
      await deleteNotifications();
      getNotifications();
    } catch (err) {
      alert.show(err);
    }
  };

  const handleSetAllIsRead = () => {
    try {
      setAllIsRead();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.notificationsHeader}>
      <div className={style.checkboxWrapper}>
        <Checkbox
          id='markAllNotifications'
          handleChange={toggleCheckedAll}
          label={isCheckedAll ? '' : t('select_all')}
          labelClassName={style.label}
          checked={isCheckedAll}
        />
        {isCheckedAll && (
          <button
            type='button'
            className={cn(style.button, { [style.button__disabled]: isLoading })}
            onClick={handleSetAllIsRead}
            disabled={isLoading}
          >
            {t('mark_all_read')}
          </button>
        )}
        {isLoading && !isLoadingItems && (
          <div className={style.loader}>
            <Preloader />
          </div>
        )}
      </div>
      <Tooltip content={t('delete_selected_notifications')}>
        <button
          type='button'
          className={style.deleteLink}
          onClick={handleDelete}
          disabled={isLoading || !amountChecked}
        >
          <BasketIcon />
        </button>
      </Tooltip>
    </div>
  );
};

export default observer(NotificationsHeader);
