import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './NotificationsModal.module.scss';
import { useRootModel } from '../../../models/RootStore';

import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody/ModalBody';
import Button from '../../ui/Button/Button';

/**
 * Represents NotificationsModal
 * @returns {JSX.Element}
 */
const NotificationsModal = () => {
  const { t } = useTranslation();
  const {
    user: {
      notifications: { activeNotification },
    },
    modal: {
      notifications: { isOpen, close },
    },
  } = useRootModel();

  if (!activeNotification) return <></>;

  const { title, body } = activeNotification;
  return (
    <>
      {isOpen && (
        <Modal header handleClose={close} headerTitle={title}>
          <ModalBody className={style.body}>
            <p className={style.infoText}>{body}</p>
            <Button
              text={t('okay')}
              size='large'
              className={style.button}
              color='primary'
              onClick={close}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(NotificationsModal);
