import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './OtherSettingsMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import AccountSettings from '../../../../pages/Profile/AccountSettings/AccountSettings';

/**
 * Represents Other settings component in Mobile version
 * @returns {JSX.Element}
 */

const OtherSettingsMobile = () => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/profile' />
        <p className={style.title}>{t('other_settings')}</p>
      </div>
      <AccountSettings />
    </div>
  );
};

export default OtherSettingsMobile;
