import React from 'react';
import { observer } from 'mobx-react';

import ErrorBoundary from '../Error/ErrorBoundary/ErrorBoundary';

import IncreaseModal from './Modals/IncreaseModal/IncreaseModal';
import ActiveTradeModal from './Modals/ActiveTradeModal/ActiveTradeModal';
import CloseTradeModal from './Modals/CloseTradeModal/CloseTradeModal';
import HistoryModal from './Modals/HistoryModal/HistoryModal';
import OpenTradeModal from './Modals/OpenTradeModal/OpenTradeModal';
import DepositModal from './Modals/DepositModal/DepositModal';
import CancelTradeModal from './Modals/CancelTradeModal/CancelTradeModal';
import CurrentBalanceModal from './Modals/CurrentBalanceModal/CurrentBalanceModal';
import SearchModal from './Modals/SearchModal/SearchModal';
import NotificationsModal from '../Notifications/NotificationsModal/NotificationsModal';
import ChatPicturePreview from './Modals/ChatPicturePreview/ChatPicturePreview';
import CloseTradeModalMobile from './Modals/CloseTradeModalMobile/CloseTradeModalMobile';
import IncreaseModalMobile from './Modals/IncreaseModalMobile/IncreaseModalMobile';

/**
 * Represents ActiveTradesModals component
 * @returns {JSX.Element}
 */
const AppModals = () => {
  return (
    <ErrorBoundary>
      <ActiveTradeModal />
      <CloseTradeModal />
      <CloseTradeModalMobile />
      <HistoryModal />
      <IncreaseModal />
      <IncreaseModalMobile />
      <OpenTradeModal />
      <DepositModal />
      <CancelTradeModal />
      <CurrentBalanceModal />
      <SearchModal />
      <NotificationsModal />
      <ChatPicturePreview />
    </ErrorBoundary>
  );
};

export default observer(AppModals);
