import React from 'react';
import style from './TabsScroll.module.scss';
import TabScrollItem from './TabScrollItem/TabScrollItem';

/**
 * @typedef {Object} TabComponent
 * @property {number} id - tab id
 * @property {string} label - tab label
 * @property {JSX.Element} component - Component for render
 */

/**
 * Represents tabs component
 * @param {Array<TabComponent>} tabs - array of tabs - [{ id, label, component }]
 * @returns {JSX.Element}
 */
const TabsScroll = ({ tabs, onChange, activeTab }) => {
  return (
    <ul className={style.tabList}>
      {tabs.map(({ tabLabel, id }) => (
        <li key={id} className={style.listItem}>
          <TabScrollItem id={id} activeTab={activeTab} label={tabLabel} onClick={onChange} />
        </li>
      ))}
    </ul>
  );
};

export default TabsScroll;
