import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './ActiveTradesRightSide.module.scss';
import { useRootModel } from '../../../models/RootStore';

import ButtonWithArrows from '../../ui/ButtonWithArrows/ButtonWithArrows';
import Trades from './Trades/Trades';
import ActiveTradesDropdown from '../../ActiveTradesDropdown/ActiveTradesDropdown';
import Badge from '../../ui/Badge/Badge';
import eventSourceUtil from '../../../eventSource/eventSource';

/**
 * Represents a Active trades block on Main Page.
 * @returns {JSX.Element}
 */
const ActiveTradesRightSide = () => {
  const { t } = useTranslation();
  const [openedList, setOpenedList] = useState(true);
  const {
    finance: {
      activeTrades: { getActiveTrades, allTradesAmount, items, closeDataStreamTrades },
    },
  } = useRootModel();

  useEffect(() => {
    getActiveTrades();
    eventSourceUtil.changeRightBarTradesState(true);

    return () => {
      closeDataStreamTrades();
      eventSourceUtil.changeRightBarTradesState(false);
    };
  }, []);

  const handleToggle = () => {
    setOpenedList(!openedList);
  };

  const isTrades = !!allTradesAmount;
  return (
    items.length > 0 && (
      <div className={style.activeTrades}>
        <div className={style.buttonsBlock}>
          <div className={style.titleWrapper}>
            <ButtonWithArrows
              text={t('active_trades')}
              handleToggle={handleToggle}
              openedList={openedList}
            />
            {isTrades && <Badge amount={allTradesAmount} className={style.notifications} />}
          </div>
          {isTrades && <ActiveTradesDropdown />}
        </div>
        {isTrades && openedList && <Trades />}
      </div>
    )
  );
};

export default observer(ActiveTradesRightSide);
