import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './PaymentHistoryMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import Empty from '../../../../components/Empty/Empty';
import Dropdown from '../../../../components/ui/Dropdown/Dropdown';
import { PERIOD, SORT_BY_TIME, SORT_BY_VALUES } from '../../../../constant/commonConstants';
import PaymentHistoryMobileItem from './PaymentHistoryMobileItem/PaymentHistoryMobileItem';
import { useRootModel } from '../../../../models/RootStore';

/**
 * Represents Payment history component in Mobile version
 * @returns {JSX.Element}
 */

const PaymentHistoryMobile = () => {
  const { t } = useTranslation();
  const {
    user: {
      paymentHistory: { items, changePeriod, getPaymentHistory, setSortBy },
    },
  } = useRootModel();

  const handleChangeSortByTime = (period) => {
    Object.entries(PERIOD).forEach(([key, value]) => {
      if (t(key) === period) {
        changePeriod(value);
      }
    });
    getPaymentHistory();
  };

  const handleChangeSortByValue = (value) => {
    Object.entries(SORT_BY_VALUES).forEach(([key, val]) => {
      if (val === value) {
        setSortBy(key);
      }
    });
    getPaymentHistory();
  };

  useEffect(() => {
    changePeriod('WEEK');
    getPaymentHistory();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/balance' />
        <p className={style.title}>{t('payment_history')}</p>
      </div>
      <div className={style.paymentHistoryMobileView}>
        {items.length === 0 ? (
          <Empty />
        ) : (
          <div className={style.itemsWrapper}>
            {items?.map(
              ({ id, date, paymentMethod, amount, paymentType, exchangeRate, status }) => (
                <PaymentHistoryMobileItem
                  key={id}
                  transferNumber={id}
                  date={date}
                  paymentMethod={paymentMethod}
                  amount={amount}
                  type={paymentType}
                  exchangeRate={exchangeRate}
                  status={status}
                />
              ),
            )}
          </div>
        )}
        <div className={style.footer}>
          <Dropdown
            className={style.dropdownSortTime}
            contentClassName={style.content}
            sideToOpen='up'
            onChange={handleChangeSortByTime}
          >
            {Object.values(SORT_BY_TIME)}
          </Dropdown>
          <Dropdown
            className={style.dropdownSortValue}
            contentClassName={style.content}
            description={t('sort_by')}
            sideToOpen='up'
            onChange={handleChangeSortByValue}
          >
            {Object.values(SORT_BY_VALUES)}
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default observer(PaymentHistoryMobile);
