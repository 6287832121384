import React from 'react';
import i18n from '../../../translations/i18n';
import DropdownAndSearchBlock from './DropdownAndSearchBlock/DropdownAndSearchBlock';
import TabsOnMainPage from '../../components/TabsOnMainPage/TabsOnMainPage';
import MainTable from './MainTable/MainTable';

const tabs = [
  {
    id: 0,
    tabLabel: i18n.t('popular'),
    component: <MainTable filter='popular' />,
  },
  {
    id: 1,
    tabLabel: i18n.t('crypto'),
    component: <MainTable filter='crypto' />,
  },
  {
    id: 2,
    tabLabel: i18n.t('rising'),
    component: <MainTable filter='rising' />,
  },
  {
    id: 3,
    tabLabel: i18n.t('falling'),
    component: <MainTable filter='falling' />,
  },
  {
    id: 4,
    tabLabel: i18n.t('volatility'),
    component: <MainTable filter='volatility' />,
  },
  {
    id: 5,
    tabLabel: i18n.t('uprising_trend'),
    component: <MainTable filter='uptrend' />,
  },
  {
    id: 6,
    tabLabel: i18n.t('falling_trend'),
    component: <MainTable filter='falltrend' />,
  },
  {
    id: 7,
    tabLabel: i18n.t('commodities'),
    component: <MainTable filter='commodities' />,
  },
];

/**
 * Represents Main page in Mobile version
 * @returns {JSX.Element}
 */

const MainPage = () => {
  return (
    <>
      <DropdownAndSearchBlock />
      <TabsOnMainPage tabs={tabs} />
    </>
  );
};

export default MainPage;
