import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import style from './CancelTradeContent.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import date from '../../../../../util/date';

import TVChartForModal from '../../../../TVChartForModal/TVChartForModal';
import TitleNumberFormat from '../../../../ui/TitleNumberFormat/TitleNumberFormat';
import Button from '../../../../ui/Button/Button';
import ModalHeader from '../../../../Modal/ModalHeader/ModalHeader';
import ModalBody from '../../../../Modal/ModalBody/ModalBody';
import Error from '../../../../Error/Error';
import ModalFooter from '../../../../Modal/ModalFooter/ModalFooter';
import AlertContext from '../../../../../context/alert/alertContext';
import useWindowSize from '../../../../../hooks/useWindowSize';

/**
 * Represents CancelTradeContent component
 * @param handleClose
 * @returns {JSX.Element}
 */
const CancelTradeContent = ({ handleClose }) => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const alert = useContext(AlertContext);
  const {
    modal: {
      cancelTrade: { close },
    },
    settings: { language },
    finance: {
      trade: { order, cancelTrade, isLoading },
    },
  } = useRootModel();

  if (!order) cancelTrade.close();
  const { openedAt, amount, multiplier, openingPrice, id } = order;
  const values = [
    {
      id: 1,
      title: t('opening_date'),
      value: date.toLocaleString(openedAt, language),
      formatted: false,
    },
    { id: 2, title: t('amount'), value: amount, options: { money: true } },
    { id: 3, title: t('multiplier'), value: multiplier, formatted: false, textBeforeValue: 'x' },
    { id: 4, title: t('conditions'), value: openingPrice, textBeforeValue: t('price') },
  ];

  const handleCancelTrade = async () => {
    try {
      await cancelTrade(id);
      alert.show(t('order_canceled'), 'success');
      close();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.tvChartModal}>
        <TVChartForModal />
      </div>
      <div className={style.cancelContent}>
        {isDesktop && (
          <ModalHeader
            wrapperClassName={style.modalHeader}
            handleClose={handleClose}
            title={`${t('cancel_order')}?`}
          />
        )}
        <ModalBody className={style.modalBody}>
          {order ? (
            <div className={style.itemsWrapper}>
              {values.map(({ id: key, title, value, options, textBeforeValue, formatted }) => (
                <TitleNumberFormat
                  key={key}
                  title={title}
                  value={value}
                  formatted={formatted}
                  options={options}
                  textBeforeValue={textBeforeValue}
                  className={style.item}
                />
              ))}
            </div>
          ) : (
            <Error />
          )}
        </ModalBody>
        <ModalFooter className={style.footer}>
          <Button text={t('cancel')} className={style.button} color='secondary' onClick={close} />
          <Button
            text={t('yes_delete')}
            className={style.button}
            color='primary'
            onClick={handleCancelTrade}
            disabled={isLoading}
          />
        </ModalFooter>
      </div>
    </div>
  );
};

export default CancelTradeContent;
