import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import style from './TabsScrollLinkItem.module.scss';

/**
 * Represents tab item component
 * @param {string} tabLabel - tab label
 * @param {string} url - url
 * @returns {JSX.Element}
 */
const TabsScrollLinkItem = ({ tabLabel, url }) => {
  const titleRef = useRef();

  useEffect(() => {
    if (titleRef.current.ariaCurrent === 'page') {
      titleRef.current.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'center' });
    }
  }, []);

  return (
    <li className={style.linkItem}>
      <NavLink to={url} className={style.link} activeClassName={style.active} ref={titleRef}>
        {tabLabel}
      </NavLink>
    </li>
  );
};

export default TabsScrollLinkItem;
