import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './NotificationItem.module.scss';
import Switch from '../../../../../components/ui/Switch/Switch';

/**
 * Represents Notification BalanceRightSideItem
 * @param {number} id - item id
 * @param {string} title - title for notification
 * @param {boolean} status - status
 * @param {string} attributeName - attributeName
 * @param {function} handleSwitch - switch handler
 * @returns {JSX.Element}
 */
const NotificationItem = ({ id, title, status, attributeName, handleSwitch }) => {
  const { t } = useTranslation();

  const switchHandler = () => {
    if (handleSwitch) handleSwitch(attributeName, !status);
  };

  return (
    <div className={style.notificationItem}>
      <div className={style.text}>
        <p className={style.title}>{title}</p>
        <p className={style.status}>
          {t('status')}:{' '}
          <span className={status ? style.statusValueEnabled : style.statusValueDisabled}>
            {status ? t('enabled') : t('disabled')}
          </span>
        </p>
      </div>
      <Switch checked={status} onChange={switchHandler} id={id} title={title} />
    </div>
  );
};

export default NotificationItem;
