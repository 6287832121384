import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './CurrentBalance.module.scss';
import { useRootModel } from '../../models/RootStore';

import CurrentBalanceItem from './CurrentBalanceItem/CurrentBalanceItem';

/**
 * Represents CurrentBalance
 * @param {CSSStyleRule} className - wrapper className
 * @param {CSSStyleRule} itemClassName - item className
 * @param {CSSStyleRule} titleClassName - title className
 * @param {CSSStyleRule} valueClassName - value className
 * @returns {JSX.Element}
 */
const CurrentBalance = ({ className, itemClassName, titleClassName, valueClassName }) => {
  const {
    user: { userData },
  } = useRootModel();

  useEffect(() => {
    const getBalance = () => {
      if (userData) userData.getBalance();
    };
    getBalance();
    const interval = setInterval(getBalance, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!userData) return <></>;
  const { balance, profit, used, available } = userData;

  const { t } = useTranslation();

  const items = [
    { id: 1, title: t('balance'), value: balance },
    { id: 2, title: t('profit'), value: profit },
    { id: 3, title: t('used'), value: used },
    { id: 4, title: t('available'), value: available },
  ];
  return (
    <div className={cn(style.currentBalance, className)}>
      {items.map(({ id, title, value }) => (
        <CurrentBalanceItem
          itemClassName={itemClassName}
          valueClassName={cn({ [style.usedValue]: id === 3 }, valueClassName)}
          title={title}
          value={value}
          key={title}
          titleClassName={titleClassName}
        />
      ))}
    </div>
  );
};

export default observer(CurrentBalance);
