import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './ProductItemDetailPrice.module.scss';
import string from '../../../../../util/string';
import { useRootModel } from '../../../../../models/RootStore';

/**
 * Represents Instrument title and price block on Mobile version
 * @returns {JSX.Element}
 */

const ProductItemDetailPrice = () => {
  const { t } = useTranslation();
  const {
    finance: { item, activeSymbol },
  } = useRootModel();
  return (
    <div className={style.priceBlock}>
      <div>
        <p>{activeSymbol}</p>
        <p className={style.text}>{item?.alias}</p>
      </div>
      <div>
        <p>{string.formatNumber(item?.lp)}</p>
        <p className={style.text}>{t('price')}</p>
      </div>
      <div>
        <p
          className={cn({
            [style.colorDanger]: item?.cpd < 0,
            [style.colorSuccess]: item?.cpd > 0,
          })}
        >
          {string.formatPercent(item?.cpd, { withSign: true })}
        </p>
        <p className={style.text}>{t('for_day')}</p>
      </div>
    </div>
  );
};

export default observer(ProductItemDetailPrice);
