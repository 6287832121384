import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import style from './OpenTradeButton.module.scss';

import { ReactComponent as Open } from '../../../assets/image/common/openTradeIcon.svg';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents Open Trade Button
 * @param {CSSStyleRule} className - className for the link
 * @returns {JSX.Element}
 */

const OpenTradeButton = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    user: { isAuthorized },
    modal: {
      openTrade: { open },
    },
  } = useRootModel();

  const clickHandler = () => {
    if (!isAuthorized) {
      history.push('/login');
      return;
    }
    open();
  };
  return (
    <button type='button' className={cn(style.button, className)} onClick={clickHandler}>
      <Open />
      <p>{t('open')}</p>
    </button>
  );
};

export default OpenTradeButton;
