import React from 'react';
import style from './LanguageItem.module.scss';
import { ReactComponent as ActiveIcon } from '../../../../../assets/image/common/verified.svg';

/**
 * Represents LanguageItem
 * @param {string} language - language
 * @param {function} onClick - click handler
 * @param {boolean} isActive - isActive
 * @returns {JSX.Element}
 */
const LanguageItem = ({ language, onClick, isActive = false }) => {
  const handleClick = () => {
    onClick(language);
  };

  return (
    <li className={style.languageItem}>
      <button type='button' className={style.button} onClick={handleClick}>
        {isActive && <ActiveIcon />}
        {language}
      </button>
    </li>
  );
};

export default LanguageItem;
