import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './Trades.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import ActiveTradesItem from './ActiveTradesItem/ActiveTradesItem';
import ButtonToggle from '../../../ButtonToggle/ButtonToggle';

/**
 * Represents a Trades block inside Active trades block.
 * @returns {JSX.Element}
 */
const Trades = () => {
  const { t } = useTranslation();
  const {
    finance: {
      activeTrades: { sortedByTime },
    },
  } = useRootModel();
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div className={style.container}>
      <div className={style.instrumentTitle}>
        <p>{t('instrument')}</p>
        <p>{t('price')}</p>
        <div className={style.profitBlock}>
          <p>{t('profit')}</p>
        </div>
      </div>
      <div className={style.contentVisible}>
        {sortedByTime
          .slice(0, 3)
          .map(
            ({
              symbol: { s },
              id,
              amount,
              profit,
              lastPrice,
              multiplier,
              profitPercent,
              operation,
            }) => {
              return (
                <ActiveTradesItem
                  key={id}
                  symbol={s}
                  price={lastPrice}
                  amount={amount}
                  percent={profitPercent}
                  multiplier={multiplier}
                  profit={profit}
                  operation={operation}
                  id={id}
                />
              );
            },
          )}
      </div>
      <div className={cn(style.content, { [style.visible]: isVisible })}>
        {sortedByTime
          .slice(3, sortedByTime.length)
          .map(
            ({
              symbol: { s },
              id,
              amount,
              profit,
              lastPrice,
              multiplier,
              profitPercent,
              operation,
            }) => {
              return (
                <ActiveTradesItem
                  key={id}
                  symbol={s}
                  price={lastPrice}
                  amount={amount}
                  percent={profitPercent}
                  multiplier={multiplier}
                  profit={profit}
                  operation={operation}
                  id={id}
                />
              );
            },
          )}
      </div>
      {sortedByTime.length > 3 && (
        <ButtonToggle isVisible={isVisible} handleToggle={handleToggle} />
      )}
      <div className={style.buttonBlock}>
        <Link to='/trades/active' className={style.link}>
          {t('active_trades')}
        </Link>
        <Link to='/trades/closed' className={style.link}>
          {t('closed_trades')}
        </Link>
      </div>
    </div>
  );
};

export default observer(Trades);
