import { types } from 'mobx-state-tree';
import { flow } from 'mobx';
import axios from 'axios';
import eventSource from '../../eventSource/eventSource';
import error from '../../util/error';

const NotificationItemModel = types.model('NotificationItemModel', {
  title: types.maybeNull(types.string),
  body: types.string,
  id: types.number,
  isRead: types.boolean,
  notificationDate: types.string,
  recipientId: types.number,
  checked: types.optional(types.boolean, false),
});

const NotificationsModel = types
  .model('NotificationsModel', {
    isLoading: types.optional(types.boolean, false),
    isLoadingItems: types.optional(types.boolean, false),
    items: types.array(NotificationItemModel),
    page: types.optional(types.number, 0),
    pageSize: types.optional(types.number, 4),
    activeNotificationId: types.optional(types.number, 0),
    error: types.optional(types.string, ''),
    amountTotal: types.optional(types.number, 0),
    amountUnread: types.optional(types.number, 0),
  })
  .actions((notifications) => ({
    setIsLoading(loading) {
      notifications.isLoading = loading;
    },
    setIsLoadingItems(loading) {
      notifications.isLoadingItems = loading;
    },
    setError(message) {
      notifications.error = message;
    },
    clearError() {
      notifications.error = '';
    },
    setItems(items) {
      notifications.items = items;
    },
    add(item) {
      const { items, setItems } = notifications;
      setItems([item, ...items]);
    },
    setAmountTotal(amount) {
      notifications.amountTotal = amount;
    },
    addAmountTotal() {
      notifications.amountTotal += 1;
    },
    setAmountUnread(amount) {
      notifications.amountUnread = amount;
    },
    addAmountUnread() {
      notifications.amountUnread += 1;
    },
    reduceAmountUnread() {
      notifications.amountUnread -= 1;
    },
    getNotifications: flow(function* getNotifications() {
      const {
        setIsLoading,
        setItems,
        setError,
        clearError,
        setAmountTotal,
        page,
        pageSize,
        setAmountUnread,
        setIsLoadingItems,
      } = notifications;
      setIsLoading(true);
      setIsLoadingItems(true);
      clearError();
      try {
        const { headers, data } = yield axios.get(`/services/notification/api/notifications`, {
          params: { page, size: pageSize },
        });
        setItems(data);
        const total = parseInt(headers['x-total-count'], 10);
        setAmountTotal(total);
        const unread = parseInt(headers['x-total-unread-count'], 10);
        setAmountUnread(unread);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
        setIsLoadingItems(false);
      }
    }),
    getNotificationsUpdates() {
      try {
        const event = eventSource.createEventSourceNotifications();
        event.onmessage = (e) => {
          const { add, addAmountTotal, addAmountUnread } = notifications;
          const data = JSON.parse(e.data);
          if (!data?.id) return;
          add(data);
          addAmountTotal();
          addAmountUnread();
        };
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        notifications.setError(message);
        error.errorHandler(message);
      }
    },
    closeStream() {
      eventSource.getEventSourceNotifications().close();
    },
    toggleChecked(id) {
      const { items, setItems } = notifications;
      const array = items.map((i) => (i.id === id ? { ...i, checked: !i.checked } : i));
      setItems(array);
    },
    toggleCheckedAll() {
      const { items, setItems, isCheckedAll } = notifications;
      const newItems = items.map((i) => ({ ...i, checked: isCheckedAll ? !i.checked : true }));
      setItems(newItems);
    },
    setPage(page) {
      const { setItems, items } = notifications;
      notifications.page = page;
      setItems(items.map((i) => ({ ...i, checked: false })));
    },
    setNextPage() {
      const { totalPages, page, setPage } = notifications;
      if (page - 1 < totalPages) setPage(page + 1);
    },
    setPreviousPage() {
      const { page, setPage } = notifications;
      if (page > 0) setPage(page - 1);
    },
    setIsRead: flow(function* setIsRead(id) {
      const { items, setItems, setIsLoading, setError, clearError, reduceAmountUnread } =
        notifications;
      const item = items.find((i) => i.id === id);
      if (!item || item.isRead) return;
      setIsLoading(true);
      clearError();
      try {
        yield axios.put(`/services/notification/api/notifications`, [item]);
        setItems(items.map((i) => (i.id === id ? { ...i, isRead: true } : i)));
        reduceAmountUnread();
      } catch (err) {
        const message = err.response?.data.message || err.message;
        setError(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    setAllIsRead: flow(function* setAllIsRead() {
      const {
        setError,
        clearError,
        checked,
        setIsLoading,
        setItems,
        items,
        setAmountUnread,

        amountUnread,
      } = notifications;

      setIsLoading(true);
      clearError();
      try {
        yield axios.put(`/services/notification/api/notifications`, checked);
        const totalNotRead = checked.reduce((acc, cur) => (!cur.isRead ? acc + 1 : acc), 0);
        setItems(items.map((i) => ({ ...i, checked: false, isRead: i.checked ? true : i.isRead })));
        if (totalNotRead) setAmountUnread(amountUnread - totalNotRead);
      } catch (err) {
        const message = err.response?.data.message || err.message;
        setError(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    deleteNotifications: flow(function* deleteNotifications() {
      const {
        checked,
        setError,
        clearError,
        setIsLoading,
        items,
        setItems,
        setAmountUnread,
        amountUnread,
      } = notifications;
      setIsLoading(true);
      clearError();
      try {
        yield axios.delete(`/services/notification/api/notifications`, {
          data: checked,
        });
        const totalNotRead = checked.reduce((acc, cur) => (cur.isRead ? acc + 1 : acc), 0);
        if (totalNotRead) setAmountUnread(amountUnread - totalNotRead);
        setItems(items.filter((i) => !i.checked));
      } catch (err) {
        const message = err.response?.data.message || err.message;
        setError(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    setActiveNotificationId(id) {
      notifications.activeNotificationId = id;
    },
    setIsModalOpen(isOpen) {
      notifications.isModalOpen = isOpen;
    },
  }))
  .views((notifications) => ({
    get checked() {
      const { items } = notifications;
      return items.filter(({ checked }) => checked);
    },
    get isCheckedAll() {
      const { items } = notifications;
      return items.every((i) => i.checked);
    },
    get totalPages() {
      const { amountTotal, pageSize } = notifications;
      return Math.ceil(amountTotal / pageSize);
    },
    get isFirstPage() {
      return notifications.page === 0;
    },
    get isLastPage() {
      const { totalPages, page } = notifications;
      return totalPages === page + 1;
    },
    get activeNotification() {
      const { items, activeNotificationId } = notifications;
      return items.find((i) => i.id === activeNotificationId);
    },
    get amountChecked() {
      return notifications.checked.length;
    },
  }));

export default NotificationsModel;
