import React from 'react';
import { observer } from 'mobx-react';

import style from './Notifications.module.scss';
import { useRootModel } from '../../models/RootStore';

import NotificationItem from './NotificationItem/NotificationItem';
import NotificationsHeader from './NotificationsHeader/NotificationsHeader';
import NotificationsFooter from './NotificationsFooter/NotificationsFooter';
import Preloader from '../ui/Preloader/Preloader';

/**
 * Represents Notifications component
 * @param {ref} wrapperRef - reference to wrapper
 * @returns {JSX.Element}
 */
const Notifications = ({ wrapperRef }) => {
  const {
    user: {
      notifications: { items, isLoadingItems },
    },
  } = useRootModel();

  return (
    <div className={style.notifications} ref={wrapperRef}>
      <NotificationsHeader />
      {isLoadingItems && <Preloader />}
      {!isLoadingItems &&
        items.map(({ id, title, body, checked, isRead }) => (
          <NotificationItem
            isRead={isRead}
            key={id}
            id={id}
            text={body}
            title={title}
            checked={checked}
          />
        ))}
      <NotificationsFooter />
    </div>
  );
};

export default observer(Notifications);
