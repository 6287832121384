import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents PrivateRoute
 * @param {boolean} exact - is path exact
 * @param {string} path - route path
 * @param {JSX.Element} Component - component for render
 * @returns {JSX.Element}
 */
const PrivateRoute = ({ exact, path, component: Component }) => {
  const {
    user: { isAuthorized },
  } = useRootModel();

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return !isAuthorized ? <Redirect to='/' /> : <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
