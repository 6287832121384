import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './ActiveTradesItemInstrument.module.scss';
import emptyFunction from '../../../../../../util/emptyFunction';
import string from '../../../../../../util/string';
import { OPERATION } from '../../../../../../constant/commonConstants';
import { ReactComponent as ArrowDown } from '../../../../../../assets/image/common/downArr.svg';
import { ReactComponent as ArrowUp } from '../../../../../../assets/image/common/upArr.svg';
import CoinFormat from '../../../../../CoinFormat/CoinFormat';

/**
 * Represents ActiveTradesItemInstrument component
 * @param {string} symbol - instrument symbol
 * @param {number} price - instrument price
 * @param {number} multiplier - instrument multiplier
 * @param {number} profit - instrument profit
 * @param {number} percent - instrument percent
 * @param {number} amount - instrument amount
 * @param {'BUY' | 'SELL'} operation - instrument amount
 * @param {function} onClick - onClick handler
 * @returns {JSX.Element}
 */
const ActiveTradesItemInstrument = ({
  symbol,
  price,
  multiplier,
  profit,
  percent,
  amount,
  onClick,
  operation,
}) => {
  return (
    <div
      role='button'
      tabIndex={-1}
      className={style.instrument}
      onKeyDown={emptyFunction}
      onClick={onClick}
    >
      <div className={style.instrument__block}>
        <p className={style.instrument__text}>{symbol}</p>
        <span>{operation === OPERATION.buy ? <ArrowUp /> : <ArrowDown />}</span>
      </div>
      <div>
        <p className={style.price}>{string.formatNumber(price)}</p>
        <div className={style.bottomPrice}>
          <CoinFormat value={amount} withoutSign={true} />x{multiplier}
        </div>
      </div>
      <div className={style.priceBlock}>
        <div className={style.price}>
          <div
            className={cn({ [style.colorDanger]: profit < 0, [style.colorSuccess]: profit > 0 })}
          >
            <CoinFormat value={profit} />
          </div>
        </div>
        <p className={style.bottomPrice}>
          <span
            className={cn({ [style.colorDanger]: percent < 0, [style.colorSuccess]: percent > 0 })}
          >
            ({string.formatPercent(percent, { withSign: true })})
          </span>
        </p>
      </div>
    </div>
  );
};

export default observer(ActiveTradesItemInstrument);
