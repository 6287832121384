import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from '../../HeaderUserInfo.module.scss';
import { useRootModel } from '../../../../../models/RootStore';

// eslint-disable-next-line max-len
import { ReactComponent as Brightness } from '../../../../../assets/image/common/brightness-mini.svg';
import Switch from '../../../../ui/Switch/Switch';

/**
 * Represents ButtonSwitchBrightness component
 * @returns {JSX.Element}
 */
const ButtonSwitchBrightness = () => {
  const { t } = useTranslation();
  const {
    settings: { theme, toggleTheme },
  } = useRootModel();

  const SwitchBrightness = () => (
    <Switch
      labelClassName={style.switch}
      sliderClassName={style.switchSlider}
      checked={theme === 'dark'}
      onChange={toggleTheme}
    />
  );

  return (
    <div
      role='button'
      className={cn(style.buttonWrapper, style.darkMode)}
      onClick={() => toggleTheme()}
      onKeyDown={() => toggleTheme()}
      tabIndex={-1}
    >
      <div className={style.icon}>
        <Brightness />
        <span>{t('dark')}</span>
      </div>
      <div>
        <SwitchBrightness />
      </div>
    </div>
  );
};

export default observer(ButtonSwitchBrightness);
