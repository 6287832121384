import React from 'react';
import style from './RigthSideBlock.module.scss';
import DemoAccount from './DemoAccount/DemoAccount';
import BalanceRightSide from './BalanceRightSide/BalanceRightSide';
import Favorites from './Favorites/Favorites';
import ActiveTradesRightSide from './ActiveTradesRightSide/ActiveTradesRightSide';
import PendingRightSide from './PendingRightSide/PendingRightSide';

/**
 * Represents Right side blocks on Main Page.
 * @returns {JSX.Element}
 */

const RightSideBlock = () => {
  return (
    <div className={style.rightSideBlock}>
      <div className={style.wrapper}>
        <DemoAccount />
        <BalanceRightSide />
        <ActiveTradesRightSide />
        <PendingRightSide />
        <Favorites />
      </div>
    </div>
  );
};

export default RightSideBlock;
