import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import style from './OpenTradeContent.module.scss';

import useWindowSize from '../../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../../models/RootStore';
import AlertContext from '../../../../../context/alert/alertContext';

import { ReactComponent as Down } from '../../../../../assets/image/common/curveDown.svg';
import { ReactComponent as Up } from '../../../../../assets/image/common/curveUp.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';

import TabsForModal from '../../../../ui/TabsForModal/TabsForModal';
import TVChartForModal from '../../../../TVChartForModal/TVChartForModal';
import Now from './Now/Now';
import Price from './Price/Price';
import LimitProfitsAndLost from '../../../../LimitProfitsAndLost/LimitProfitsAndLost';
import Preloader from '../../../../ui/Preloader/Preloader';

/**
 * Represents Open Trade Content in Modal after click Open Trade Button
 * @param {function} handleClose - handle close Modal button
 * @returns {JSX.Element}
 */
const OpenTradeContent = ({ handleClose }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const { isDesktop } = useWindowSize();
  const {
    user: {
      userData: { demoVersion, realAccount, documentsVerificationStatus },
    },
    modal: {
      openTrade: { close },
    },
    finance: {
      item,
      trade: { amount, setOperation, openTrade, getMaxMultiplier, isLoading },
      pendingTrades: { getPendingTrades },
      activeTrades: { getActiveTrades },
    },
  } = useRootModel();

  useEffect(() => {
    getMaxMultiplier();
  }, []);

  if (!item) return <></>;
  const { bid, ask } = item;

  const tabs = [
    {
      id: 1,
      tabLabel: t('now'),
      component: Now,
    },
    {
      id: 2,
      tabLabel: t('at_price'),
      component: Price,
    },
  ];

  const handleOperation = async (operation) => {
    try {
      if (!demoVersion && realAccount.available < amount) {
        close();
        history.push('/balance');
        return;
      }
      if (!demoVersion && documentsVerificationStatus !== 'APPROVED') {
        close();
        alert.show(t('upload_description'), 'error');
        history.push('/profile', { activeTab: 2 });
        return;
      }
      await setOperation(operation);
      await openTrade();
      getActiveTrades();
      getPendingTrades();
      close();
      alert.show(t('trade_open'), 'success');
    } catch (err) {
      alert.show(t(err.message));
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.tvChart}>
        <TVChartForModal />
      </div>

      {isLoading ? (
        <div className={style.loader}>
          <Preloader />
        </div>
      ) : (
        <div className={style.container}>
          {isDesktop && (
            <button type='button' onClick={handleClose} className={style.closeButton}>
              <CloseIcon />
            </button>
          )}
          <TabsForModal tabs={tabs} />
          <LimitProfitsAndLost amount={amount} />
          <div className={style.footerBlock}>
            <div className={style.buttonContainer}>
              <button
                type='button'
                className={cn(style.button, style.red)}
                onClick={() => handleOperation('SELL')}
                disabled={amount < 1}
              >
                <p>{t('sell')}</p>
                <Down />
              </button>
              <button
                type='button'
                className={cn(style.green, style.button)}
                onClick={() => handleOperation('BUY')}
                disabled={amount < 1}
              >
                <p>{t('buy')}</p>
                <Up />
              </button>
            </div>
            <div className={style.footer}>
              <p>
                {t('bid')}: {bid}
              </p>
              <p className={style.greyColor}>Spread: {Number((bid - ask).toFixed(10))}</p>
              <p>
                {t('ask')}: {ask}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(OpenTradeContent);
