import React from 'react';
import { observer } from 'mobx-react';
import numeral from 'numeral';

import style from './HeaderBalance.module.scss';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents HeaderBalance
 * @returns {JSX.Element}
 */
const HeaderBalance = () => {
  const {
    user: { userData },
    modal: { currentBalance },
  } = useRootModel();

  return (
    <button type='button' className={style.currentBalance} onClick={currentBalance.toggle}>
      <span className={style.currency}>$</span>
      <span>{numeral(userData?.balance).format('0,0.00')}</span>
    </button>
  );
};

export default observer(HeaderBalance);
