import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './NotificationsMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import Notifications from '../../../../pages/Profile/Notifications/Notifications';

/**
 * Represents Notifications component in Mobile version
 * @returns {JSX.Element}
 */

const NotificationsMobile = () => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/profile' />
        <p className={style.title}>{t('notifications')}</p>
      </div>
      <Notifications />
    </div>
  );
};

export default NotificationsMobile;
