import React from 'react';
import { observer } from 'mobx-react';

import style from './ActiveTradesItem.module.scss';
import { useRootModel } from '../../../../../models/RootStore';

import ActiveTradesItemInstrument from './ActiveTradesItemInstrument/ActiveTradesItemInstrument';
import ActiveTradesItemInfo from './ActiveTradesItemInfo/ActiveTradesItemInfo';

/**
 * Represents ActiveTradesItem component
 * @param {number} id - trade id
 * @param {string} symbol - trade symbol
 * @param {number} price - trade price
 * @param {number} amount - trade openPrice
 * @param {number} multiplier - trade multiplier
 * @param {number} profit - trade profit
 * @param {number} percent - trade percent
 * @param {'BUY' | 'SELL'} operation - trade amount
 * @returns {JSX.Element}
 */
const ActiveTradesItem = ({
  id,
  symbol,
  price,
  amount,
  profit,
  percent,
  multiplier,
  operation,
}) => {
  const {
    finance: {
      setActiveSymbol,
      activeTrades: { setActiveTradeId },
    },
    modal: { activeTrade, increaseTrade, closeTrade },
  } = useRootModel();

  const setSymbol = async () => {
    await setActiveSymbol(symbol);
    await setActiveTradeId(id);
  };

  const handleClickIncrease = async () => {
    await setSymbol();
    increaseTrade.open();
  };

  const handleClickInfo = async () => {
    await setSymbol();
    activeTrade.open();
  };

  const handleClickTradeClose = async () => {
    await setSymbol();
    closeTrade.open();
  };

  return (
    <div className={style.instrumentBlock}>
      <ActiveTradesItemInstrument
        profit={profit}
        multiplier={multiplier}
        percent={percent}
        amount={amount}
        price={price}
        symbol={symbol}
        onClick={handleClickInfo}
        operation={operation}
      />
      <div className={style.info}>
        <ActiveTradesItemInfo
          onClickIncrease={handleClickIncrease}
          onClickInfo={handleClickInfo}
          onClickCloseTrade={handleClickTradeClose}
        />
      </div>
    </div>
  );
};

export default observer(ActiveTradesItem);
