import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './CloseTradeContentMobile.module.scss';

import { ReactComponent as Close } from '../../../../../assets/image/common/closeInCircleWhite.svg';
import Dropdown from '../../../../ui/Dropdown/Dropdown';
import { ReactComponent as Dollar } from '../../../../../assets/image/common/dollar.svg';
import { ReactComponent as Dots } from '../../../../../assets/image/common/dots.svg';
import { useRootModel } from '../../../../../models/RootStore';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';
import AlertContext from '../../../../../context/alert/alertContext';
import TabsOnMainPage from '../../../../../MobileApp/components/TabsOnMainPage/TabsOnMainPage';
// eslint-disable-next-line max-len
import CommissionsAndReportsTop from '../../ActiveTradeModal/ActiveTradesContent/CommissionsAndReports/CommissionsAndReportsTop/CommissionsAndReportsTop';
// eslint-disable-next-line max-len
import GeneralInformationTop from '../../ActiveTradeModal/ActiveTradesContent/GeneralInformation/GeneralInformationTop/GeneralInformationTop';

const CloseTradeContentMobile = ({ handleClose }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    modal: { closeTradeMobile, increaseTradeMobile },
    finance: {
      activeTrades: { activeTrade, getActiveTrades },
      closedTrades: { getClosedTrades },
      trade: { closeTrade },
    },
  } = useRootModel();

  const { id } = activeTrade;

  const tabs = [
    {
      id: 0,
      tabLabel: t('general_information'),
      component: <GeneralInformationTop />,
    },
    {
      id: 1,
      tabLabel: t('commissions_and_reports'),
      component: <CommissionsAndReportsTop />,
    },
  ];

  const openIncreaseTradeContent = () => {
    increaseTradeMobile.open();
    closeTradeMobile.close();
  };

  const MainComponent = () => (
    <button type='button' className={style.secondaryButton}>
      <Dots />
    </button>
  );

  const handleCloseTrade = async () => {
    try {
      await closeTrade(id);
      alert.show(t('order_closed'), 'success');
      closeTradeMobile.close();
      getActiveTrades();
      getClosedTrades();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.closeTradeModalContainer}>
      <div className={style.header}>
        <p>{t('close_out_trade_position')}</p>
        <button type='button' onClick={handleClose} className={style.closeButton}>
          <CloseIcon />
        </button>
      </div>
      <TabsOnMainPage tabs={tabs} />
      <div className={style.buttonContainer}>
        <button type='button' className={cn(style.button, style.red)} onClick={handleCloseTrade}>
          <Close />
          <p>{t('close_trade_position')}</p>
        </button>

        <Dropdown
          mainComponent={MainComponent}
          changeMainComponent={false}
          showArrow={false}
          sideToOpen='up'
          className={style.dropdownButtonClass}
          itemClassName={style.item}
          contentClassName={style.content}
        >
          <button type='button' className={style.dropdownButton} onClick={openIncreaseTradeContent}>
            <Dollar />
            <p className={style.buttonText}>{t('increase_trade')}</p>
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

export default observer(CloseTradeContentMobile);
