import React from 'react';
import { observer } from 'mobx-react';

import style from './DepositModal.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import useWindowSize from '../../../../hooks/useWindowSize';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import DepositMoney from '../../../DepositMoney/DepositMoney';

/**
 * Represents DepositModal component
 * @returns {JSX.Element}
 */
const DepositModal = () => {
  const { isMobile } = useWindowSize();
  const {
    user: { demoAccount },
    modal: {
      deposit: { isOpen, close },
    },
  } = useRootModel();
  return (
    <>
      {!isMobile && demoAccount && isOpen && (
        <Modal
          handleClose={close}
          header
          headerTitle='Deposit'
          wrapperClassname={style.depositModal}
        >
          <ModalBody className={style.modalBody}>
            <DepositMoney className={style.modalContent} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(DepositModal);
