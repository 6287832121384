import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import App from './App';
import './style/main.scss';
import './translations/i18n';
import { rootStore, RootStoreContext } from './models/RootStore';

// todo activate Sentry
// Sentry.init({
//   dsn: 'https://c14a26d04c5745ac8590ed7feb8446ca@o983786.ingest.sentry.io/5939770',
//   integrations: [new Integrations.BrowserTracing()],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <BrowserRouter>
    <RootStoreContext.Provider value={rootStore}>
      <App />
    </RootStoreContext.Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
