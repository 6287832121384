import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import style from './AffiliateMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import { ReactComponent as Affiliate } from '../../../../assets/image/common/affiliateMobile.svg';
import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody/ModalBody';
import AffiliateRulesMobile from './AffiliateRulesMobile/AffiliateRulesMobile';

/**
 * Represents Affiliate mobile component
 * @returns {JSX.Element}
 */

const AffiliateMobile = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [link] = useState('Some link txt');
  const inputAreaRef = useRef(null);

  const copyCodeToClipboard = async () => {
    await inputAreaRef.current.select();
    document.execCommand('copy');
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/profile/settings' />
        <p className={style.title}>{t('affiliate_program')}</p>
      </div>
      <article className={style.affiliateMobile}>
        <h3 className={style.inviteTitle}>{t('invite_friends')}</h3>
        <div className={style.shareBlock}>
          <div className={style.imageBlock}>
            <Affiliate />
          </div>
          <h4 className={style.title}>{t('share_link')}</h4>
          <p className={style.text}>{t('share_text')}</p>
          <input
            className={style.inputAffiliate}
            type='text'
            value={link}
            readOnly
            ref={inputAreaRef}
          />
          <button type='button' className={style.copyButton} onClick={copyCodeToClipboard}>
            {t('copy')}
          </button>
          <button type='button' className={style.inviteText} onClick={handleOpenModal}>
            {t('program_rules')}
          </button>
        </div>
      </article>

      {showModal && (
        <Modal handleClose={handleCloseModal} wrapperClassname={style.modalWrapper}>
          <ModalBody className={style.modal}>
            <AffiliateRulesMobile handleClose={handleCloseModal} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default AffiliateMobile;
