import i18n from '../translations/i18n';

export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';
export const DEFAULT_THEME = THEME_DARK;
export const DEFAULT_LANGUAGE = 'en';
export const LANGUAGES = {
  en: 'English',
  de: 'German',
  it: 'Italian',
  ru: 'Russian',
  fr: 'French',
  pt: 'Portuguese',
  ja: 'Japanese',
  pl: 'Polish',
  es: 'Spanish',
  nl: 'Dutch',
  tr: 'Turkish',
  cn: 'Chinese',
};
export const OPERATION = {
  buy: 'BUY',
  sell: 'SELL',
};

export const PAGES_WITHOUT_SIDEBAR = ['verification', 'login', 'forgot-password', 'reset-password'];
export const PAGES_WITHOUT_AUTH_BUTTON = PAGES_WITHOUT_SIDEBAR;

export const GENERAL_SEND_CODE_RESTORE_SECONDS = 600;
export const ENTER_CODE_RESTORE_SECONDS = 180;
export const ENTER_CODE_RESTORE_STORAGE_STRING = 'enterCodeRestoreTime';
export const SEND_CODE_RESTORE_SECONDS = 180;
export const SEND_CODE_RESTORE_STORAGE_STRING = 'sendCodeRestoreTime';
export const VERIFY_SEND_CODE_RESTORE_SECONDS = 60;

export const VERIFY_ATTEMPTS_STORAGE_STRING = 'attempts';
export const VERIFY_ATTEMPTS_COUNT = 3;
export const VERIFY_ATTEMPTS_RESTORE_SECONDS = 60 * 60 * 3;

export const PERIOD = {
  week: 'WEEK',
  month: 'MONTH',
  quarter: 'QUARTER',
  all_time: 'ALL_TIME',
};

export const PERIOD_PRICE_CHANGE = ['day', 'week', 'month', 'year'];

export const SORT_BY_TIME = {};
Object.entries(PERIOD).forEach(([translateValue, modelValue]) => {
  SORT_BY_TIME[modelValue] = i18n.t(translateValue);
});

export const sortByTime = Object.keys(PERIOD).map((p) => i18n.t(p));
export const sortByQuantity = [10, 20, 30, 40, 50];
export const SORT_VALUES = {
  id: 'id',
  date: 'date',
  payment: 'payment',
  amount: 'amount',
  type: 'type',
  exchange: 'exchange',
  status: 'status',
};
export const sortByValue = Object.keys(SORT_VALUES).map((v) => i18n.t(v));
export const SORT_BY_VALUES = {};
Object.entries(SORT_VALUES).forEach(([translateValue, modelValue]) => {
  SORT_BY_VALUES[modelValue] = i18n.t(translateValue);
});

// export const sortByValue = [
//   'ID',
//   'Date',
//   'Payment method',
//   'Deposit',
//   'Withdrawal',
//   'Exchange rate',
//   'Status',
// ];

export const PAYMENT_HISTORY_OPTIONS = [
  'id',
  'date',
  'payment',
  'amount',
  'type',
  'exchange',
  'status',
];

export const DOCUMENT_FILE_SIZE = 10 * 1024 * 1024;
export const DOCUMENT_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
export const CHAT_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
];
export const CHAT_FILE_SIZE = 10 * 1024 * 1024;

export const COLOR = {
  primary: '#4671e0',
  white: '#fff',
};

export const operationTitle = {
  CLOSE_TRADE: 'Close trade position for',
  INCREASE_INVESTMENT: 'Increase investment for',
  OPEN_ACCOUNT: 'The account has been opened.',
  OPEN_TRADE: 'Open trade position for',
  STOP_LOSS: 'Take loss for',
  TAKE_PROFIT: 'Take profit for',
};

export const historyOperation = {
  PLACE_PENDING_ORDER: 'Place pending order',
  EXECUTE_PENDING_ORDER: 'Execute pending order',
  OPEN_TRADE: 'Open an investment',
  SET_LIMITS: 'Set limits',
  INCREASE_INVESTMENT: 'Increase investment amount',
  CLOSE_TRADE: 'Close position',
  LIMIT_REACHED: 'The investment is being closed out due to reaching ($3599) profit/loss',
  TOTAL_LOSS: 'The position will be closed because it has lost 100%',
  CANCEL_ORDER: 'Cancel pending order',
};

export const DEFAULT_SYMBOL = 'GBPUSD';
export const DEFAULT_CATEGORY = 'forex';
export const DEFAULT_INTERVAL = '1D';

export const supportSections = [
  {
    name: 'General questions',
    supportType: 'GENERAL_QUESTIONS',
  },
  { name: 'Login recovery', supportType: 'LOGIN_RECOVERY' },
  { name: 'Refill of funds', supportType: 'REFILL_OF_FUNDS' },
  { name: 'Withdrawals', supportType: 'WITHDRAWALS' },
  { name: 'Financial claim', supportType: 'FINANCIAL_CLAIM' },
  { name: 'Closing of account', supportType: 'CLOSING_OF_ACCOUNT' },
];

export const documentsStatus = {
  PENDING: i18n.t('documents_progress'),
  APPROVED: i18n.t('documents_approved'),
  DECLINED: i18n.t('documents_rejected'),
  EXPIRED: i18n.t('documents_expired'),
};
