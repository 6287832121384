import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from '../../HeaderUserInfo.module.scss';
import { ReactComponent as Star } from '../../../../../assets/image/common/star-mini.svg';

/**
 * Represents ButtonAffilateProgram component
 * @returns {JSX.Element}
 */
const ButtonAffilateProgram = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleAffilateProgram = () => {
    history.push('/info/affiliate');
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Star />
      </i>
      <button type='button' className={style.button} onClick={handleAffilateProgram}>
        {t('affilate_program')}
      </button>
    </div>
  );
};

export default ButtonAffilateProgram;
