import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './PriceChange.module.scss';
import { useRootModel } from '../../models/RootStore';
import { PERIOD_PRICE_CHANGE } from '../../constant/commonConstants';

import { ReactComponent as Clock } from '../../assets/image/common/clock.svg';
import { ReactComponent as Done } from '../../assets/image/common/doneDoubleBlue.svg';
import Dropdown from '../ui/Dropdown/Dropdown';
import TitleNumberFormat from '../ui/TitleNumberFormat/TitleNumberFormat';

/**
 * Represents Price change component
 * @param {CSSStyleRule} className - wrapper className
 * @returns {JSX.Element}
 */
const PriceChange = ({ className }) => {
  const { t } = useTranslation();
  const {
    finance: { setActivePeriod, activeLow, activeHigh, period, pricePercent },
  } = useRootModel();

  return (
    <div className={cn(style.container, className)}>
      <div className={style.priceBlock}>
        <p>{t('price_change')}</p>
        <Dropdown
          mainComponent={() => <p>{t(period)}</p>}
          changeMainComponent={false}
          description={<Clock />}
          contentClassName={style.contentDropdown}
          itemClassName={style.itemDropdown}
          currentItemWrapperClassName={style.itemDropdownWrapper}
        >
          {PERIOD_PRICE_CHANGE.map((_period) => {
            return (
              <button
                key={_period}
                type='button'
                className={style.dropdownButton}
                onClick={() => setActivePeriod(_period)}
              >
                {t(_period)}
                {_period === period && <Done />}
              </button>
            );
          })}
        </Dropdown>
      </div>
      <div className={style.graph}>
        <div className={style.triangle} style={{ left: `${pricePercent}%` }} />
      </div>
      <div className={style.price}>
        <TitleNumberFormat title={t('low')} value={activeLow} />
        <TitleNumberFormat title={t('high')} value={activeHigh} />
      </div>
    </div>
  );
};

export default observer(PriceChange);
