import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import style from './Now.module.scss';
import TextInput from '../../../../../ui/TextInput/TextInput';
import NumberInput from '../../../../../ui/NumberInputWithDropdown/NumberInputWithDropdown';
import { useRootModel } from '../../../../../../models/RootStore';
import { ReactComponent as Coin } from '../../../../../../assets/image/common/coin.svg';

/**
 * Represents Now Tab in Modal after click Open Trade Button
 * @returns {JSX.Element}
 */

const Now = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState('');
  const [multiplier, setMultiplier] = useState(1);
  const {
    finance: {
      trade: { setAmount: getAmount, setMultiplier: getMultiplier, multipliedSum, maxMultiplier },
    },
  } = useRootModel();

  const handleChange = (val) => {
    setAmount(val);
  };

  const handleMultiplayerChange = (e) => {
    const { value } = e.target;

    if ((/^[0-9]+$/.test(value) && value <= maxMultiplier) || value === '') {
      setMultiplier(value);
    }
  };

  const handleMultiplayerBlur = () => {
    if (multiplier === '0' || multiplier === '') {
      setMultiplier(1);
    }
  };

  useEffect(() => {
    getAmount(+amount);
    getMultiplier(+multiplier);
  }, [amount, multiplier]);

  return (
    <div className={style.container}>
      <form>
        <NumberInput
          id='nowTradeAmount'
          onChange={handleChange}
          label
          labelTitle={t('trade_amount')}
        />
        <div className={style.wrapper}>
          <div className={style.label}>
            <label htmlFor='multiplayerNow'>{t('multiplier')}</label>
            <p />
          </div>
          <TextInput
            id='multiplayerNow'
            value={multiplier}
            name='multiplayer'
            onChange={handleMultiplayerChange}
            onBlur={handleMultiplayerBlur}
            inputClassName={style.input}
            leftIcon={() => 'x' || null}
            leftIconClassName={style.currencyIcon}
            inputMode='numeric'
            maxLength={3}
          />
        </div>
        <div className={style.wrapper}>
          <div className={style.label}>
            <p>{t('multiplied_sum')}</p>
            <p />
          </div>
          <TextInput
            id='multipliedSummNow'
            inputClassName={style.inputDisabled}
            leftIcon={Coin}
            leftIconClassName={style.currencyIcon}
            inputMode='numeric'
            placeholder={numeral(multipliedSum).format('0,0.00')}
            disabled
          />
        </div>
      </form>
    </div>
  );
};

export default observer(Now);
