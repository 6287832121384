import React, { Suspense } from 'react';
import style from './WithSuspense.module.scss';
import Preloader from '../components/ui/Preloader/Preloader';

/**
 * Represents HOC withSuspense
 * @param {JSX.Element} Component - component for render
 * @returns {JSX.Element}
 */
const withSuspense = (Component) => {
  const Container = (props) => (
    <Suspense fallback={<Preloader className={style.preloader} />}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </Suspense>
  );
  return Container;
};

export default withSuspense;
