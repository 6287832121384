import React from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { PAGES_WITHOUT_SIDEBAR } from '../../../constant/commonConstants';
import useWindowSize from '../../../hooks/useWindowSize';
import { useRootModel } from '../../../models/RootStore';

import Logo from '../../ui/Logo/Logo';

/**
 * Represents HeaderLogo
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderLogo = () => {
  const { pathname } = useLocation();
  const {
    user: { isAuthorized },
  } = useRootModel();
  const { isDesktop } = useWindowSize();
  const isSidebar = isAuthorized && !PAGES_WITHOUT_SIDEBAR.some((page) => pathname.includes(page));
  return <>{!isDesktop ? <Logo /> : !isSidebar && <Logo />}</>;
};

export default observer(HeaderLogo);
