import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './BalanceRightSide.module.scss';

import ButtonWithArrows from '../../ui/ButtonWithArrows/ButtonWithArrows';
import CurrentBalance from '../../CurrentBalance/CurrentBalance';

/**
 * Represents a BalanceRightSide block on Main Page.
 * @returns {JSX.Element}
 */

const BalanceRightSide = () => {
  const { t } = useTranslation();
  const [openedList, setOpenedList] = useState(true);
  const handleToggle = () => {
    setOpenedList(!openedList);
  };

  return (
    <div className={style.balanceBlock}>
      <ButtonWithArrows
        text={t('my_balance')}
        handleToggle={handleToggle}
        openedList={openedList}
      />
      {openedList && <CurrentBalance className={style.balance} />}
    </div>
  );
};

export default observer(BalanceRightSide);
