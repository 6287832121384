import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import style from './PersonalDetails.module.scss';
import countries from '../../../util/countries';
import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import date from '../../../util/date';
import AlertContext from '../../../context/alert/alertContext';
import { useRootModel } from '../../../models/RootStore';
import useWindowSize from '../../../hooks/useWindowSize';
import useFormikApp from '../../../hooks/useFormikApp';
import formikUtil from '../../../util/formikUtil';
import {
  CITY,
  CITY_MAX_LENGTH,
  FIRST_NAME,
  LAST_NAME,
  NAME_MAX_LENGTH,
  PASSPORT,
  PASSPORT_MAX_LENGTH,
} from '../../../util/validationSchema';
import { documentsStatus } from '../../../constant/commonConstants';

import { ReactComponent as ClipIcon } from '../../../assets/image/common/clip.svg';

import Modal from '../../../components/Modal/Modal';
import ModalBody from '../../../components/Modal/ModalBody/ModalBody';
import Button from '../../../components/ui/Button/Button';
import TextInput from '../../../components/ui/TextInput/TextInput';
import CountryAndCode from '../../../components/ui/CountryAndCodeDropdown/CountryAndCodeDropdown';
import AppDatePicker from '../../../components/ui/AppDatePicker/AppDatePicker';
import ButtonWithIcon from '../../../components/ui/ButtonWithIcon/ButtonWithIcon';
import Documents from '../Documents/Documents';

/**
 * Represents a personal details component
 * @returns {JSX.Element}
 */
const PersonalDetails = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const alert = useContext(AlertContext);
  const history = useHistory();
  const {
    user: { userData, changeUserData, isLoading },
  } = useRootModel();

  const { VALID_START_DATE } = date;

  const currDate = new Date(userData?.dateOfBirth || VALID_START_DATE);

  const [countryName, setCountryName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(currDate);
  const [isCountryError, setIsCountryError] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const handleOpenDocumentsModal = () => setShowDocumentsModal((prev) => !prev);
  const handleCloseDocumentsModal = () => setShowDocumentsModal(false);
  const handleToDocumentsPage = () => history.push('/profile/documents');

  const submitHandler = async ({ firstName, lastName, city, passportId }) => {
    const day = dateOfBirth.toLocaleString('en', { day: '2-digit' });
    const month = dateOfBirth.toLocaleString('en', { month: '2-digit' });
    const year = dateOfBirth.getFullYear();

    const country = countries.getCountryByCountryName(countryName);

    try {
      await changeUserData({
        firstName,
        lastName,
      });
      await changeUserData(
        {
          dateOfBirth: `${year}-${month}-${day}`,
          passportId,
          city,
          countryCode: countries.getObjectKey(country),
        },
        true,
      );

      alert.show(t('personal_data_saved'), 'success');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const formik = useFormikApp([FIRST_NAME, LAST_NAME, CITY, PASSPORT], submitHandler);

  const setValueFromStorage = useCallback(
    async (field, extendedAttributes = false) => {
      if (userData.user[field] && !extendedAttributes) {
        await formikUtil.setValue(field, userData.user[field], formik);
        return;
      }
      if (userData[field]) {
        await formikUtil.setValue(field, userData[field], formik);
      }
    },
    [formik],
  );

  useEffect(() => {
    if (userData) {
      setValueFromStorage(FIRST_NAME);
      setValueFromStorage(LAST_NAME);
      setValueFromStorage(CITY, true);
      setValueFromStorage(PASSPORT, true);
      setDateOfBirth(userData.dateOfBirth || VALID_START_DATE, true);
    }
  }, [userData, showDocumentsModal]);

  const replaceApostrophe = (str) => str.replace('"', "'");

  const handleChangeName = async (e) => {
    const { value } = e.target;
    const newValue = replaceApostrophe(value);
    if (newValue.length > NAME_MAX_LENGTH || !/^([\p{L}-]*'*\s*)*$/u.test(newValue)) return;
    await formikUtil.setValue(FIRST_NAME, newValue, formik);
  };

  const handleChangeSurname = async (e) => {
    const { value } = e.target;
    const newValue = replaceApostrophe(value);
    if (newValue.length > NAME_MAX_LENGTH || !/^([\p{L}-]*'*\s*)*$/u.test(newValue)) return;
    await formikUtil.setValue(LAST_NAME, newValue, formik);
  };

  const handleChangeCity = async (e) => {
    const { value } = e.target;
    const newValue = replaceApostrophe(value);
    if (newValue.length > CITY_MAX_LENGTH || !/^([\p{L}-]*'*\s*)*$/u.test(newValue)) return;
    await formikUtil.setValue(CITY, newValue, formik);
  };

  // todo password validation
  // ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)
  // (?=.*[@+*/|'`&#$^?!:.,{}~-])[A-Za-z\d@+*/|'`&#$^?!:.,{}~-]{8,30}$

  const handleChangePassport = (e) => {
    const { value } = e.target;
    if (value.length > PASSPORT_MAX_LENGTH || !/^[\p{L}\d*]*$/u.test(value)) return;
    formik.handleChange(e);
  };

  const isFormValid = formik.isValid && Object.keys(countryName).length;

  return (
    <ErrorBoundary>
      <form onSubmit={formik.handleSubmit} className={style.form}>
        <TextInput
          id={FIRST_NAME}
          label={t('name')}
          placeholder={t('name')}
          formik={formik}
          maxLength={NAME_MAX_LENGTH}
          onChange={handleChangeName}
        />

        <TextInput
          id={LAST_NAME}
          label={t('surname')}
          placeholder={t('surname')}
          formik={formik}
          maxLength={NAME_MAX_LENGTH}
          onChange={handleChangeSurname}
        />

        <AppDatePicker onChange={setDateOfBirth} value={currDate} />
        <div className={style.countryWrapper}>
          <span className={style.labelTitle}>{t('country')}</span>
          <CountryAndCode
            error={isCountryError}
            setIsError={setIsCountryError}
            onChange={setCountryName}
            value={userData?.countryCode}
          />
        </div>

        <TextInput
          id={CITY}
          label={t('city')}
          placeholder={t('city')}
          formik={formik}
          maxLength={CITY_MAX_LENGTH}
          onChange={handleChangeCity}
        />

        <TextInput
          id={PASSPORT}
          label={t('passport_id')}
          onChange={handleChangePassport}
          formik={formik}
          placeholder={t('passport_id')}
          maxLength={PASSPORT_MAX_LENGTH}
        />

        <div className={style.documentsWrapper}>
          <div className={style.documentsTextWrapper}>
            <p className={style.documentsText}>{t('photo_of_passport_or_other_document')}</p>
          </div>
          <div className={style.inputFileButtonWithTextWrapper}>
            <ButtonWithIcon
              // text={!userData?.documents.first.path ? t('documents') : t('edit_files')}
              text={t('documents')}
              wrapperClassName={style.inputFileButtonWrapper}
              icon={ClipIcon}
              onClick={isMobile ? handleToDocumentsPage : handleOpenDocumentsModal}
              disabled={
                userData?.documentsVerificationStatus === 'APPROVED' && userData?.tradingEnabled
              }
            />
            {/*{userData?.documents.first.status === 'APPROVED' && (*/}
            {userData?.documentsVerificationStatus && (
              <p className={cn(style.status, style[userData?.documentsVerificationStatus])}>
                {t('status')}: {documentsStatus[userData?.documentsVerificationStatus]}
              </p>
            )}
            {/*)}*/}
          </div>
        </div>

        <Button
          text={t('save_changes')}
          submit
          disabled={!(isFormValid && !isLoading)}
          size='medium'
          className={style.submitButton}
        />
      </form>
      {showDocumentsModal && (
        <Modal
          header
          handleClose={handleCloseDocumentsModal}
          headerTitle={t('upload_passport')}
          wrapperClassname={style.modal}
        >
          <ModalBody className={style.modalBody}>
            <Documents closeModal={handleCloseDocumentsModal} />
          </ModalBody>
        </Modal>
      )}
    </ErrorBoundary>
  );
};

export default observer(PersonalDetails);
