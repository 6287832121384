import React from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import style from './NavigationItem.module.scss';
import getAnimationClasses from '../../util/getAnimationClasses';
import useWindowSize from '../../hooks/useWindowSize';

import Tooltip from '../ui/Tooltip/Tooltip';

/**
 * Represents item for navigation menu
 * @param {string} text - item title
 * @param {JSX.Element} Icon - item icon
 * @param {string} url - item url
 * @param {boolean} showText - show text
 * @returns {JSX.Element}
 */
const NavigationItem = ({ text = '', icon: Icon, url = '', showText = false }) => {
  const { isDesktop } = useWindowSize();
  const { t } = useTranslation();

  let isActive = null;

  if (url === '/trades/active') isActive = (_, { pathname }) => /^\/trades\/\w*/.test(pathname);
  if (url === '/products/crypto') isActive = (_, { pathname }) => /^\/products\/\w*/.test(pathname);

  if (!isDesktop) {
    return (
      <NavLink
        className={style.item}
        to={url}
        activeClassName={style.active}
        isActive={isActive}
        exact
      >
        <Icon />
        <p className={style.itemText}>{text}</p>
      </NavLink>
    );
  }
  return (
    <li>
      <NavLink
        className={style.item}
        to={url}
        activeClassName={style.active}
        isActive={isActive}
        exact
      >
        {Icon && (
          <div className={style.iconWrapper}>
            {showText ? (
              <Icon />
            ) : (
              <Tooltip
                content={text}
                tooltipClassName={style.tooltip}
                childrenWrapperClassName={style.tooltipChild}
              >
                <Icon />
              </Tooltip>
            )}
          </div>
        )}

        <CSSTransition
          in={showText}
          timeout={700}
          classNames={getAnimationClasses(style)}
          unmountOnExit
        >
          <span className={style.text}>{t(text)}</span>
        </CSSTransition>
      </NavLink>
    </li>
  );
};

export default NavigationItem;
