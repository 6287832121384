import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import style from './NowMobile.module.scss';

import OpenTradeButtons from '../OpenTradeButtons/OpenTradeButtons';
import LimitsMobile from '../LimitsMobile/LimitsMobile';
import Now from '../Now/Now';
import Switch from '../../../../../ui/Switch/Switch';
import { useRootModel } from '../../../../../../models/RootStore';

/**
 * Represents Now Tab in Mobile version after click Open Trade Button
 * @returns {JSX.Element}
 */

const NowMobile = () => {
  const [status, setStatus] = useState(false);
  const {
    finance: {
      trade: { setLoss, setProfit },
    },
  } = useRootModel();

  const switchHandler = () => {
    setStatus(!status);
  };

  useEffect(() => {
    return () => {
      setProfit(null);
      setLoss(null);
    };
  }, []);

  return (
    <>
      <div className={style.container}>
        <div className={style.switchWrapper}>
          <Switch checked={status} onChange={switchHandler} id='123_nowMob' />
          <p className={style.text}>Limit profits and loss</p>
        </div>
        <Now />
        <OpenTradeButtons />
      </div>

      {status && <LimitsMobile setStatus={setStatus} />}
    </>
  );
};

export default observer(NowMobile);
