import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useRootModel } from '../../../../../../models/RootStore';

import style from './CloseTrade.module.scss';
import string from '../../../../../../util/string';
import date from '../../../../../../util/date';
import CoinFormat from '../../../../../CoinFormat/CoinFormat';

/**
 * Represents Close Trade tab in Close Trade content
 * @returns {JSX.Element}
 */
const CloseTrade = () => {
  const { t } = useTranslation();
  const {
    settings: { language },
    finance: {
      activeTrades: { activeTrade },
    },
  } = useRootModel();

  const {
    amount,
    openingPrice,
    operation,
    openedAt,
    multiplier,
    profit,

    profitPercent,
  } = activeTrade;

  return (
    <div className={style.container}>
      <div>
        <div className={style.item}>
          <p className={style.title}>{t('opening_date')}</p>
          <p>{date.toLocaleString(openedAt, language)}</p>
        </div>
        <div className={style.item}>
          <p className={style.title}>{t('opening_price')}</p>
          <p>{string.formatNumber(openingPrice)}</p>
        </div>
        <div className={style.item}>
          <p className={style.title}>{t('operation')}:</p>
          <div className={style.operation}>
            <span className={style.operation__name}>to {operation.toLowerCase()}</span>
            <CoinFormat value={amount} withoutSign={true} />
          </div>
        </div>
        <div className={style.item}>
          <p className={style.title}>{t('multiplier')}</p>
          <p>× {multiplier}</p>
        </div>
      </div>
      <div className={style.item}>
        <p className={style.title}>{t('profit')}</p>
        <div className={style.price}>
          <div className={cn(style.textRed, { [style.textGreen]: profit > 0 })}>
            <CoinFormat value={profit} />
          </div>
          <span className={style.equally}>=</span>
          <span className={cn(style.textRed, { [style.textGreen]: profitPercent > 0 })}>
            {string.formatPercent(profitPercent)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default observer(CloseTrade);
