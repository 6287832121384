import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import style from './Sidebar.module.scss';
import useWindowSize from '../../hooks/useWindowSize';

import { ReactComponent as Main } from '../../assets/image/common/main.svg';
import { ReactComponent as Trades } from '../../assets/image/common/trades.svg';
import { ReactComponent as Markets } from '../../assets/image/common/markets.svg';
import { ReactComponent as Balance } from '../../assets/image/common/balance.svg';
import { ReactComponent as Profile } from '../../assets/image/common/profile.svg';
import { ReactComponent as Support } from '../../assets/image/common/support.svg';
import { ReactComponent as SlideArrowLeft } from '../../assets/image/common/slideArrowLeft.svg';
import { ReactComponent as SlideArrowRight } from '../../assets/image/common/slideArrowRight.svg';
import Logo from '../ui/Logo/Logo';
import NavigationItem from '../NavigationItem/NavigationItem';
import getAnimationClasses from '../../util/getAnimationClasses';

/**
 * @typedef {Object} SidebarItem
 * @property {number} id - item id
 * @property {string} text - text for title and tooltip
 * @property {JSX.Element} icon - item icon
 * @property {string} url - item route
 */

/**
 * Represents a sidebar
 * @returns {JSX.Element}
 */
const Sidebar = () => {
  const { isMobile, isLaptop } = useWindowSize();
  const { t } = useTranslation();

  const elements = [
    { id: 1, text: t('main'), icon: Main, url: '/' },
    { id: 2, text: t('trades'), icon: Trades, url: '/trades/active' },
    { id: 3, text: t('markets'), icon: Markets, url: '/products/crypto' },
    { id: 4, text: t('balance'), icon: Balance, url: '/balance' },
    { id: 5, text: t('profile'), icon: Profile, url: '/profile' },
    { id: 6, text: t('support'), icon: Support, url: '/support' },
  ];

  const wrapperRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleToggleShowSidebar = () => setShowSidebar((prev) => !prev);

  return (
    <aside
      className={cn(style.leftSidebar, { [style.open]: showSidebar && !isMobile && !isLaptop })}
      ref={wrapperRef}
    >
      <CSSTransition in={showSidebar} timeout={700} classNames={getAnimationClasses(style)}>
        <div className={cn(style.logoWrapper)}>
          <Logo />
        </div>
      </CSSTransition>
      <nav className={style.nav}>
        <ul className={style.list}>
          {elements.map(({ id, text, icon, url }) => (
            <NavigationItem
              key={id}
              text={text}
              icon={icon}
              url={url}
              showText={showSidebar && !isMobile && !isLaptop}
            />
          ))}
        </ul>

        <button type='button' className={style.slideArrow} onClick={handleToggleShowSidebar}>
          {showSidebar ? <SlideArrowLeft /> : <SlideArrowRight />}
        </button>
      </nav>
    </aside>
  );
};

export default Sidebar;
