import React, { lazy } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import style from './MainApp.module.scss';

import { PAGES_WITHOUT_SIDEBAR } from '../constant/commonConstants';
import ErrorBoundary from '../components/Error/ErrorBoundary/ErrorBoundary';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import { useRootModel } from '../models/RootStore';
import withSuspense from '../HOC/withSuspense';
import useWindowSize from '../hooks/useWindowSize';

import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import Chat from '../components/Chat/Containers/Chat';
import Main from '../pages/Main/Main';
import Footer from '../components/Footer/Footer';
import MobileApp from '../MobileApp/MobileApp';
import RightSideBlock from '../components/RightSideBlock/RightSideBlock';

const Trades = lazy(() => import('../pages/Trades/Trades'));
const Favorites = lazy(() => import('../pages/Favorites/Favorites'));
const Balance = lazy(() => import('../pages/Balance/Balance'));
const Profile = lazy(() => import('../pages/Profile/Profile'));
const Support = lazy(() => import('../pages/Support/Support'));
const Question = lazy(() => import('../pages/Support/Question/Question'));
const Documents = lazy(() => import('../pages/Profile/Documents/Documents'));
const Products = lazy(() => import('../pages/Products/Products'));
const ExchangeSchedule = lazy(() => import('../pages/ExchangeSchedule/ExchangeSchedule'));
const FooterInfo = lazy(() => import('../pages/FooterInfo/FooterInfo'));
const StatusInfo = lazy(() => import('../pages/StatusInfo/StatusInfo'));
const NotFound = lazy(() => import('../pages/NotFound/NotFound'));

/**
 * Main App component
 * @returns {JSX.Element}
 */

const MainApp = () => {
  const { isMobile, isDesktopBigScreen } = useWindowSize();
  const { pathname } = useLocation();
  const {
    user: { isAuthorized },
  } = useRootModel();

  const isHeader = !PAGES_WITHOUT_SIDEBAR.some((page) => pathname.includes(page));
  const isSidebar = isAuthorized && !PAGES_WITHOUT_SIDEBAR.some((page) => pathname.includes(page));
  const isPathname =
    pathname.includes('/trades') ||
    pathname.includes('/products') ||
    pathname === '/' ||
    pathname === '/favorites' ||
    pathname === '/profile/status' ||
    pathname.includes('/info');

  return isMobile ? (
    <MobileApp />
  ) : (
    <div className={style.app}>
      {isSidebar && <Sidebar />}
      <div className={style.content}>
        <Header />
        <div className={style.bodyWrapper}>
          <div className={style.wrapper}>
            <ErrorBoundary>
              {isAuthorized && <Chat />}
              <div className={style.main}>
                <Switch>
                  <Route exact path='/' component={Main} />
                  <PrivateRoute exact path='/trades/:type' component={withSuspense(Trades)} />
                  <PrivateRoute exact path='/favorites' component={withSuspense(Favorites)} />
                  <PrivateRoute
                    exact
                    path='/products/:category/:s?'
                    component={withSuspense(Products)}
                  />
                  <Route exact path='/info/:type' component={withSuspense(FooterInfo)} />
                  <PrivateRoute exact path='/profile/status' component={withSuspense(StatusInfo)} />
                  <PrivateRoute exact path='/balance' component={withSuspense(Balance)} />
                  <PrivateRoute exact path='/profile' component={withSuspense(Profile)} />
                  <PrivateRoute
                    exact
                    path='/profile/documents'
                    component={withSuspense(Documents)}
                  />
                  <Route exact path='/support' component={withSuspense(Support)} />
                  <Route exact path='/support/question' component={withSuspense(Question)} />
                  <Route exact path='/schedule' component={withSuspense(ExchangeSchedule)} />
                  <Redirect to='/404' />
                </Switch>
                {isDesktopBigScreen && isAuthorized && isPathname && <RightSideBlock />}
              </div>
            </ErrorBoundary>
            <Switch>
              <Route exact path='/404' component={withSuspense(NotFound)} />
            </Switch>
          </div>
          {isHeader && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default observer(MainApp);
