const download = (uri, name, setLoadPending) => {
  if (typeof setLoadPending === 'function') setLoadPending(true);
  fetch(uri, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function load(buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        if (typeof setLoadPending === 'function') setLoadPending(false);
        link.click();
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      if (typeof setLoadPending === 'function') setLoadPending(false);
    });
};

export default {
  download,
};
