import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './NotificationItem.module.scss';
import { useRootModel } from '../../../models/RootStore';

import { ReactComponent as ArrowDoubleIcon } from '../../../assets/image/common/arrowDouble.svg';
import Checkbox from '../../ui/Checkbox/Checkbox';

/**
 * Represents NotificationItem component
 * @param {number} id - notification id
 * @param {string} text - notification text
 * @param {string} title - notification title
 * @param {boolean} checked - is notification checked
 * @param {boolean} isRead - flag isRead
 * @returns {JSX.Element}
 */
const NotificationItem = ({ id, text, title, checked, isRead }) => {
  const {
    user: {
      notifications: { toggleChecked, setIsRead, setActiveNotificationId },
    },
    modal: {
      notifications: { open },
    },
  } = useRootModel();
  const handleChangeCheckbox = () => toggleChecked(id);
  const handleOpenModal = () => {
    open();
    setActiveNotificationId(id);
    setIsRead(id);
  };

  return (
    <div className={style.notificationItem}>
      <Checkbox
        id={id}
        checked={checked}
        handleChange={handleChangeCheckbox}
        wrapperClassName={style.checkbox}
      />
      <button type='button' className={style.title} onClick={handleOpenModal}>
        {title}
      </button>
      <p className={style.text}>{text}</p>
      <i className={cn(style.icon, { [style.isRead]: isRead })}>
        <ArrowDoubleIcon />
      </i>
    </div>
  );
};

export default observer(NotificationItem);
