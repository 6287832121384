import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './PersonalDetailsForm.module.scss';
import {
  CITY,
  CITY_MODAL,
  CITY_MAX_LENGTH,
  FIRST_NAME,
  FIRST_NAME_MODAL,
  LAST_NAME,
  LAST_NAME_MODAL,
  NAME_MAX_LENGTH,
  PASSPORT,
  PASSPORT_MODAL,
  PASSPORT_MAX_LENGTH,
} from '../../../util/validationSchema';
import useFormikApp from '../../../hooks/useFormikApp';
import formikUtil from '../../../util/formikUtil';
import { useRootModel } from '../../../models/RootStore';
import TextInput from '../../../components/ui/TextInput/TextInput';
// eslint-disable-next-line max-len
import CountryAndCode from '../../../components/ui/CountryAndCodeDropdown/CountryAndCodeDropdown';
import countries from '../../../util/countries';
import date from '../../../util/date';
import AppDatePicker from '../../../components/ui/AppDatePicker/AppDatePicker';

const PersonalDetailsForm = ({ setFormik }) => {
  const { t } = useTranslation();
  const {
    user: { userData, changeUserData },
  } = useRootModel();

  const { VALID_START_DATE } = date;

  const currDate = new Date(userData?.dateOfBirth || VALID_START_DATE);

  const [isCountryError, setIsCountryError] = useState(false);
  const [countryName, setCountryName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(currDate);

  const replaceApostrophe = (str) => str.replace('"', "'");

  const handleChangeName = async (e) => {
    const { value } = e.target;
    const newValue = replaceApostrophe(value);
    if (newValue.length > NAME_MAX_LENGTH || !/^([\p{L}-]*'*\s*)*$/u.test(newValue)) return;
    await formikUtil.setValue(FIRST_NAME_MODAL, newValue, formik);
  };

  const handleChangeSurname = async (e) => {
    const { value } = e.target;
    const newValue = replaceApostrophe(value);
    if (newValue.length > NAME_MAX_LENGTH || !/^([\p{L}-]*'*\s*)*$/u.test(newValue)) return;
    await formikUtil.setValue(LAST_NAME_MODAL, newValue, formik);
  };

  const handleChangeCity = async (e) => {
    const { value } = e.target;
    const newValue = replaceApostrophe(value);
    if (newValue.length > CITY_MAX_LENGTH || !/^([\p{L}-]*'*\s*)*$/u.test(newValue)) return;
    await formikUtil.setValue(CITY_MODAL, newValue, formik);
  };

  const handleChangePassport = (e) => {
    const { value } = e.target;
    if (value.length > PASSPORT_MAX_LENGTH || !/^[\p{L}\d*]*$/u.test(value)) return;
    formik.handleChange(e);
  };

  const submitHandlerModal = async ({
    firstNameModal,
    lastNameModal,
    cityModal,
    passportIdModal,
  }) => {
    const day = dateOfBirth.toLocaleString('en', { day: '2-digit' });
    const month = dateOfBirth.toLocaleString('en', { month: '2-digit' });
    const year = dateOfBirth.getFullYear();

    const country = countries.getCountryByCountryName(countryName);

    try {
      await changeUserData({
        firstName: firstNameModal,
        lastName: lastNameModal,
      });
      await changeUserData(
        {
          dateOfBirth: `${year}-${month}-${day}`,
          passportId: passportIdModal,
          city: cityModal,
          countryCode: countries.getObjectKey(country),
        },
        true,
      );

      alert.show(t('personal_data_saved'), 'success');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const formik = useFormikApp(
    [FIRST_NAME_MODAL, LAST_NAME_MODAL, CITY_MODAL, PASSPORT_MODAL],
    submitHandlerModal,
  );

  const setValueFromStorage = useCallback(
    async (field, extendedAttributes = false) => {
      if (userData.user[field] && !extendedAttributes) {
        await formikUtil.setValue(`${field}Modal`, userData.user[field], formik);
        return;
      }
      if (userData[field]) {
        await formikUtil.setValue(`${field}Modal`, userData[field], formik);
      }
    },
    [formik],
  );

  useEffect(() => {
    if (userData) {
      setValueFromStorage(FIRST_NAME);
      setValueFromStorage(LAST_NAME);
      setValueFromStorage(CITY, true);
      setValueFromStorage(PASSPORT, true);
      setDateOfBirth(userData.dateOfBirth || VALID_START_DATE, true);
    }
  }, [userData]);

  useEffect(() => {
    setFormik(formik);
  }, []);

  return (
    <div className={style.personalDetailsForm}>
      <div className={style.inputWrapper}>
        <TextInput
          id={FIRST_NAME_MODAL}
          label={t('name')}
          placeholder={t('name')}
          formik={formik}
          maxLength={NAME_MAX_LENGTH}
          onChange={handleChangeName}
        />

        <TextInput
          id={LAST_NAME_MODAL}
          label={t('surname')}
          placeholder={t('surname')}
          formik={formik}
          maxLength={NAME_MAX_LENGTH}
          onChange={handleChangeSurname}
        />
      </div>

      <AppDatePicker onChange={setDateOfBirth} value={currDate} className={style.dateWrapper} />

      <div className={style.inputWrapper}>
        <div className={style.countryWrapper}>
          <span className={style.labelTitle}>{t('country')}</span>
          <CountryAndCode
            error={isCountryError}
            setIsError={setIsCountryError}
            onChange={setCountryName}
            value={userData?.countryCode}
          />
        </div>

        <TextInput
          id={CITY_MODAL}
          label={t('city')}
          placeholder={t('city')}
          formik={formik}
          maxLength={CITY_MAX_LENGTH}
          onChange={handleChangeCity}
        />
      </div>
      <TextInput
        id={PASSPORT_MODAL}
        label={t('passport_id')}
        onChange={handleChangePassport}
        formik={formik}
        placeholder={t('passport_id')}
        maxLength={PASSPORT_MAX_LENGTH}
      />
    </div>
  );
};

export default observer(PersonalDetailsForm);
