import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from './GeneralPhone.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import AlertContext from '../../../../context/alert/alertContext';
import useFormikApp from '../../../../hooks/useFormikApp';
import formikUtil from '../../../../util/formikUtil';
import { PHONE } from '../../../../util/validationSchema';
import useSendCodeState from '../../../../hooks/useSendCodeState';
import { GENERAL_SEND_CODE_RESTORE_SECONDS } from '../../../../constant/commonConstants';

import Button from '../../../../components/ui/Button/Button';
import PhoneCodeWithInput from '../../../../components/ui/PhoneCodeWithInput/PhoneCodeWithInput';
import emptyFunction from '../../../../util/emptyFunction';
import GeneralPhoneCodes from './GeneralPhoneCodes/GeneralPhoneCodes';

/**
 * Represents phone component for GeneralInformation
 * @returns {JSX.Element}
 */
const GeneralPhone = () => {
  const alert = useContext(AlertContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const {
    user: { userData, changePhone, verifyPhone },
  } = useRootModel();
  const { setSecondsRestoreSendCodeGeneral } = useSendCodeState();

  const [phoneCode, setPhoneCode] = useState('');
  const [phone, setPhone] = useState('');
  const [isVerifyCodeSent, setIsVerifyCodeSent] = useState(false);
  const [isPhoneChanged, setIsPhoneChanged] = useState(false);
  const [isPhoneWasConfirmed, setIsPhoneWasConfirmed] = useState(false);
  const [isMatchWithStore, setIsMatchWithStore] = useState(true);
  const [isPhoneError, setIsPhoneError] = useState(false);

  useEffect(() => {
    if (userData) setIsPhoneWasConfirmed(userData.phoneConfirmed);
  }, [userData]);

  const formik = useFormikApp([PHONE], emptyFunction);

  const handleChangePhone = async () => {
    try {
      setIsPhoneWasConfirmed(userData.phoneConfirmed);
      const { phoneConfirmed } = userData;
      await changePhone({ phoneCode, phone });

      setSecondsRestoreSendCodeGeneral(GENERAL_SEND_CODE_RESTORE_SECONDS);
      setIsPhoneChanged(false);
      if (phoneConfirmed) setIsVerifyCodeSent(true);
      alert.show(t('phone_changed'), 'info');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const handleVerifyPhone = async () => {
    try {
      await verifyPhone({ phone, phoneCode });
      setIsVerifyCodeSent(true);
      setIsPhoneChanged(false);
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  useEffect(() => {
    setIsMatchWithStore(
      userData && `${userData.phoneCode}${userData[PHONE]}` === `${phoneCode}${phone}`,
    );
  }, [userData && userData[PHONE], phone, phoneCode]);

  useEffect(() => {
    if (userData && userData.phoneCode && userData[PHONE]) {
      formikUtil.setValue(PHONE, userData[PHONE], formik);
      setPhone(userData[PHONE]);
      setPhoneCode(userData.phoneCode);
      setIsMatchWithStore(true);
      setIsPhoneChanged(false);
    }
  }, [userData && userData[PHONE]]);

  const emailConfirmed = userData?.emailConfirmed;
  const phoneConfirmed = userData?.phoneConfirmed;

  const handleInputKeyDown = async (event) => {
    if (isPhoneError || event.key !== 'Enter') return;
    try {
      if (isPhoneChanged) {
        await handleChangePhone();
      } else {
        await handleVerifyPhone();
      }
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  useEffect(() => {
    if (pathname === '/profile/change-phone') {
      setIsVerifyCodeSent(true);
    }
  }, []);

  useEffect(() => {
    if (formik.values[PHONE]) setIsPhoneChanged(!isMatchWithStore);
  }, [formik.values[PHONE], isMatchWithStore]);

  const handleSetPhone = (value) => {
    setPhoneCode(value.phoneCode);
    setPhone(value.phone);
    if (!isMatchWithStore) setIsPhoneChanged(true);
  };

  const isEmailConfirmedCodeDidntSend = emailConfirmed && !isVerifyCodeSent;
  const isValid = formik.isValid && !isPhoneError;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={cn(style.generalPhone, {
        [style.withMarginBottom]: emailConfirmed,
      })}
      autoComplete='nope'
    >
      <PhoneCodeWithInput
        handleChange={handleSetPhone}
        phoneConfirmed={phoneConfirmed}
        showVerifiedLabel
        handleInputKeyDown={handleInputKeyDown}
        setIsError={setIsPhoneError}
        formik={formik}
      />

      {!isVerifyCodeSent && !isMatchWithStore && isPhoneChanged && (
        <Button
          text={t('save_phone')}
          size='small'
          color='primary'
          onClick={handleChangePhone}
          width='full'
          disabled={!isValid}
          marginBottom
        />
      )}

      {isVerifyCodeSent && (
        <GeneralPhoneCodes
          setIsPhoneChanged={setIsPhoneChanged}
          setIsVerifyCodeSent={setIsVerifyCodeSent}
          isPhoneChanged={isPhoneChanged}
          changePhoneNumber={handleChangePhone}
          verifyPhoneNumber={handleVerifyPhone}
          isPhoneWasConfirmed={isPhoneWasConfirmed}
        />
      )}

      {phoneCode && phone && !isPhoneChanged && !phoneConfirmed && emailConfirmed && (
        <p className={style.text}>{t('continuing_verification_number')}</p>
      )}

      {isEmailConfirmedCodeDidntSend && !isPhoneChanged && !phoneConfirmed && (
        <Button
          size='small'
          text={t('verify_phone')}
          onClick={handleVerifyPhone}
          disabled={!formik.isValid}
          width='full'
          marginBottom
        />
      )}
    </form>
  );
};

export default observer(GeneralPhone);
