import React from 'react';
import { observer } from 'mobx-react';

import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import HistoryContent from './HistoryContent/HistoryContent';

/**
 * Represents HistoryModal component
 * @returns {JSX.Element}
 */
const HistoryModal = () => {
  const {
    modal: {
      historyTrade: { isOpen, close },
    },
  } = useRootModel();

  return (
    <>
      {isOpen && (
        <Modal wrapperClassname={style.modalWrapper} handleClose={close}>
          <ModalBody className={style.modal}>
            <HistoryContent handleClose={close} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(HistoryModal);
