import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, Redirect, useParams } from 'react-router-dom';
import style from './TradesPage.module.scss';
import SearchTradesMobile from '../../components/SearchTradesMobile/SearchTradesMobile';

import ActiveTradesMobile from './ActiveTradesMobile/ActiveTradesMobile';
import PendingTradesMobile from './PendingTradesMobile/PendingTradesMobile';
import ClosedTradesMobile from './ClosedTradesMobile/ClosedTradesMobile';
import FlowOfFundsMobile from './FlowOfFundsMobile/FlowOfFundsMobile';
import PeriodFilterTrades from '../../components/PeriodFilterTrades/PeriodFilterTrades';

const trades = {
  active: <ActiveTradesMobile />,
  pending: <PendingTradesMobile />,
  closed: <ClosedTradesMobile />,
  'flow-funds': <FlowOfFundsMobile />,
};

/**
 * Represents Trades page in Mobile version
 * @returns {JSX.Element}
 */

const TradesPage = () => {
  const { t } = useTranslation();
  const { type } = useParams();

  if (!Object.keys(trades).includes(type)) return <Redirect to='/404' />;

  return (
    <>
      {type !== 'flow-funds' && (
        <div className={style.searchBlock}>
          <SearchTradesMobile />
          <Link className={style.openTradeButton} to='/'>
            {t('open_new')}
          </Link>
        </div>
      )}
      <section className={style.contentSection}>
        <nav className={style.navigation}>
          <NavLink to='/trades/active' className={style.link} activeClassName={style.active}>
            {t('active')}
          </NavLink>
          <NavLink to='/trades/pending' className={style.link} activeClassName={style.active}>
            {t('pending')}
          </NavLink>
          <NavLink to='/trades/closed' className={style.link} activeClassName={style.active}>
            {t('closed')}
          </NavLink>
          <NavLink to='/trades/flow-funds' className={style.link} activeClassName={style.active}>
            {t('flow')}
          </NavLink>
        </nav>
        {trades[type]}
      </section>
      <PeriodFilterTrades />
    </>
  );
};

export default TradesPage;
