import { useState, useEffect } from 'react';

/**
 * Hook window size
 * @property {number} width - window width
 * @property {boolean} isLandscape - return true if orientation is landscape
 * @property {boolean} isMobile - return true if it is mobile view (width < 768px)
 * @property {boolean} isLaptop - return true if it is laptop view (768px < width <1200px)
 * @property {boolean} isDesktop - return true if it is desktop view (width > 1200px)
 * @property {boolean} isDesktopPlus - return true if it is desktop view (width > 1300px)
 * @property {boolean} isDesktopBigScreen - return true if it is desktop view (width > 1800px)
 */

const useWindowSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width < 768;
  const isDesktop = width >= 1300;
  const isDesktopPlus = width >= 1400;
  const isDesktopBigScreen = width >= 1800;
  const isLaptop = !(isMobile || isDesktop);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { isMobile, isLaptop, isDesktop, isDesktopPlus, isDesktopBigScreen };
};

export default useWindowSize;
