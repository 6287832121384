import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './InstrumentsLeftSideBlockTablet.module.scss';
import { useRootModel } from '../../../models/RootStore';
import instrumentIcon from '../../../assets/instrumentIcon';
import emptyFunction from '../../../util/emptyFunction';
import string from '../../../util/string';

/**
 * Represents Instruments in Left Side Block on Main Page component for tablet
 * @returns {JSX.Element}
 */
const InstrumentsLeftSideBlockTablet = () => {
  const { t } = useTranslation();
  const {
    finance: { items, activeSymbol, setActiveSymbol },
  } = useRootModel();

  const handleOnClick = async (s) => {
    await setActiveSymbol(s);
  };

  return (
    <>
      <p className={style.title}>{t('instruments')}</p>
      <div className={style.instruments}>
        {items?.map(({ alias, s, lp, cpd, type, oldPrice }) => {
          const instType = instrumentIcon[type];
          const Icon = instType ? instrumentIcon[type][s] : null;
          const [firstPair, secondPair] = alias.split('/');
          return (
            <div
              role='button'
              tabIndex={-1}
              className={cn(style.item, { [style.active]: activeSymbol === s })}
              key={alias}
              onClick={() => handleOnClick(s)}
              onKeyDown={emptyFunction}
            >
              <div className={style.instrumentBlock}>
                <i className={style.instrumentIcon}>{Icon && <Icon />}</i>
                <div>
                  <p className={style.alias}>
                    <span>{firstPair}</span>
                    {secondPair && <span className={style.secondPair}>/{secondPair}</span>}
                  </p>
                  <p className={style.secondaryText}>{s}</p>
                </div>
              </div>
              <div>
                <p
                  className={cn(style.price, {
                    [style.backRed]: lp < oldPrice,
                    [style.backGreen]: lp > oldPrice,
                  })}
                >
                  {string.formatNumber(lp)}
                </p>
                <p className={style.secondaryText}>{t('price')}</p>
              </div>
              <div>
                <p className={cn({ [style.red]: cpd < 0, [style.green]: cpd > 0 })}>
                  {string.formatPercent(cpd)}
                </p>
                <p className={style.secondaryText}>{t('change')}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default observer(InstrumentsLeftSideBlockTablet);
