import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './ActiveTradesMobileItem.module.scss';
import string from '../../../../../util/string';

import { ReactComponent as Down } from '../../../../../assets/image/common/downArr.svg';
import { ReactComponent as Up } from '../../../../../assets/image/common/upArr.svg';
import Button from '../../../../../components/ui/Button/Button';
import { OPERATION } from '../../../../../constant/commonConstants';
import { useRootModel } from '../../../../../models/RootStore';
import CoinFormat from '../../../../../components/CoinFormat/CoinFormat';

/**
 * Represents Instrument item in Active trades on Mobile version
 * @param {number} id - instrument id
 * @param {string} alias - instrument title
 * @param {string} code - instrument code
 * @param {number} price - instrument price
 * @param {number} funds - used funds
 * @param {number} multiplier - multiplier
 * @param {number} profit - trade profit in $
 * @param {number} profitPercent - trade profit in %
 * @param {'BUY' | 'SELL'} operation - trade operation
 * @param {JSX.Element} icon - instrument icon
 * @returns {JSX.Element}
 */

const ActiveTradesMobileItem = ({
  id,
  alias,
  code,
  price,
  funds,
  multiplier,
  profit,
  profitPercent,
  operation,
  icon: Icon,
}) => {
  const { t } = useTranslation();
  const {
    modal: { increaseTradeMobile, closeTradeMobile },
    finance: {
      activeTrades: { setActiveTradeId },
    },
  } = useRootModel();

  const handleClickIncrease = async (e) => {
    e.stopPropagation();
    await setActiveTradeId(id);
    increaseTradeMobile.open();
  };

  const handleClickClose = async (e) => {
    e.stopPropagation();
    await setActiveTradeId(id);
    closeTradeMobile.open();
  };

  return (
    <div className={style.instrumentMobileItem}>
      <div className={style.iconAndPriceWrapper}>
        <div className={style.titleWrapper}>
          <i className={style.instrumentIcon}>
            <Icon />
          </i>
          <div>
            <div className={style.container}>
              <span className={style.title}>{alias}</span>
              <span>{operation === OPERATION.buy ? <Up /> : <Down />}</span>
            </div>
            <p className={style.number}>{code}</p>
          </div>
        </div>
        <div className={style.priceContainer}>
          <div>
            <p className={style.price}>{string.formatNumber(price)}</p>
            <div className={style.number}>
              <CoinFormat value={funds} withoutSign={true} />x {multiplier}
            </div>
          </div>
          <div className={style.increase}>
            <div className={cn(style.price, style.red, { [style.green]: profit > 0 })}>
              <CoinFormat value={profit} />
            </div>
            <p
              className={cn(style.number, style.positionEnd, style.red, {
                [style.green]: profit > 0,
              })}
            >
              ({string.formatPercent(profitPercent, { withSign: true })})
            </p>
          </div>
        </div>
      </div>
      <div className={style.options}>
        <Button
          text={t('increase')}
          color='secondary'
          size='small'
          className={style.button}
          onClick={handleClickIncrease}
        />
        <Button
          text={t('close_out')}
          color='primary'
          size='small'
          className={style.button}
          onClick={handleClickClose}
        />
      </div>
    </div>
  );
};

export default observer(ActiveTradesMobileItem);
