import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './InstrumentsLeftSideBlock.module.scss';
import { useRootModel } from '../../../models/RootStore';

import InstrumentsContent from './InstrumentsContent/InstrumentsContent';

/**
 * Represents Instruments in Left Side Block on Main Page component
 * @returns {JSX.Element}
 */
const InstrumentsLeftSideBlock = () => {
  const { t } = useTranslation();
  const {
    finance: { setActiveSymbol, isLoading },
  } = useRootModel();

  const handleOnClick = async (s) => {
    await setActiveSymbol(s);
  };

  return (
    <div className={style.instruments}>
      <div className={style.container}>
        <div className={style.header}>
          <p>{t('name')}</p>
          <p className={style.priceTitle}>{t('price')}</p>
          <p className={style.percentTitle}>{t('change')}</p>
        </div>
        <InstrumentsContent handleOnClick={handleOnClick} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default observer(InstrumentsLeftSideBlock);
