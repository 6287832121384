import { types } from 'mobx-state-tree';
import { flow } from 'mobx';
import axios from 'axios';
import error from '../../../util/error';
import sort from '../../../util/sort';
import { sortByQuantity } from '../../../constant/commonConstants';
import InstrumentItemModel from '../InstrumentItemModel';
import eventSourceUtil from '../../../eventSource/eventSource';

const FavoriteTradesModel = types
  .model('FavoriteTradesModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    items: types.array(InstrumentItemModel),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'asc'),
    pageSize: types.optional(types.number, sortByQuantity[0]),
    page: types.optional(types.number, 1),
  })
  .actions((favorites) => ({
    setIsLoading(load) {
      favorites.isLoading = load;
    },
    setError(err) {
      favorites.error = err;
    },
    clearError() {
      favorites.error = '';
    },
    setItems(items) {
      favorites.items = items;
    },
    closeDataStreamFavorites() {
      eventSourceUtil.closeStreamFavorites();
    },
    updateFavoritesState(data) {
      const { items } = favorites;
      items.forEach((item) => {
        let newData = data.find((i) => i.s === item.s);
        if (newData) item.update({ ...newData, oldPrice: item.lp });
      });
    },
    updateFavorites() {
      const { updateFavoritesState } = favorites;
      const eventSource = eventSourceUtil.createEventSourceFavorites();
      eventSource.onmessage = (e) => {
        updateFavoritesState(JSON.parse(e.data));
      };
    },
    getFavorites: flow(function* getFavorites() {
      const { setIsLoading, setError, clearError, setItems, updateFavorites } = favorites;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`/services/trading/api/symbols/favorites`);
        setItems(data);
        updateFavorites();
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    setSortBy(key) {
      favorites.sortBy = key;
    },
    setSortDirection(direction) {
      favorites.sortDirection = direction;
    },
    switchSortDirection() {
      favorites.sortDirection = favorites.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    setPage(page) {
      favorites.page = page;
    },
    setPageSize(size) {
      favorites.pageSize = size;
    },
  }))
  .views((favorites) => ({
    get sortedItems() {
      const { items, sortDirection, sortBy, page, pageSize } = favorites;
      const itemsAmount = (page - 1) * pageSize;
      if (!sortBy) return items;
      return sort
        .sortArrayBy(items, sortDirection, sortBy)
        .slice(itemsAmount, itemsAmount + pageSize);
    },
  }));

export default FavoriteTradesModel;
