import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './TraderSentiment.module.scss';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents Trader sentiment component
 * @param {CSSStyleRule} className - wrapper className
 * @param {CSSStyleRule} classNameGraph - graph className
 * @param {boolean} isTitle - is title
 * @returns {JSX.Element}
 */
const TraderSentiment = ({ className, isTitle = true, classNameGraph }) => {
  const { t } = useTranslation();
  const {
    finance: { traderSentiment, activeSymbol },
  } = useRootModel();

  useEffect(() => {
    //  getTraderSentiment();
  }, [activeSymbol]);

  return (
    <div className={cn(style.container, className)}>
      {isTitle && <p className={style.title}>{t('sentiment')}</p>}
      <div className={cn(style.graph, classNameGraph)}>
        <div style={{ width: `${traderSentiment}%` }} className={style.percent} />
      </div>
      <div className={style.price}>
        <p className={style.text}>
          <span className={style.secondaryColor}>{t('down')}: </span>
          {traderSentiment}%
        </p>
        <p className={style.text}>
          <span className={style.secondaryColor}>{t('up')}: </span>
          {100 - traderSentiment}%
        </p>
      </div>
    </div>
  );
};

export default observer(TraderSentiment);
