import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './HeaderNotifications.module.scss';
import useOnClick from '../../../hooks/useOnClick';
import emptyFunction from '../../../util/emptyFunction';
import { useRootModel } from '../../../models/RootStore';
import useWindowSize from '../../../hooks/useWindowSize';

// eslint-disable-next-line max-len
import { ReactComponent as NotificationsIcon } from '../../../assets/image/common/notifications.svg';
import Notifications from '../../Notifications/Notifications';
import Tooltip from '../../ui/Tooltip/Tooltip';

/**
 * Represents HeaderNotifications
 * @returns {JSX.Element}
 */
const HeaderNotifications = () => {
  const { isMobile } = useWindowSize();

  const { t } = useTranslation();
  const {
    settings: { isLightMode, userData },
    user: {
      notifications: { getNotifications, getNotificationsUpdates, amountUnread },
    },
    modal: {
      notifications: { isOpen },
    },
  } = useRootModel();

  useEffect(() => {
    getNotifications();
    getNotificationsUpdates();
  }, [userData?.user]);

  const [showNotifications, setShowNotifications] = useState(false);
  const toggleShowNotifications = () => setShowNotifications((prev) => !prev);
  const closeSnowNotifications = () => setShowNotifications(false);

  const notificationsRef = useRef();
  useOnClick(notificationsRef, isOpen ? emptyFunction : closeSnowNotifications);

  const showNotificationsButton = (
    <button
      type='button'
      className={cn(style.optionsItem, {
        [style.lightThemeItem]: isLightMode,
        [style.activeButton]: showNotifications && !isMobile,
        [style.withModal]: showNotifications,
      })}
      onClick={toggleShowNotifications}
    >
      <i className={style.notificationsIconWrapper}>
        <NotificationsIcon />
        {amountUnread > 0 && <span className={style.badge} />}
      </i>
      {!isMobile && amountUnread > 0 && <span>{amountUnread > 99 ? '99+' : amountUnread}</span>}
    </button>
  );

  return (
    <div className={style.notifications}>
      {isMobile ? (
        showNotificationsButton
      ) : (
        <Tooltip content={t('notifications')} position='bottom'>
          {showNotificationsButton}
        </Tooltip>
      )}
      {showNotifications && <Notifications wrapperRef={notificationsRef} />}
    </div>
  );
};

export default observer(HeaderNotifications);
