import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './CommissionsAndReports.module.scss';
import { useRootModel } from '../../../../../../models/RootStore';
import CommissionsAndReportsTop from './CommissionsAndReportsTop/CommissionsAndReportsTop';

/**
 * Represents Commissions and Reports in Active Trades content
 * @returns {JSX.Element}
 */

const CommissionsAndReports = () => {
  const { t } = useTranslation();
  const {
    finance: {
      activeTrades: { activeTradeId },
    },
    modal: { activeTrade, historyTrade },
  } = useRootModel();

  const handleChange = () => {
    activeTrade.close();
    historyTrade.open();
  };

  return (
    <>
      <div>
        <CommissionsAndReportsTop />
        <div className={style.bottomBlock}>
          <p className={style.number}>
            {t('trade_number')} {activeTradeId}
          </p>
          <button type='button' className={style.button} onClick={handleChange}>
            {t('trade_history')}
          </button>
        </div>
      </div>
    </>
  );
};

export default observer(CommissionsAndReports);
