import React from 'react';
import { observer } from 'mobx-react';
import style from './InstrumentDetails.module.scss';
import InstrumentInfo from '../InstrumentInfo/InstrumentInfo';
import OpenTradeButton from '../../../components/ui/OpenTradeButton/OpenTradeButton';
import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import TVChart from '../../../components/TVChart/TVChart';
import PriceChange from '../../../components/PriceChange/PriceChange';
import TraderSentiment from '../../../components/TraderSentiment/TraderSentiment';
import News from '../../../components/News/News';
import { useRootModel } from '../../../models/RootStore';

const InstrumentDetails = () => {
  const {
    settings: { theme, language },
    finance: { activeInterval, activeSymbol },
    user: { userData },
  } = useRootModel();

  return (
    <div className={style.about}>
      <div className={style.instrumentInfoTop}>
        <InstrumentInfo />
        <OpenTradeButton />
      </div>

      <div className={style.tvChart}>
        <ErrorBoundary>
          <TVChart
            theme={theme}
            symbol={activeSymbol}
            interval={activeInterval}
            userId={userData?.id || null}
            language={language}
            wrapperClassName={style.tvChartWrapper}
          />
        </ErrorBoundary>
      </div>

      <div className={style.instrumentInfoBottom}>
        <PriceChange className={style.item} />
        <TraderSentiment className={style.item} />
      </div>
      <News />
    </div>
  );
};

export default observer(InstrumentDetails);
