import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from '../../HeaderUserInfo.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import AlertContext from '../../../../../context/alert/alertContext';

import { ReactComponent as Account } from '../../../../../assets/image/common/real-account.svg';

/**
 * Represents ButtonSwitchReal component
 * @returns {JSX.Element}
 */
const ButtonSwitchReal = () => {
  const alert = useContext(AlertContext);
  const { t } = useTranslation();
  const {
    user: { switchAccount, userData },
  } = useRootModel();
  const handleSwitchAccount = async () => {
    try {
      await switchAccount();
    } catch (err) {
      alert.show(err.message);
    }
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Account />
      </i>
      <button type='button' className={style.button} onClick={handleSwitchAccount}>
        {userData.demoVersion ? t('switch_real') : t('switch_demo')}
      </button>
    </div>
  );
};

export default observer(ButtonSwitchReal);
