import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import styles from './Favorites.module.scss';
import { useRootModel } from '../../../models/RootStore';

import FavoritesInstruments from './FavoritesInstruments/FavoritesInstruments';
import ButtonWithArrows from '../../ui/ButtonWithArrows/ButtonWithArrows';
import eventSourceUtil from '../../../eventSource/eventSource';

/**
 * Represents a Favorites block on Main Page.
 * @returns {JSX.Element}
 */
const Favorites = () => {
  const { t } = useTranslation();
  const {
    finance: {
      favoriteTrades: { items, getFavorites, closeDataStreamFavorites },
    },
  } = useRootModel();

  useEffect(() => {
    getFavorites();
    eventSourceUtil.changeRightBarFavoritesState(true);

    return () => {
      eventSourceUtil.changeRightBarFavoritesState(false);
      closeDataStreamFavorites();
    };
  }, []);

  const [openedList, setOpenedList] = useState(true);
  const handleToggle = () => {
    setOpenedList(!openedList);
  };

  return (
    <div className={styles.favorites}>
      <ButtonWithArrows text={t('favorites')} handleToggle={handleToggle} openedList={openedList} />
      {openedList && items.length > 0 && <FavoritesInstruments />}
    </div>
  );
};

export default observer(Favorites);
