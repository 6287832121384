import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './DepositMoneyMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import DepositMoney from '../../../../components/DepositMoney/DepositMoney';

/**
 * Represents Deposit Money component in Mobile version
 * @returns {JSX.Element}
 */

const DepositMoneyMobile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/balance' />
        <p className={style.title}>{t('deposit_money')}</p>
      </div>
      <p className={style.text}>{t('deposit')}</p>
      <DepositMoney />
    </div>
  );
};

export default DepositMoneyMobile;
