import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './FavoritesInstruments.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import FavoritesItem from './FavoritesItem/FavoritesItem';

/**
 * Represents a FavoritesInstruments block inside Favorites block.
 * @returns {JSX.Element}
 */
const FavoritesInstruments = () => {
  const { t } = useTranslation();
  const {
    finance: {
      favoriteTrades: { items },
    },
  } = useRootModel();

  return (
    <div className={style.instruments}>
      <div className={style.title}>
        <p>{t('name')}</p>
        <p>{t('price')}</p>
      </div>
      {items.map(({ s, alias, lp, oldPrice, type }) => (
        <FavoritesItem
          symbol={s}
          alias={alias}
          price={lp}
          oldPrice={oldPrice}
          key={s}
          type={type}
        />
      ))}
    </div>
  );
};

export default observer(FavoritesInstruments);
