import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './ClosedTradesMobile.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import instrumentIcon from '../../../../assets/instrumentIcon';

import ClosedTradesMobileItem from './ClosedTradesMobileItem/ClosedTradesMobileItem';
import NotFoundMobile from '../../../components/NotFoundMobile/NotFoundMobile';
import Preloader from '../../../../components/ui/Preloader/Preloader';

/**
 * Represents Closed Trades component in Mobile version
 * @returns {JSX.Element}
 */

const ClosedTradesMobile = () => {
  const { t } = useTranslation();
  const {
    finance: {
      closedTrades: { total, trades, getClosedTrades, setPageSize, quantity, isLoading },
    },
  } = useRootModel();

  useEffect(() => {
    setPageSize(total);
    getClosedTrades();
  }, []);

  if (isLoading)
    return (
      <div className={style.preloaderCloseTradesMobile}>
        <Preloader />
      </div>
    );

  return (
    <>
      {trades?.length > 0 ? (
        <section className={style.container}>
          <div className={style.header}>
            <div className={style.instruments}>
              <p>{t('instruments')}</p>
              <p className={style.notifications}>{quantity}</p>
            </div>
            <p>{t('trade_profit')}</p>
          </div>
          {trades.map(({ id, amount, multiplier, closedAt, symbol, profit, operation }) => {
            const { alias, s, type } = symbol;
            return (
              <ClosedTradesMobileItem
                key={id}
                title={alias}
                number={s}
                dateClose={closedAt}
                amount={amount}
                multiplier={multiplier}
                profit={profit}
                icon={instrumentIcon[type][s]}
                operation={operation}
              />
            );
          })}
        </section>
      ) : (
        <NotFoundMobile />
      )}
    </>
  );
};

export default observer(ClosedTradesMobile);
