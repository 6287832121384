import React from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../../models/RootStore';
import Modal from '../../../Modal/Modal';
import style from '../../AppModals.module.scss';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import IncreaseContentMobile from './IncreaseContentMobile/IncreaseContentMobile';

const IncreaseModalMobile = () => {
  const {
    modal: {
      increaseTradeMobile: { isOpen },
    },
  } = useRootModel();
  return (
    <>
      {isOpen && (
        <Modal wrapperClassname={style.modalWrapper}>
          <ModalBody className={style.modal}>
            <IncreaseContentMobile />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(IncreaseModalMobile);
