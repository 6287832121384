import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './ProductItemDetailMobile.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import BackButton from '../../../../components/ui/BackButton/BackButton';
import FavoritesButton from '../../../../components/ui/FavoritesButton/FavoritesButton';
import TabsOnMainPage from '../../../components/TabsOnMainPage/TabsOnMainPage';
import ProductItemPriceChange from './ProductItemPriceChange/ProductItemPriceChange';
import ProductItemTraderSentiment from './ProductItemTraderSentiment/ProductItemTraderSentiment';
import ProductItemDetailPrice from './ProductItemDetailPrice/ProductItemDetailPrice';
// eslint-disable-next-line max-len
import OpenTradeButtonMobile from '../../../../components/ui/OpenTradeButtonMobile/OpenTradeButtonMobile';
import TVChart from '../../../../components/TVChart/TVChart';

/**
 * Represents Instrument details component on Mobile version
 * @returns {JSX.Element}
 */

const ProductItemDetailMobile = () => {
  const { t } = useTranslation();
  const { s } = useParams();
  const {
    finance: { item, setActiveSymbol, activeSymbol },
  } = useRootModel();

  const tabs = [
    {
      id: 0,
      tabLabel: t('chart'),
      component: (
        <TVChart
          symbol={activeSymbol}
          wrapperClassName={style.chartWrapper}
          className={style.chart}
        />
      ),
    },
    {
      id: 1,
      tabLabel: t('price_change'),
      component: <ProductItemPriceChange />,
    },
    {
      id: 2,
      tabLabel: t('sentiment'),
      component: <ProductItemTraderSentiment />,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (s) await setActiveSymbol(s);
    };
    fetchData();
  }, [s]);

  return (
    <section>
      <div className={style.titleBlock}>
        <BackButton url={`/products/${item?.type}`} />
        <div className={style.title}>
          <FavoritesButton
            onClick={item?.toggleFavorite}
            isFavorite={item?.favorite}
            isLoading={item?.isLoading}
          />
          <p>{item?.alias}</p>
        </div>
      </div>
      <TabsOnMainPage tabs={tabs} />
      <ProductItemDetailPrice />
      <OpenTradeButtonMobile />
    </section>
  );
};

export default observer(ProductItemDetailMobile);
