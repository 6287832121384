import React, { useEffect } from 'react';
import ErrorBoundary from '../../../Error/ErrorBoundary/ErrorBoundary';

/**
 * Represents Tabs for modal item content
 * @param {Component} component - react component what will be show
 * @param {Ref} tabContentWrapperRef - ref for outer wrapper
 * @returns {JSX.Element}
 */

const TabsForModalContent = ({ component: Component = null, tabContentWrapperRef = null }) => {
  useEffect(() => {
    if (tabContentWrapperRef?.current) {
      tabContentWrapperRef.current.scrollTop = 0;
    }
  }, [tabContentWrapperRef?.current]);

  const content = <section ref={tabContentWrapperRef}>{Component && <Component />}</section>;

  return <ErrorBoundary>{content}</ErrorBoundary>;
};

export default TabsForModalContent;
