import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
// import { VERIFY_ATTEMPTS_COUNT } from '../../constant/commonConstants';

const User = types.model('User', {
  active: types.boolean,
  email: types.string,
  firstName: types.maybeNull(types.string),
  id: types.number,
  imageUrl: types.maybeNull(types.string),
  langKey: types.string,
  lastName: types.maybeNull(types.string),
  login: types.optional(types.string, String),
  resetDate: types.maybeNull(types.string),
});

const AccountModel = types.model('AccountModel', {
  balance: types.number,
  id: types.number,
  available: types.optional(types.number, 0),
  profit: types.optional(types.number, 0),
  used: types.optional(types.number, 0),
});

// const DocumentPageModel = types.model('DocumentPageModel', {
//   path: types.maybeNull(types.string),
//   status: types.maybeNull(types.string),
// });
//
// const DocumentsModel = types.model('DocumentsModel', {
//   first: types.optional(DocumentPageModel, {}),
//   registration: types.optional(DocumentPageModel, {}),
// });

const DocumentsModel = types.model('DocumentsModel', {
  resource: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const UserModel = types
  .model('UserModel', {
    city: types.maybeNull(types.string),
    countryCode: types.maybeNull(types.string),
    dateOfBirth: types.maybeNull(types.string),
    demoAccount: types.maybeNull(AccountModel),
    demoVersion: types.boolean,
    documentsVerificationStatus: types.maybeNull(types.string),
    emailConfirmed: types.boolean,
    // id: types.optional(types.number, 0),
    marginCallEnabled: types.boolean,
    notificationHighlightDealsRiskEnabled: types.boolean,
    notificationTradeAlertsEnabled: types.boolean,
    passportId: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    phoneCode: types.maybeNull(types.string),
    phoneConfirmed: types.boolean,
    realAccount: types.maybeNull(AccountModel),
    tradingEnabled: types.boolean,
    twoFaEnabled: types.boolean,
    user: User,
    documents: types.array(DocumentsModel),
    // phoneVerifyAttempts: types.optional(types.number, VERIFY_ATTEMPTS_COUNT),
    // documents: types.optional(DocumentsModel, {
    //   first: {},
    //   registration: {},
    // }),
  })
  .actions((userData) => ({
    getBalance: flow(function* getUpdateBalance() {
      const { demoAccount, realAccount } = userData;
      try {
        const { data } = yield axios.get(`/services/trading/api/accounts/balance`);
        if (demoAccount) {
          userData.demoAccount = { ...demoAccount, ...data };
        } else {
          userData.realAccount = { ...realAccount, ...data };
        }
      } catch (e) {
        // todo error?
      }
    }),
  }))
  .views((userData) => ({
    get balance() {
      const { demoVersion, demoAccount, realAccount } = userData;
      return demoVersion ? demoAccount?.balance : realAccount?.balance;
    },
    get profit() {
      const { demoVersion, demoAccount, realAccount } = userData;
      return demoVersion ? demoAccount?.profit : realAccount?.profit;
    },
    get used() {
      const { demoVersion, demoAccount, realAccount } = userData;
      return demoVersion ? demoAccount?.used : realAccount?.used;
    },
    get available() {
      const { demoVersion, demoAccount, realAccount } = userData;
      return demoVersion ? demoAccount?.available : realAccount?.available;
    },
    get id() {
      return userData?.user?.id || null;
    },
    get firstName() {
      return userData.user.firstName || '';
    },
    get lastName() {
      return userData.user.lastName || '';
    },
    get fullName() {
      const { firstName, lastName } = userData;
      return `${firstName}${lastName ? ` ${lastName}` : ''}`;
    },
  }));

export default UserModel;
