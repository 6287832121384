import React from 'react';
import { observer } from 'mobx-react';

import style from './TVChartForModal.module.scss';
import { useRootModel } from '../../models/RootStore';
import instrumentIcon from '../../assets/instrumentIcon';

import TVChart from '../TVChart/TVChart';
import ErrorBoundary from '../Error/ErrorBoundary/ErrorBoundary';
import FavoritesButton from '../ui/FavoritesButton/FavoritesButton';
import Preloader from '../ui/Preloader/Preloader';

/**
 * Represents TVChartForModal component
 * @returns {JSX.Element}
 */
const TVChartForModal = () => {
  const {
    settings: { theme, language },
    user: { userData },
    finance: { activeSymbol, activeInterval, currentCategory, item, graphError },
  } = useRootModel();

  const Icon = instrumentIcon[currentCategory][activeSymbol];
  return (
    <ErrorBoundary>
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.symbolWrapper}>
            <i className={style.instrumentIcon}>{Icon && <Icon />}</i>
            {activeSymbol}
            <FavoritesButton
              isFavorite={item?.favorite}
              onClick={item?.toggleFavorite}
              isLoading={item?.isLoading}
            />
          </div>
        </div>
        {graphError ? (
          <Preloader className={style.loader} />
        ) : (
          <TVChart
            containerId='tv_chart_open_trade'
            isOpenTradeButton={false}
            isFullscreenButton={false}
            needToCloseStream={false}
            userId={userData?.id || null}
            symbol={activeSymbol}
            interval={activeInterval}
            theme={theme}
            language={language}
            wrapperClassName={style.tvChartWrapper}
            className={style.tvChart}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default observer(TVChartForModal);
