import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useRootModel } from '../../../../../../models/RootStore';
import style from './PriceMobile.module.scss';
import Switch from '../../../../../ui/Switch/Switch';
import OpenTradeButtons from '../OpenTradeButtons/OpenTradeButtons';
import LimitsMobile from '../LimitsMobile/LimitsMobile';
import Price from '../Price/Price';

/**
 * Represents Price Tab in Mobile version after click Open Trade Button
 * @returns {JSX.Element}
 */

const PriceMobile = () => {
  const [status, setStatus] = useState(false);
  const {
    finance: {
      trade: { setLoss, setProfit },
    },
  } = useRootModel();

  const switchHandler = () => {
    setStatus(!status);
  };

  useEffect(() => {
    return () => {
      setProfit(null);
      setLoss(null);
    };
  }, []);

  return (
    <>
      <div className={style.container}>
        <div className={style.switchWrapper}>
          <Switch checked={status} onChange={switchHandler} id='123_priceMob' />
          <p className={style.text}>Limit profits and loss</p>
        </div>
        <Price />
        <OpenTradeButtons />
      </div>

      {status && <LimitsMobile setStatus={setStatus} />}
    </>
  );
};

export default observer(PriceMobile);
