import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../util/error';
import socketUtils from '../../util/socketUtils';

const { REACT_APP_CHAT } = process.env;

const MessageModel = types
  .model('MessageModel', {
    id: types.number,
    clientId: types.number,
    operatorId: types.maybeNull(types.number),
    status: types.string,
    createdAt: types.maybeNull(types.string),
    text: types.string,
    chatId: types.number,
    sender: types.maybeNull(types.number),
    size: types.optional(types.number, 0),
    type: types.string,
    uri: types.maybeNull(types.string),
    viewedByOperator: types.boolean,
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    file: types.optional(types.string, ''),
    originalName: types.maybeNull(types.string),
  })
  .actions((message) => ({
    updateStatus(status) {
      message.status = status;
    },
    update(item) {
      message.createdAt = item.createdAt;
      message.text = item.text;
      message.sender = Number(item.sender);
      message.size = item.size;
      message.type = item.type;
      message.uri = item.uri;
      message.viewedByOperator = item.viewedByOperator;
      message.firstName = item.firstName;
      message.lastName = item.lastName;
      message.file = item.file;
      message.originalName = item.originalName;
      message.operatorId = Number(item.operatorId);
      message.status = item.status;
    },
  }));

const MessagesModel = types
  .model('MessagesModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    items: types.array(MessageModel),
    page: types.optional(types.number, 1),
    pagesCount: types.optional(types.number, 1),
  })
  .actions((messages) => ({
    setIsLoading(loading) {
      messages.isLoading = loading;
    },
    setError(err) {
      messages.error = err;
    },
    clearError() {
      messages.error = '';
    },
    setPagesCount(count) {
      messages.pagesCount = count;
    },
    addMessage(message) {
      messages.items = [...messages.items, message];
    },
    addItems(items) {
      const newItems = items.filter((item) => !messages.items.find((m) => m.id === item.id));
      messages.items = [...messages.items, ...newItems];
    },
    changeItem(item) {
      const newItem = messages.items.find((i) => i.id === item.id);
      if (!newItem) return;
      newItem.updateStatus(newItem.status);
    },
    updateItem(item) {
      const message = messages.items.find((i) => i.id === item.id);
      message?.update(item);
    },
    clearItems() {
      messages.items = [];
    },
    setPage(page) {
      messages.page = page;
    },
    setNextPage() {
      const { page, pagesCount, setPage } = messages;
      if (page < pagesCount) {
        setPage(page + 1);
      }
    },
    setPreviousPage() {
      const { page, setPage } = messages;
      if (page > 1) {
        setPage(page - 1);
      }
    },
    getMessages: flow(function* getMessages() {
      const { setIsLoading, clearError, setError, page, addItems, setPagesCount } = messages;
      setIsLoading(true);
      clearError();
      try {
        const { items } = getParent(messages);
        const { id } = items[0];
        const { data } = yield axios.get(`${REACT_APP_CHAT}/message/${id}/${page}`);
        setPagesCount(data.pagesCount);
        const messagesData = data.messages.map((m) => {
          return {
            ...m,
            clientId: Number(m.clientId),
            operatorId: Number(m.operatorId),
            sender: Number(m.sender),
          };
        });
        addItems(messagesData);
      } catch (e) {
        setError(e.message);
        error.errorHandler(e.message);
      } finally {
        setIsLoading(false);
      }
    }),
    sendMessage: flow(function* sendMessage(message, fileUri = null, filename = '', type = 'text') {
      const { setIsLoading, clearError, setError } = messages;
      setIsLoading(true);
      clearError();
      try {
        const chat = getParent(messages);
        const {
          user: { userData },
        } = getParent(chat);
        const { id: chatId, operatorId } = chat.items[0];

        const requestData = {
          clientId: userData.id,
          sender: userData.id,
          operatorId,
          chatId,
          type,
          text: message,
          status: 'sent',
          size: 0,
          viewedByOperator: false,
          uri: fileUri,
          originalName: filename,
        };
        yield axios.post(`${REACT_APP_CHAT}/message`, requestData);
      } catch (e) {
        setError(e.message);
        error.errorHandler(e.message);
        throw new Error(e.message);
      } finally {
        setIsLoading(false);
      }
    }),
    sendFile: flow(function* sendFile(file) {
      const { setIsLoading, clearError, setError } = messages;
      setIsLoading(true);
      clearError();
      try {
        const {
          data: { fileS3Key },
        } = yield axios.post(`${REACT_APP_CHAT}/file-s3/upload`, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return `${REACT_APP_CHAT}/file-s3?fileS3Key=${fileS3Key}`;
      } catch (e) {
        setError(e.message);
        error.errorHandler(e.message);
        throw new Error(e.message);
      }
    }),
    getFile: flow(function* getFile(uri) {
      try {
        const responseS3 = yield axios.post(
          `${REACT_APP_CHAT}/file-s3`,
          JSON.stringify({
            fileS3Key: uri,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        return responseS3.data;
      } catch (e) {
        // todo error
        return '';
      }
    }),
    getUpdateMessages() {
      const messageListener = ({ createdMessage }) => {
        const { addMessage, items } = messages;
        const messageInState = items.find((i) => i.id === createdMessage.id);
        if (!messageInState) {
          const newMessage = {
            ...createdMessage,
            clientId: Number(createdMessage.clientId),
            operatorId: Number(createdMessage.operatorId),
            sender: Number(createdMessage.sender),
          };
          addMessage(newMessage);
          return;
        }
        messageInState.updateStatus(createdMessage.status);
      };

      socketUtils.onMessage(messageListener);
    },
    getUpdateMessage() {
      const updateListener = ({ updatedMessage }) => {
        messages.updateItem(updatedMessage);
      };
      socketUtils.handleUpdateMessage(updateListener);
    },
    setIsRead: flow(function* setIsRead(id) {
      const { setIsLoading, clearError, setError } = messages;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.put(`${REACT_APP_CHAT}/message/${id}`, {
          status: 'read',
        });
        messages.changeItem(data);
      } catch (e) {
        setError(e.message);
        error.errorHandler(e.message);
        throw new Error(e.message);
      } finally {
        setIsLoading(false);
      }
    }),
  }))
  .views((messages) => ({
    get sortedItems() {
      return messages.items.slice().sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
    },
    get isNewMessages() {
      return messages.items.some((i) => i.status === 'sent');
    },
  }));

export default MessagesModel;
