import React from 'react';
import style from './ProductItemPriceChange.module.scss';
import PriceChange from '../../../../../components/PriceChange/PriceChange';

/**
 * Represents Price change tab in Instrument details on Mobile version
 * @returns {JSX.Element}
 */

const ProductItemPriceChange = () => {
  return <PriceChange className={style.priceChange} />;
};

export default ProductItemPriceChange;
