import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import style from './FlowOfFundsMobileItem.module.scss';
import { operationTitle } from '../../../../../constant/commonConstants';
import { useRootModel } from '../../../../../models/RootStore';
import date from '../../../../../util/date';
import CoinFormat from '../../../../../components/CoinFormat/CoinFormat';

/**
 * Represents Instrument item in Flow of funds on Mobile version
 * @param {string} date - operation date
 * @param {string} title - instrument title
 * @param {string} operation - operation title
 * @param {number} balance - balance in $
 * @param {number} amount - amount in $
 * @returns {JSX.Element}
 */

const FlowOfFundsMobileItem = ({ operation, date: openedAt, title, balance, amount }) => {
  const {
    settings: { language },
  } = useRootModel();
  return (
    <div className={style.instrumentMobileItem}>
      <div className={style.leftBlock}>
        <div>
          <p>{operationTitle[operation]}</p>
          <p>{title}</p>
        </div>
        <p className={style.text}>{date.toLocaleString(openedAt, language)}</p>
      </div>
      <div className={style.rightBlock}>
        <CoinFormat value={balance} />
        <div className={cn(style.text, style.red, { [style.green]: amount > 0 })}>
          <CoinFormat value={amount} />
        </div>
      </div>
    </div>
  );
};

export default observer(FlowOfFundsMobileItem);
