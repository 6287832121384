import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useWindowSize from '../../hooks/useWindowSize';
import { useRootModel } from '../../models/RootStore';
import { PAGES_WITHOUT_AUTH_BUTTON } from '../../constant/commonConstants';

import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import HeaderBalance from './HeaderBalance/HeaderBalance';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import HeaderPageTitle from './HeaderPageTitle/HeaderPageTitle';
import HeaderAuthButton from './HeaderAuthButton/HeaderAuthButton';

import styles from './Header.module.scss';

/**
 * Represents header
 * @returns {JSX.Element}
 */
const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const {
    user: { isAuthorized, userData, verifyEmailConfirm },
    settings: { setUserDefaultLanguage },
  } = useRootModel();

  useEffect(() => {
    if (userData) {
      setUserDefaultLanguage();
    }
  }, [userData?.user]);

  const { pathname, search } = useLocation();

  const isAuthButton = !PAGES_WITHOUT_AUTH_BUTTON.some((page) => pathname.includes(page));

  useEffect(() => {
    if (!(pathname === '/' && search)) return;
    const match = search.match(/\?token=([^&]+)/);
    if (!match || !match.length) return;
    const token = match[1];
    verifyEmailConfirm(token);
  }, []);

  const handleDeposit = () => {
    history.push('/balance', { activeTab: 1 });
  };

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <HeaderLogo />
          {isAuthorized && <HeaderPageTitle />}
        </div>
        <HeaderBalance />
        {!isAuthorized && isAuthButton && <HeaderAuthButton />}
        {!isMobile && isAuthorized && (
          <button type='button' className={styles.depositButton} onClick={handleDeposit}>
            {t('deposit')}
          </button>
        )}
        <HeaderNavigation />
      </div>
    </header>
  );
};

export default observer(Header);
