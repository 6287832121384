import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './AccountSettings.module.scss';
import { useRootModel } from '../../../models/RootStore';

import { ReactComponent as HatIcon } from '../../../assets/image/common/hat.svg';
import { ReactComponent as SupportIcon } from '../../../assets/image/common/support.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/image/common/arrowRight.svg';
import { ReactComponent as BrightnessIcon } from '../../../assets/image/common/brightness.svg';
import { ReactComponent as CrownIcon } from '../../../assets/image/common/crown-white.svg';
import { ReactComponent as StarIcon } from '../../../assets/image/common/star-white.svg';

import Button from '../../../components/ui/Button/Button';
import MenuItem from '../../../components/ui/MenuItem/MenuItem';
import Switch from '../../../components/ui/Switch/Switch';
import Languages from './Languages/Languages';
import AlertContext from '../../../context/alert/alertContext';

const AccountSettings = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    settings: { theme, toggleTheme },
    user: { userData, switchAccount, updateDemoBalance },
  } = useRootModel();

  if (!userData) return <Redirect to='/login' />;

  const switchBrightness = () => <Switch checked={theme === 'dark'} onChange={toggleTheme} />;

  const { demoVersion, demoAccount, realAccount } = userData;
  const isDemoNull = demoAccount === null;

  const accountId = demoVersion ? demoAccount.id : realAccount.id;

  const handleUpdateBalance = () => {
    try {
      updateDemoBalance();
    } catch (err) {
      alert.show(err.message);
    }
  };

  return (
    <div className={style.account}>
      <div className={style.buttons}>
        <div className={style.name}>
          <p className={style.title}>{demoVersion ? t('demo_account') : t('real_account')}</p>
          <p className={style.number}>Account № {accountId}</p>
          <div className={style.hat}>
            <HatIcon />
          </div>
        </div>
        {!isDemoNull && (
          <Button
            text={demoVersion ? t('switch_real') : t('switch_demo')}
            width='full'
            size='medium'
            color='primary'
            onClick={switchAccount}
          />
        )}
        {demoVersion ? (
          <Button text={t('update')} width='full' size='medium' onClick={handleUpdateBalance} />
        ) : (
          <Link to='/balance/deposit' className={style.depositButton}>
            {t('deposit')}
          </Link>
        )}
      </div>
      <div className={style.options}>
        <MenuItem text={t('dark')} iconLeft={BrightnessIcon} elementRight={switchBrightness} />
        <Link to='/profile/support'>
          <MenuItem text={t('support')} iconLeft={SupportIcon} elementRight={ArrowRightIcon} />
        </Link>
        <Link to='/profile/status'>
          <MenuItem text={t('status_info')} iconLeft={CrownIcon} elementRight={ArrowRightIcon} />
        </Link>
        <Link to='/info/affiliate'>
          <MenuItem
            text={t('affilate_program')}
            iconLeft={StarIcon}
            elementRight={ArrowRightIcon}
          />
        </Link>
        <Languages />
      </div>
    </div>
  );
};

export default observer(AccountSettings);
