import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import i18n from '../../../translations/i18n';

import style from './HeaderUserInfo.module.scss';
import useOnClick from '../../../hooks/useOnClick';
import getLanguage from '../../../util/getLanguage';
import { useRootModel } from '../../../models/RootStore';

import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDown.svg';
import { ReactComponent as CrownGold } from '../../../assets/image/common/crown-gold.svg';

import ButtonProfile from './HeaderButtons/ButtonProfile/ButtonProfile';
import ButtonAffilateProgram from './HeaderButtons/ButtonAffilateProgram/ButtonAffilateProgram';
import ButtonStatusInfo from './HeaderButtons/ButtonStatusInfo/ButtonStatusInfo';
import HeaderLanguage from '../HeaderLanguage/HeaderLanguage';
import ButtonSwitchBrightness from './HeaderButtons/ButtonSwitchBrightness/ButtonSwitchBrightness';
import ButtonLogout from './HeaderButtons/ButtonLogout/ButtonLogout';
import ButtonUpdateDemo from './HeaderButtons/ButtonUpdateDemo/ButtonUpdateDemo';
import ButtonSwitchReal from './HeaderButtons/ButtonSwitchReal/ButtonSwitchReal';

/**
 * Represents HeaderUserInfo component
 * @returns {JSX.Element}
 */
const HeaderUserInfo = () => {
  const [showContent, setShowContent] = useState(false);
  const refs = useRef({});
  const wrapperRef = useRef(null);
  const {
    user: { isAuthorized, setUserLanguage },
    settings: { langKeys, changeLanguage },
  } = useRootModel();

  const [showLanguages, setShowLanguages] = useState(false);
  const handleChangeLanguage = async (lang) => {
    setShowLanguages(false);
    const langKey = getLanguage(langKeys, lang);
    await changeLanguage(langKey);
    await i18n.changeLanguage(langKey);
    if (isAuthorized) {
      setUserLanguage(langKey);
    }
  };

  const {
    user: { userData, isDemoAccountNull },
  } = useRootModel();

  const closeMenu = () => setShowContent(false);
  useOnClick(wrapperRef, closeMenu);

  const handleOnClick = () => setShowContent((prev) => !prev);

  const handleOnKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp': {
        const key = Object.keys(refs.current).length - 1;
        refs.current[key]?.focus();
        e.preventDefault();
        break;
      }
      case 'ArrowDown':
        refs.current[0]?.focus();
        e.preventDefault();
        break;
      case 'Enter':
        setShowContent((prev) => !prev);
        break;
      case 'Escape':
        closeMenu();
        break;
      default:
    }
  };

  const transitionStyles = {
    enter: style.enter,
    enterActive: style.enterActive,
    enterDone: style.enterDone,
    exit: style.exit,
    exitActive: style.exitActive,
    exitDone: style.exitDone,
  };

  const handleLanguageMouseEnter = () => setShowLanguages(true);
  const handleLanguageMouseLeave = () => setShowLanguages(false);

  return (
    <div
      role='button'
      className={cn(
        style.dropdown,
        style.userDropdown,
        { [style.active]: showContent },
        style.down,
      )}
      tabIndex={0}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      ref={wrapperRef}
    >
      <div className={style.currentItemWrapper}>
        <p className={style.nameWrapper}>
          <i className={style.icon}>
            <CrownGold />
          </i>
          <span className={style.name}>{userData?.fullName || userData?.user.email}</span>
        </p>
        <i tabIndex={-1} className={cn(style.arrow)}>
          {showContent ? <ArrowUp /> : <ArrowDown />}
        </i>
      </div>

      <CSSTransition in={showContent} timeout={700} classNames={transitionStyles} unmountOnExit>
        <>
          <div
            role='menu'
            className={cn(style.dropdownContent, {
              [style.showContent]: showContent,
              [style.top]: !isDemoAccountNull,
            })}
          >
            <ButtonProfile />
            {!isDemoAccountNull && <ButtonUpdateDemo />}
            {!isDemoAccountNull && <ButtonSwitchReal />}
            <ButtonAffilateProgram />
            <ButtonStatusInfo />
            <HeaderLanguage
              onMouseEnter={handleLanguageMouseEnter}
              onMouseLeave={handleLanguageMouseLeave}
              isShowButtons={showLanguages}
            />
            <ButtonSwitchBrightness />
            <ButtonLogout />
          </div>
        </>
      </CSSTransition>
      {showLanguages && (
        <div
          className={cn(style.buttons, { [style.topLang]: !isDemoAccountNull })}
          onMouseEnter={handleLanguageMouseEnter}
          onMouseLeave={handleLanguageMouseLeave}
        >
          {langKeys.map(([, lang]) => (
            <button
              className={style.button}
              type='button'
              onClick={() => handleChangeLanguage(lang)}
              key={lang}
            >
              {lang}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeaderUserInfo;
