import * as Yup from 'yup';

// todo translate

export const EMAIL = 'email';
export const PASSWORD = 'password';
export const NEW_PASSWORD = 'newPassword';
export const CONFIRM_PASSWORD = 'confirmPassword';
export const FIRST_NAME = 'firstName';
export const FIRST_NAME_MODAL = 'firstNameModal';
export const LAST_NAME = 'lastName';
export const LAST_NAME_MODAL = 'lastNameModal';
export const CITY = 'city';
export const CITY_MODAL = 'cityModal';
export const PASSPORT = 'passportId';
export const PASSPORT_MODAL = 'passportIdModal';
export const PHONE = 'phone';
export const SMS_CODE = 'smsCode';
export const EMAIL_CODE = 'emailCode';
export const AMOUNT_WITHDRAW = 'amountWithdraw';
export const DEPOSIT_MONEY = 'depositMoney';
export const AMOUNT = 'amount';
export const AMOUNT_1 = 'amount';
export const CARD_NUMBER = 'cardNumber';
export const CARDHOLDER_NAME = 'cardholderName';
export const EXPIRATION_DATE = 'expirationDate';
export const CVV = 'cvv';
export const COUNTRY = 'country';
export const COUNTRY_CODE = 'countryCode';
export const SUBJECT = 'subject';
export const DESCRIPTION = 'description';

export const EMAIL_MAX_LENGTH = 50;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 30;
export const NAME_MAX_LENGTH = 50;
export const CITY_MAX_LENGTH = 100;
export const PASSPORT_MIN_LENGTH = 6;
export const PASSPORT_MAX_LENGTH = 24;
export const PHONE_MIN_LENGTH = 5;
export const PHONE_MAX_LENGTH = 20;
export const CARD_NUMBER_MIN_LENGTH = 13;
export const CARD_NUMBER_MAX_LENGTH = 34;
export const CARD_NAME_MAX_LENGTH = 100;
export const CODE_LENGTH = 6;

// todo translate
export const ERROR_TEXT_REQUIRED = 'Required';
export const ERROR_TEXT_DATE_NOT_VALID = 'Date not valid';

const email = Yup.string()
  .email('Invalid email')
  .max(EMAIL_MAX_LENGTH, `Must be ${EMAIL_MAX_LENGTH} characters or less`)
  .required(ERROR_TEXT_REQUIRED);

const password = Yup.string()
  .min(PASSWORD_MIN_LENGTH, `Must be at least ${PASSWORD_MIN_LENGTH} characters`)
  .max(PASSWORD_MAX_LENGTH, `Must be ${PASSWORD_MAX_LENGTH} characters or less`)
  .required(ERROR_TEXT_REQUIRED);

const confirmPassword = Yup.string()
  .oneOf([Yup.ref(NEW_PASSWORD), null], 'Passwords must match')
  .required(ERROR_TEXT_REQUIRED);

const firstName = Yup.string()
  .max(NAME_MAX_LENGTH, `Must be ${NAME_MAX_LENGTH} characters or less`)
  .required(ERROR_TEXT_REQUIRED);

const lastName = Yup.string()
  .max(NAME_MAX_LENGTH, `Must be ${NAME_MAX_LENGTH} characters or less`)
  .required(ERROR_TEXT_REQUIRED);

const city = Yup.string()
  .max(CITY_MAX_LENGTH, `Must be ${CITY_MAX_LENGTH} characters or less`)
  .required(ERROR_TEXT_REQUIRED);

const passport = Yup.string()
  .min(PASSPORT_MIN_LENGTH, `Must be at least ${PASSPORT_MIN_LENGTH} characters`)
  .max(PASSPORT_MAX_LENGTH, `Must be ${PASSPORT_MAX_LENGTH} characters or less`)
  .required(ERROR_TEXT_REQUIRED);

const phone = Yup.string()
  .matches(/^[0-9]+$/, 'Must be only digits')
  .min(PHONE_MIN_LENGTH, `Must be at least ${PHONE_MIN_LENGTH} characters`)
  .max(PHONE_MAX_LENGTH, `Must be ${PHONE_MAX_LENGTH} characters or less`)
  .required(ERROR_TEXT_REQUIRED);

const depositMoney = Yup.number()
  .min(100, 'Min amount for withdraw is 100')
  .required(ERROR_TEXT_REQUIRED);

const amountWithdraw = Yup.number()
  .min(1, 'Min amount for withdraw is 1')
  .required(ERROR_TEXT_REQUIRED);

const amount = Yup.string()
  .matches(/^[0-9,.]+$/, 'Must be only digits')
  .required(ERROR_TEXT_REQUIRED);

const cardNumber = Yup.string()
  .min(CARD_NUMBER_MIN_LENGTH + 3, `Must be min ${CARD_NUMBER_MIN_LENGTH} characters`)
  .max(CARD_NUMBER_MAX_LENGTH + 8, `Must be max ${CARD_NUMBER_MAX_LENGTH} characters`)
  .required(ERROR_TEXT_REQUIRED);

const cardholderName = Yup.string()
  .max(CARD_NAME_MAX_LENGTH, `Must be max ${CARD_NAME_MAX_LENGTH} characters`)
  .required(ERROR_TEXT_REQUIRED);
const expirationDate = Yup.string().min(5, `Must be 4 characters`).required(ERROR_TEXT_REQUIRED);
const cvv = Yup.string()
  .matches(/^[0-9]+$/, 'Must be only digits')
  .min(3, 'Must be 3 characters')
  .required(ERROR_TEXT_REQUIRED);

const code = Yup.string()
  .matches(/^[0-9]+$/, 'Must be only digits')
  .min(CODE_LENGTH, `Must be ${CODE_LENGTH} characters`)
  .max(CODE_LENGTH, `Must be ${CODE_LENGTH} characters`)
  .required(ERROR_TEXT_REQUIRED);

const country = Yup.string().required(ERROR_TEXT_REQUIRED);
const countryCode = Yup.string().required(ERROR_TEXT_REQUIRED);
const subject = Yup.string().required(ERROR_TEXT_REQUIRED);
const description = Yup.string().required(ERROR_TEXT_REQUIRED);

const validationSchema = {
  [EMAIL]: email,
  [PASSWORD]: password,
  [NEW_PASSWORD]: password,
  [CONFIRM_PASSWORD]: confirmPassword,
  [FIRST_NAME]: firstName,
  [FIRST_NAME_MODAL]: firstName,
  [LAST_NAME]: lastName,
  [LAST_NAME_MODAL]: lastName,
  [CITY]: city,
  [CITY_MODAL]: city,
  [PASSPORT]: passport,
  [PASSPORT_MODAL]: passport,
  [PHONE]: phone,
  [AMOUNT_WITHDRAW]: amountWithdraw,
  [DEPOSIT_MONEY]: depositMoney,
  [AMOUNT]: amount,
  [AMOUNT_1]: amount,
  [CARD_NUMBER]: cardNumber,
  [CARDHOLDER_NAME]: cardholderName,
  [EXPIRATION_DATE]: expirationDate,
  [CVV]: cvv,
  [SMS_CODE]: code,
  [EMAIL_CODE]: code,
  [COUNTRY]: country,
  [COUNTRY_CODE]: countryCode,
  [SUBJECT]: subject,
  [DESCRIPTION]: description,
};

/**
 * Formik initial values with validation schema
 * @param {Array<string>} values - names of fields
 * @returns {{initialValues: {}, schema}}
 */
export const getFormikInitialValuesWithSchema = (values) => {
  const initialValues = {};
  const schemaObj = {};

  values.forEach((key) => {
    if (!key) return;
    initialValues[key] = '';
    schemaObj[key] = validationSchema[key];
  });
  const schema = Yup.object(schemaObj);

  return { initialValues, schema };
};

export default validationSchema;
