import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './PendingRightSideContent.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import PendingRightSideItem from './PendingRightSideItem/PendingRightSideItem';
import ButtonToggle from '../../../ButtonToggle/ButtonToggle';

/**
 * Represents a Pending content inside Pending orders.
 * @returns {JSX.Element}
 */
const PendingRightSideContent = () => {
  const { t } = useTranslation();
  const {
    finance: {
      pendingTrades: { items },
    },
  } = useRootModel();
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div className={style.container}>
      {!!items.length && (
        <div className={style.instrumentTitle}>
          <p>{t('instrument')}</p>
          <p className={style.atPrice}>{t('atPrice')}</p>
        </div>
      )}
      <div className={style.contentVisible}>
        {items.slice(0, 3).map(({ symbol, amount, pendingPrice, multiplier, operation, id }) => (
          <PendingRightSideItem
            key={id}
            symbol={symbol?.s}
            amount={amount}
            pendingPrice={pendingPrice}
            multiplier={multiplier}
            operation={operation}
            id={id}
          />
        ))}
      </div>
      <div className={cn(style.content, { [style.visible]: isVisible })}>
        {items
          .slice(3, items.length)
          .map(({ symbol, amount, pendingPrice, multiplier, operation, id }) => (
            <PendingRightSideItem
              key={id}
              symbol={symbol?.s}
              amount={amount}
              pendingPrice={pendingPrice}
              multiplier={multiplier}
              operation={operation}
              id={id}
            />
          ))}
      </div>
      {items.length > 3 && <ButtonToggle isVisible={isVisible} handleToggle={handleToggle} />}
    </div>
  );
};

export default observer(PendingRightSideContent);
