import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { CSSTransition } from 'react-transition-group';

import style from './PaymentHistoryMobileItem.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import getAnimationClasses from '../../../../../util/getAnimationClasses';

import ButtonOpenDown from '../../../../../components/ui/ButtonOpenDown/ButtonOpenDown';
import string from '../../../../../util/string';

/**
 * Represent History item for mobile
 * @param {number} transferNumber - transfer number
 * @param {string} paymentMethod - Payment method
 * @param {number} amount - trade amount
 * @param {'DEPOSIT', 'WITHDRAWAL'} type - payment type
 * @param {number} exchangeRate - exchange rate
 * @param {Date} date - date of transfer
 * @param {string} status - is transfer complete
 * @returns {JSX.Element}
 */
const PaymentHistoryMobileItem = ({
  transferNumber,
  paymentMethod,
  amount,
  type,
  exchangeRate,
  date,
  status,
}) => {
  const { t } = useTranslation();
  const {
    settings: { language },
  } = useRootModel();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow((prev) => !prev);
  };
  const completed = status === 'COMPLETED';

  return (
    <div className={style.paymentHistoryMobileItem}>
      <div className={style.header}>
        <div className={style.numberAndStatusWrapper}>
          <p className={style.number}>{transferNumber}</p>
          <p className={completed ? style.statusComplete : style.statusDeclined}>
            {t('transfer')} {completed ? t('completed') : t('declined')}
          </p>
        </div>
        <div className={style.method}>
          <p className={style.methodName}>{paymentMethod}</p>
          <p className={style.methodTitle}>{t('payment')}</p>
        </div>
        <ButtonOpenDown isOpen={show} handleClick={handleShow} />
      </div>

      <CSSTransition in={show} timeout={700} classNames={getAnimationClasses(style)} unmountOnExit>
        <div className={style.contentWrapper}>
          <div className={style.body}>
            <div className={style.item}>
              <p className={style.amount}>${string.formatNumber(amount, { withoutSign: true })}</p>
              <p className={style.itemTitle}>{t('amount')}</p>
            </div>
            <div className={style.item}>
              <p className={style.amount}>{t(type?.toLowerCase())}</p>
              <p className={style.itemTitle}>{t('type')}</p>
            </div>
            <div className={style.item}>
              <p className={style.amount}>{numeral(exchangeRate).format('$0.00000')}</p>
              <p className={style.itemTitle}>{t('exchange')}</p>
            </div>
          </div>

          <div className={style.footer}>
            <p className={style.time}>
              {new Date(date).toLocaleString(language, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })}
            </p>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default observer(PaymentHistoryMobileItem);
