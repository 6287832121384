import { EventSourcePolyfill } from 'event-source-polyfill';
import axios from 'axios';
// import { DEFAULT_CATEGORY, DEFAULT_SYMBOL } from '../constant/commonConstants';

const { REACT_APP_FINANCE_SERVICE, REACT_APP_NOTIFICATION_SERVICE } = process.env;

let eventSource;
let eventSourceSymbol;
let eventSourceNotifications;
let eventSourceBalance;
let eventSourceTrades;
let eventSourceFavorites;
let isRightBarFavorites = false;
let isRightBarTrades = false;

const getSymbol = (source = eventSourceSymbol) => {
  const index = source.url.lastIndexOf('/');
  return source.url.slice(index + 1);
};

const getSymbolFromString = (str) => {
  const symbol = str.match(/.*([A-Z]+)_#_.*$/);
  if (symbol) return symbol[1];
  return null;
};

const handleError = (eventSrc) => (event) => {
  const {
    target: { readyState, url, onmessage, onopen },
  } = event;

  if (readyState === 2) {
    eventSrc = new EventSourcePolyfill(url, {
      headers: { Authorization: axios.defaults.headers.common.Authorization },
    });
    eventSrc.onerror = handleError;
  }
  if (onmessage) eventSrc.onmessage = onmessage;
  if (onopen) eventSrc.onopen = onopen;
};

const createEventSource = () => {
  if (eventSource && eventSource.readyState !== 2) return eventSource;
  if (eventSource && eventSource.readyState === 1) eventSource.close();
  eventSource = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/updates`);
  eventSource.onerror = handleError(eventSource);
  return eventSource;
};

const createEventSourceTrades = () => {
  if (!eventSourceTrades || eventSourceTrades.readyState === 2) {
    eventSourceTrades = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/updates`);
    eventSourceTrades.onerror = handleError(eventSourceTrades);
    return eventSourceTrades;
  }
};

const createEventSourceFavorites = () => {
  if (!eventSourceFavorites || eventSourceFavorites.readyState === 2) {
    eventSourceFavorites = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/updates`);
    eventSourceFavorites.onerror = handleError(eventSourceFavorites);
    return eventSourceFavorites;
  }
};

// todo createEventSource forex?
const createEventSourceSymbol = (symbol, category) => {
  if (
    eventSourceSymbol &&
    eventSourceSymbol.readyState !== 2 &&
    symbol === getSymbol(eventSourceSymbol)
  )
    return eventSourceSymbol;
  if (
    eventSourceSymbol &&
    (eventSourceSymbol.readyState === 1 || eventSourceSymbol.readyState === 0)
  )
    eventSourceSymbol.close();
  eventSourceSymbol = new EventSource(
    `${REACT_APP_FINANCE_SERVICE}/api/symbols/${category}/${symbol}/updates/`,
  );
  eventSourceSymbol.onerror = handleError(eventSourceSymbol);
  return eventSourceSymbol;
};

const createEventSourceNotifications = () => {
  if (eventSourceNotifications && eventSourceNotifications.readyState !== 2)
    return eventSourceNotifications;
  if (eventSourceNotifications && eventSourceNotifications.readyState === 1)
    eventSourceNotifications.close();
  eventSourceNotifications = new EventSourcePolyfill(
    `${REACT_APP_NOTIFICATION_SERVICE}/api/notifications/updates/`,
    {
      headers: { Authorization: axios.defaults.headers.common.Authorization },
      heartbeatTimeout: 36000 * 1000,
    },
  );
  eventSourceNotifications.onerror = handleError(eventSourceNotifications);
  return eventSourceNotifications;
};

const createEventSourceBalance = () => {
  if (eventSourceBalance && eventSourceBalance.readyState !== 2) return eventSourceBalance;
  if (eventSourceBalance && eventSourceBalance.readyState === 1) eventSourceBalance.close();
  eventSourceBalance = new EventSourcePolyfill(`/api/accounts/balance`);
  eventSourceBalance.onerror = handleError(eventSourceBalance);
  return eventSourceBalance;
};

const getEventSource = () => eventSource;
const getEventSourceSymbol = () => eventSourceSymbol;
const getEventSourceNotifications = () => eventSourceNotifications;
const closeStream = () => {
  if (eventSource) eventSource.close();
};
const closeStreamTrades = () => {
  if (eventSourceTrades && !isRightBarTrades) eventSourceTrades.close();
};
const closeStreamFavorites = () => {
  if (eventSourceFavorites && !isRightBarFavorites) eventSourceFavorites.close();
};
const closeSymbolStream = () => {
  if (eventSourceSymbol) eventSourceSymbol.close();
};
const closeNotificationsStream = () => {
  if (eventSourceNotifications) eventSourceNotifications.close();
};
const closeBalanceStream = () => {
  if (eventSourceBalance) eventSourceBalance.close();
};

const changeRightBarFavoritesState = (state) => {
  isRightBarFavorites = state;
};
const changeRightBarTradesState = (state) => {
  isRightBarTrades = state;
};

export default {
  createEventSource,
  createEventSourceSymbol,
  createEventSourceTrades,
  createEventSourceFavorites,
  getEventSource,
  getEventSourceSymbol,
  closeStream,
  closeStreamTrades,
  closeStreamFavorites,
  closeSymbolStream,
  getSymbol,
  getSymbolFromString,
  createEventSourceNotifications,
  getEventSourceNotifications,
  closeNotificationsStream,
  createEventSourceBalance,
  closeBalanceStream,
  changeRightBarFavoritesState,
  changeRightBarTradesState,
};
