import React from 'react';
import cn from 'classnames';
import styles from './Switch.module.scss';

/**
 * @param {boolean} checked - is checkbox checked
 * @param {function} onChange - on change handler
 * @param {string} id - id for input and htmlFor attribute
 * @param {string} title - title for the switch
 * @param {CSSStyleRule} labelClassName - labelClassName
 * @returns {JSX.Element}
 */
const Switch = ({ checked, onChange, id, title, labelClassName, sliderClassName }) => {
  return (
    <label htmlFor={`${id}_${title}`} className={cn(styles.switch, labelClassName)}>
      <input id={`${id}_${title}`} type='checkbox' checked={checked} onChange={onChange} />
      <span className={cn(styles.slider, sliderClassName)} />
    </label>
  );
};

export default Switch;
