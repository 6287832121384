import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import cn from 'classnames';
import i18n from '../../../../../translations/i18n';

import style from '../../HeaderUserInfo.module.scss';
import { DEFAULT_LANGUAGE } from '../../../../../constant/commonConstants';

import { ReactComponent as Logout } from '../../../../../assets/image/common/logout.svg';
import { useRootModel } from '../../../../../models/RootStore';

/**
 * Represents ButtonLogout component
 * @returns {JSX.Element}
 */
const ButtonLogout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    user: { logout },
    settings: { setDefaultLanguage },
  } = useRootModel();

  const handleLogout = async () => {
    await logout();
    setDefaultLanguage();

    await i18n.changeLanguage(DEFAULT_LANGUAGE);
    history.push('/');
  };

  return (
    <div className={cn(style.buttonWrapper, style.logout)}>
      <i className={cn(style.icon, style.logoutIconMini)}>
        <Logout />
      </i>
      <button type='button' className={cn(style.button, style.buttonLogout)} onClick={handleLogout}>
        {t('logout')}
      </button>
    </div>
  );
};

export default observer(ButtonLogout);
