import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import AlertContext from '../../../../../context/alert/alertContext';
import { useRootModel } from '../../../../../models/RootStore';
import style from './IncreaseContentMobile.module.scss';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';
import { ReactComponent as Tip } from '../../../../../assets/image/common/tip.svg';
import { OPERATION } from '../../../../../constant/commonConstants';
import { ReactComponent as UpArrow } from '../../../../../assets/image/common/upArr.svg';
import { ReactComponent as DownArrow } from '../../../../../assets/image/common/downArr.svg';
import NumberInput from '../../../../ui/NumberInputWithDropdown/NumberInputWithDropdown';
import Button from '../../../../ui/Button/Button';
import CoinFormat from '../../../../CoinFormat/CoinFormat';

const IncreaseContentMobile = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    modal: {
      increaseTradeMobile: { close },
    },
    finance: {
      activeTrades: { activeTrade, getActiveTrades },
      trade: { increase, increaseAmount },
    },
  } = useRootModel();

  const { id, operation, amount } = activeTrade;

  const handleIncrease = async () => {
    try {
      await increase(id);
      alert.show(t('order_increased'), 'success');
      close();
      getActiveTrades();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <p>{t('increase_trade')}</p>
        <div className={style.tipBlock}>
          <Tip />
          <p className={style.tipContent}>
            {t('you_can_increase')} <br />
            {t('increase_speed')} <br />
            {t('avoid_loss')} <br />
            {t('increasing_your_trade')}
          </p>
        </div>
        <button type='button' onClick={close} className={style.closeButton}>
          <CloseIcon />
        </button>
      </div>
      <div className={style.main}>
        <div className={style.mainContent}>
          <p className={style.operation}>
            {t('operation')}: {t(operation.toLowerCase())}
            <span className={style.arrow}>
              {operation === OPERATION.buy ? <UpArrow /> : <DownArrow />}
            </span>
          </p>
          <div>
            <CoinFormat value={amount} withoutSign={true} />
          </div>
        </div>
        <NumberInput label labelTitle={t('add')} />
        <p className={style.commissions}>{t('commissions')}</p>
        <div className={style.bottomBlock}>
          <p className={style.text}>{t('commission_for_increasing')}</p>
          <p>0.0000%</p>
        </div>
      </div>
      <div className={style.buttonContainer}>
        <Button text={t('cancel')} color='secondary' fontWeight='bold' onClick={close} />
        <Button
          text={t('increase')}
          color='primary'
          fontWeight='bold'
          onClick={handleIncrease}
          disabled={increaseAmount <= 0}
        />
      </div>
    </div>
  );
};

export default observer(IncreaseContentMobile);
