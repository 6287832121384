import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './HeaderLanguage.module.scss';
import { LANGUAGES } from '../../../constant/commonConstants';
import { useRootModel } from '../../../models/RootStore';

import { ReactComponent as LanguageIcon } from '../../../assets/image/common/globe.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/image/common/arrowRight.svg';

/**
 * Represents HeaderLanguage
 * @param {function} onMouseEnter - onMouseEnter handler
 * @param {function} onMouseLeave - onMouseLeave handler
 * @param {boolean} isShowButtons - is show buttons with languages
 * @returns {JSX.Element}
 */
const HeaderLanguage = ({ onMouseEnter, onMouseLeave, isShowButtons }) => {
  const {
    settings: { language },
  } = useRootModel();
  return (
    <div
      className={cn(style.buttonsWrapper, { [style.showButtons]: isShowButtons })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={style.languageWrapper}>
        <div className={style.language}>
          <i className={style.icon}>
            <LanguageIcon />
          </i>
          <span>{LANGUAGES[language]}</span>
        </div>

        <i>
          <ArrowIcon />
        </i>
      </div>
    </div>
  );
};

export default observer(HeaderLanguage);
