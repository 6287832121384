import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import style from './Login.module.scss';
import { useRootModel } from '../../models/RootStore';

import Logo from '../../components/ui/Logo/Logo';
import Copyrights from '../../components/ui/Copyrights/Copyrights';
import LoginForm from './LoginForm/LoginForm';
import RegistrationForm from './RegistrationForm/RegistrationForm';

/**
 * Represents a login page.
 * @returns {JSX.Element}
 */
const Login = () => {
  const { t } = useTranslation();
  const { user } = useRootModel();
  const { isAuthorized } = user;
  const { pathname } = useLocation();

  if (user.twoFAEnabled) {
    return <Redirect to='/verification' />;
  }

  if (isAuthorized) {
    return <Redirect to='/' />;
  }

  return (
    <section className={style.wrapper}>
      <div className={style.login}>
        <header className={style.header}>
          <Logo isLarge />
        </header>
        <div className={style.buttonContainer}>
          <Link
            to='/login'
            className={cn(style.loginLink, { [style.active]: pathname === '/login' })}
          >
            {t('login')}
          </Link>
          <Link
            to='/registration'
            className={cn(style.loginLink, { [style.active]: pathname === '/registration' })}
          >
            {t('registration_button')}
          </Link>
        </div>
        {pathname === '/login' && <LoginForm />}
        {pathname === '/registration' && <RegistrationForm />}
      </div>
      <Copyrights />
    </section>
  );
};

export default observer(Login);

// const Login = () => {
//   const { t } = useTranslation();
//   const { isMobile } = useWindowSize();
//   const { user, settings } = useRootModel();
//   const { login, register, getUser, isLoading, isAuthorized } = user;
//   const { setUserDefaultLanguage } = settings;
//   const alert = useContext(AlertContext);
//   const emailRef = useRef(null);
//
//   useEffect(() => {
//     if (!isMobile && emailRef.current) {
//       emailRef.current.focus();
//     }
//   }, []);
//
//   useEffect(() => {
//     const fetchUserData = async () => {
//       await getUser();
//     };
//     if (user.twoFAEnabled !== null) {
//       fetchUserData();
//     }
//   }, [user.twoFAEnabled]);
//
//   const handleLogin = async ({ email, password }) => {
//     try {
//       await login(email.toLowerCase(), password);
//     } catch (e) {
//       alert.show(t(e.message));
//     }
//   };
//
//   const formik = useFormikApp([EMAIL, PASSWORD], handleLogin);
//
//   const handleRegister = async () => {
//     try {
//       const { email, password } = formik.values;
//       await register(email.toLowerCase(), password);
//       await getUser();
//       setUserDefaultLanguage();
//     } catch (e) {
//       alert.show(t(e.message));
//     }
//   };
//
//   if (user.twoFAEnabled) {
//     return <Redirect to='/verification' />;
//   }
//
//   if (isAuthorized) {
//     return <Redirect to='/' />;
//   }
//
//   const handleInputBlur = (e) => {
//     formik.handleBlur(e);
//   };
//
//   const handleChange = async (e) => {
//     if (!/^[A-Za-z0-9.!#$%&'*+/=?^_`{|}~@-]*$/.test(e.target.value)) return;
//     formik.handleChange(e);
//   };
//
//   const isDisabled = !formik.isValid || isLoading;
//   return (
//       <section className={style.wrapper}>
//         <div className={style.login}>
//           <header className={style.header}>
//             <Logo isLarge />
//           </header>
//           <p className={style.title}>{t('start')}</p>
//
//           <form className={style.form} onSubmit={formik.handleSubmit}>
//             <TextInput
//                 id={EMAIL}
//                 label={t('email')}
//                 placeholder={t('enter_email')}
//                 onChange={handleChange}
//                 type='email'
//                 inputMode='email'
//                 inputClassName={style.input}
//                 labelClassName={style.inputLabel}
//                 errorClassName={style.errorLabel}
//                 inputRef={emailRef}
//                 width='full'
//                 maxLength={EMAIL_MAX_LENGTH}
//                 onBlur={handleInputBlur}
//                 value={formik.values[EMAIL]}
//                 error={formik.errors[EMAIL]}
//                 touched={formik.touched[EMAIL]}
//             />
//             <TextInput
//                 id={PASSWORD}
//                 label={t('password')}
//                 placeholder={t('enter_password')}
//                 onChange={handleChange}
//                 type='password'
//                 inputClassName={style.input}
//                 labelClassName={style.inputLabel}
//                 errorClassName={style.errorLabel}
//                 width='full'
//                 maxLength={PASSWORD_MAX_LENGTH}
//                 onBlur={handleInputBlur}
//                 value={formik.values[PASSWORD]}
//                 error={formik.errors[PASSWORD]}
//                 touched={formik.touched[PASSWORD]}
//             />
//
//             <button type='button' className={style.info}>
//               <Link to='/forgot-password'>{t('forgot')}</Link>
//             </button>
//
//             <Button
//                 text={t('login')}
//                 color='primary'
//                 className={style.buttonLogin}
//                 disabled={isDisabled}
//                 submit
//             />
//             <Button
//                 text={t('register')}
//                 color='secondary'
//                 onClick={handleRegister}
//                 disabled={isDisabled}
//             />
//           </form>
//         </div>
//         <Copyrights />
//       </section>
//   );
// };
//
// export default observer(Login);
