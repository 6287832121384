import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../../HeaderUserInfo.module.scss';
import { ReactComponent as Sack } from '../../../../../assets/image/common/sack.svg';
import { useRootModel } from '../../../../../models/RootStore';
import AlertContext from '../../../../../context/alert/alertContext';

/**
 * Represents ButtonUpdateDemo component
 * @returns {JSX.Element}
 */
const ButtonUpdateDemo = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    user: { updateDemoBalance },
  } = useRootModel();
  const handleUpdateBalance = () => {
    try {
      updateDemoBalance();
    } catch (err) {
      alert.show(err.message);
    }
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Sack />
      </i>
      <button type='button' className={style.button} onClick={handleUpdateBalance}>
        {t('update')}
      </button>
    </div>
  );
};

export default ButtonUpdateDemo;
