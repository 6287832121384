import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useRootModel } from '../../../models/RootStore';
import useWindowSize from '../../../hooks/useWindowSize';

import InstrumentsLeftSideBlock from '../InstrumentsLeftSideBlock/InstrumentsLeftSideBlock';
// eslint-disable-next-line max-len
import InstrumentsLeftSideBlockTablet from '../InstrumentsLeftSideBlockTablet/InstrumentsLeftSideBlockTablet';

/**
 * Represents Filters for Main page
 * @param {string} filter - filter for instruments
 * @returns {JSX.Element}
 */
const Filters = ({ filter }) => {
  const { isDesktop } = useWindowSize();
  const { finance } = useRootModel();

  const { getItems, getUpdateData, closeDataStream } = finance;

  useEffect(() => {
    const fetchData = async () => {
      await getItems(filter);
      getUpdateData();
    };
    fetchData();

    return () => {
      closeDataStream();
      // clearData();
    };
  }, [filter]);

  return isDesktop ? <InstrumentsLeftSideBlock /> : <InstrumentsLeftSideBlockTablet />;
};

export default observer(Filters);
