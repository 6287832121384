import { types } from 'mobx-state-tree';
import { values } from 'mobx';

const ModalOptionModel = types
  .model('ModalOptionModel', {
    isOpen: types.optional(types.boolean, false),
  })
  .actions((option) => ({
    setIsOpen(open) {
      option.isOpen = open;
    },
    open() {
      option.isOpen = true;
    },
    close() {
      option.isOpen = false;
    },
    toggle() {
      option.isOpen = !option.isOpen;
    },
  }));

const ModalModel = types
  .model('ModalModel', {
    activeTrade: types.optional(ModalOptionModel, {}),
    historyTrade: types.optional(ModalOptionModel, {}),
    increaseTrade: types.optional(ModalOptionModel, {}),
    increaseTradeMobile: types.optional(ModalOptionModel, {}),
    openTrade: types.optional(ModalOptionModel, {}),
    deposit: types.optional(ModalOptionModel, {}),
    closeTrade: types.optional(ModalOptionModel, {}),
    closeTradeMobile: types.optional(ModalOptionModel, {}),
    cancelTrade: types.optional(ModalOptionModel, {}),
    currentBalance: types.optional(ModalOptionModel, {}),
    search: types.optional(ModalOptionModel, {}),
    notifications: types.optional(ModalOptionModel, {}),
    chatPicturePreview: types.optional(ModalOptionModel, {}),
  })
  .views((modal) => ({
    get isOpen() {
      return values(modal).some((m) => m.isOpen);
    },
  }));

export default ModalModel;
