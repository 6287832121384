import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './AllInstrumentsDropdownMobile.module.scss';
import { ReactComponent as Favorites } from '../../../../../assets/image/common/favorites.svg';
import i18n from '../../../../../translations/i18n';
import { ReactComponent as Crypto } from '../../../../../assets/image/common/cryptocurrencies.svg';
import { ReactComponent as Indices } from '../../../../../assets/image/common/indices.svg';
import { ReactComponent as Oil } from '../../../../../assets/image/common/oil.svg';
import { ReactComponent as Metals } from '../../../../../assets/image/common/metals.svg';
import { ReactComponent as Shares } from '../../../../../assets/image/common/shares.svg';
import { ReactComponent as Currencies } from '../../../../../assets/image/common/currencies.svg';
import { ReactComponent as Agriculture } from '../../../../../assets/image/common/agriculture.svg';
import { ReactComponent as Etf } from '../../../../../assets/image/common/etf.svg';
import { ReactComponent as ArrowUp } from '../../../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../../../assets/image/common/arrowDown.svg';
import useOnClick from '../../../../../hooks/useOnClick';

const links = [
  { id: 1, to: '/favorites', icon: Favorites, title: i18n.t('favorites') },
  { id: 2, to: '/products/crypto', icon: Crypto, title: i18n.t('crypto') },
  { id: 3, to: '/products/index', icon: Indices, title: i18n.t('indices') },
  { id: 4, to: '/products/oil-gas', icon: Oil, title: i18n.t('oil') },
  { id: 5, to: '/products/metal', icon: Metals, title: i18n.t('metals') },
  { id: 6, to: '/products/stock', icon: Shares, title: i18n.t('shares') },
  { id: 7, to: '/products/forex', icon: Currencies, title: i18n.t('currencies') },
  { id: 8, to: '/products/agriculture', icon: Agriculture, title: i18n.t('agriculture') },
  { id: 9, to: '/products/etf', icon: Etf, title: i18n.t('etfs') },
];

/**
 * Represents All Instruments Dropdown component on Main page in Mobile version
 * @returns {JSX.Element}
 */

const AllInstrumentsDropdownMobile = () => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const ref = useRef();

  const handleOnClick = () => {
    setShowContent(!showContent);
  };

  useOnClick(ref, () => setShowContent(false));

  return (
    <div className={style.allInstrumentsContainer} ref={ref}>
      <div
        className={cn(style.titleButton, { [style.active]: showContent })}
        role='button'
        tabIndex={-1}
        onClick={handleOnClick}
        onKeyDown={handleOnClick}
      >
        {t('all_instruments')}
        {showContent ? <ArrowUp /> : <ArrowDown />}
      </div>
      <CSSTransition
        in={showContent}
        timeout={700}
        classNames={{
          enter: style.enter,
          enterActive: style.enterActive,
          enterDone: style.enterDone,
          exit: style.exit,
          exitActive: style.exitActive,
          exitDone: style.exitDone,
        }}
        unmountOnExit
      >
        <div className={style.content}>
          {links.map(({ id, to, icon: Icon, title }) => (
            <Link className={style.productsLink} to={to} key={id}>
              <Icon />
              <p className={style.title}>{t(title)}</p>
            </Link>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default AllInstrumentsDropdownMobile;
