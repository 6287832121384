import React from 'react';
import cn from 'classnames';

import style from './TabsScroll.module.scss';
import TabsScrollLinkItem from './TabsScrollLinkItem/TabsScrollLinkItem';

/**
 * Represents tabs component
 * @param {Object} tabs - tabs
 * @param {CSSStyleRule} className - className
 * @returns {JSX.Element}
 */
const TabsScrollLink = ({ tabs, className }) => {
  return (
    <div className={style.listContainer}>
      <ul className={cn(style.list, className)}>
        {tabs.map(({ tabLabel, id, url }) => {
          return <TabsScrollLinkItem key={id} tabLabel={tabLabel} url={url} />;
        })}
      </ul>
    </div>
  );
};

export default TabsScrollLink;
