import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import style from './MobileApp.module.scss';

import { useRootModel } from '../models/RootStore';

import MainPage from './pages/MainPage/MainPage';
import TradesPage from './pages/TradesPage/TradesPage';
import MarketsPage from './pages/MarketsPage/MarketsPage';
import BalancePage from './pages/BalancePage/BalancePage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import HeaderMobile from './components/HeaderMobile/HeaderMobile';
import NavigationMobile from './components/NavigationMobile/NavigationMobile';
import FavoritesMobile from './pages/FavoritesMobile/FavoritesMobile';
import DepositMoneyMobile from './pages/BalancePage/DepositMoneyMobile/DepositMoneyMobile';
import WithdrawMoneyMobile from './pages/BalancePage/WithdrawMoneyMobile/WithdrawMoneyMobile';
import PaymentHistoryMobile from './pages/BalancePage/PaymentHistoryMobile/PaymentHistoryMobile';
// eslint-disable-next-line max-len
import GeneralInformationMobile from './pages/ProfilePage/GeneralInformationMobile/GeneralInformationMobile';
import PersonalDetailsMobile from './pages/ProfilePage/PersonalDetailsMobile/PersonalDetailsMobile';
import ActiveSessions from './pages/ProfilePage/ActiveSessions/ActiveSessions';
import SecurityMobile from './pages/ProfilePage/SecurityMobile/SecurityMobile';
import NotificationsMobile from './pages/ProfilePage/NotificationsMobile/NotificationsMobile';
import DocumentsMobile from './pages/ProfilePage/DocumentsMobile/DocumentsMobile';
import OtherSettingsMobile from './pages/ProfilePage/OtherSettingsMobile/OtherSettingsMobile';
import SupportMobile from './pages/ProfilePage/SupportMobile/SupportMobile';
import RequestPage from './pages/ProfilePage/RequestPage/RequestPage';
import StatusInfoMobile from './pages/ProfilePage/StatusInfoMobile/StatusInfoMobile';
import NotFound from '../pages/NotFound/NotFound';
import ScrollToTop from '../hooks/useScrollToTop';
import FooterInfoMobile from './pages/FooterInfoMobile/FooterInfoMobile';
import FooterMobile from './components/FooterMobile/FooterMobile';
import ExchangeSchedule from '../pages/ExchangeSchedule/ExchangeSchedule';

/**
 * Represents Mobile version of Application
 * @returns {JSX.Element}
 */

const MobileApp = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    user: { isAuthorized },
  } = useRootModel();

  if (!isAuthorized) {
    history.push('/login');
  }
  return (
    <div>
      <ScrollToTop />
      <HeaderMobile />
      <main className={style.main}>
        <Switch>
          <Route exact path='/' component={MainPage} />
          <Route exact path='/trades/:type' component={TradesPage} />
          <Route exact path='/products/:category/:s?' component={MarketsPage} />
          <Route exact path='/balance' component={BalancePage} />
          <Route exact path='/profile' component={ProfilePage} />
          <Route exact path='/favorites' component={FavoritesMobile} />
          <Route exact path='/balance/deposit' component={DepositMoneyMobile} />
          <Route exact path='/balance/withdraw' component={WithdrawMoneyMobile} />
          <Route exact path='/balance/payment' component={PaymentHistoryMobile} />
          <Route exact path='/profile/general' component={GeneralInformationMobile} />
          <Route exact path='/profile/details' component={PersonalDetailsMobile} />
          <Route exact path='/profile/sessions' component={ActiveSessions} />
          <Route exact path='/profile/security' component={SecurityMobile} />
          <Route exact path='/profile/notification' component={NotificationsMobile} />
          <Route exact path='/profile/documents' component={DocumentsMobile} />
          <Route exact path='/profile/settings' component={OtherSettingsMobile} />
          <Route exact path='/profile/support' component={SupportMobile} />
          <Route exact path='/profile/request' component={RequestPage} />
          <Route exact path='/info/:type' component={FooterInfoMobile} />
          <Route exact path='/profile/status' component={StatusInfoMobile} />
          <Route exact path='/404' component={NotFound} />
          <Route exact path='/schedule' component={ExchangeSchedule} />
          <Redirect to='/404' />
        </Switch>
      </main>
      {pathname === '/' && <FooterMobile />}
      <NavigationMobile />
    </div>
  );
};

export default MobileApp;
