import React from 'react';
import { useLocation } from 'react-router-dom';
import i18n from '../../../translations/i18n';

import style from './HeaderPageTitle.module.scss';

import AllInstrumentsDropdown from '../AllInstrumentsDropdown/AllInstrumentsDropdown';

const routeTitle = {
  balance: i18n.t('balance'),
  profile: i18n.t('profile'),
  support: i18n.t('faq'),
  404: i18n.t('404'),
};

/**
 * Represents HeaderPageTitle
 * @returns {JSX.Element}
 */
const HeaderPageTitle = () => {
  const { pathname } = useLocation();
  const routeName = pathname.slice(1);
  return (
    <div className={style.pageTitle}>{routeTitle[routeName] || <AllInstrumentsDropdown />}</div>
  );
};

export default HeaderPageTitle;
