import React, { useState } from 'react';
import i18n from '../../translations/i18n';

import TabsScroll from '../../components/ui/TabsScroll/TabsScroll';
import Filters from './Filters/Filters';
import InstrumentDetails from './InstrumentDetails/InstrumentDetails';
import style from './Main.module.scss';

const tabs = [
  {
    id: 0,
    tabLabel: i18n.t('popular'),
    filter: 'popular',
  },
  {
    id: 1,
    tabLabel: i18n.t('crypto'),
    filter: 'crypto',
  },
  {
    id: 2,
    tabLabel: i18n.t('rising'),
    filter: 'rising',
  },
  {
    id: 3,
    tabLabel: i18n.t('falling'),
    filter: 'falling',
  },
  {
    id: 4,
    tabLabel: i18n.t('volatility'),
    filter: 'volatility',
  },
  {
    id: 5,
    tabLabel: i18n.t('uprising_trend'),
    filter: 'uptrend',
  },
  {
    id: 6,
    tabLabel: i18n.t('falling_trend'),
    filter: 'falltrend',
  },
  {
    id: 7,
    tabLabel: i18n.t('commodities'),
    filter: 'commodities',
  },
];

/**
 * Represents Main page
 * @returns {JSX.Element}
 */
const Main = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (id) => {
    setActiveTab(id);
  };

  return (
    <section>
      <TabsScroll tabs={tabs} onChange={handleChangeTab} activeTab={activeTab} />
      <div className={style.filters}>
        <Filters filter={tabs[activeTab].filter} />
        <InstrumentDetails />
      </div>
    </section>
  );
};

export default Main;
