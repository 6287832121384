import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { observer } from 'mobx-react';

import style from './InstrumentInfo.module.scss';
import string from '../../../util/string';
import { useRootModel } from '../../../models/RootStore';
import instrumentIcon from '../../../assets/instrumentIcon';

import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import TitleNumberFormat from '../../../components/ui/TitleNumberFormat/TitleNumberFormat';
import FavoritesButton from '../../../components/ui/FavoritesButton/FavoritesButton';

/** Represents InstrumentInfo component
 * @returns {JSX.Element}
 */
const InstrumentInfo = () => {
  const { t } = useTranslation();
  const {
    user: { isAuthorized },
    finance: { item, activeSymbol },
  } = useRootModel();

  const { alias, lp, oldPrice, h, l, cpd, type, s, favorite, toggleFavorite } = item;

  const instType = instrumentIcon[type];
  const Icon = instType ? instType[s] : null;

  return (
    <ErrorBoundary className={style.error}>
      <div className={style.priceBlock}>
        {isAuthorized && (
          <FavoritesButton
            className={style.favoritesButton}
            onClick={toggleFavorite}
            isFavorite={favorite}
            isLoading={item.isLoading}
          />
        )}
        <i className={style.icon}>{Icon && <Icon />}</i>
        <div className={style.gridColumn}>
          <p>{activeSymbol}</p>
          <p className={style.text}>{alias}</p>
        </div>

        <div className={cn(style.gridColumn, style.price)}>
          <p
            className={cn(style.priceValue, {
              [style.backgroundRed]: lp < oldPrice,
              [style.backgroundGreen]: lp > oldPrice,
            })}
          >
            {string.formatNumber(lp)}
          </p>
          <p className={style.text}>{t('price')}</p>
        </div>

        <hr className={style.line} />

        <div className={cn(style.gridColumn, style.marginRight)}>
          <TitleNumberFormat title={t('high')} value={h} />
          <TitleNumberFormat title={t('low')} value={l} />
        </div>

        <div className={style.gridColumn}>
          <p className={cn(style.colorGreen, { [style.colorRed]: cpd < 0 })}>
            {string.formatPercent(cpd, { withSign: true })}
          </p>
          <p className={style.text}>{t('for_day')}</p>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default observer(InstrumentInfo);
