import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './SupportMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import FaqItem from '../../../../pages/Support/FAQItem/FAQItem';

/**
 * Represents Support component in Mobile version
 * @returns {JSX.Element}
 */

const SupportMobile = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      title: t('how_reset'),
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
    },
    {
      id: 2,
      title: t('after_change'),
      text:
        'If you change your password, you’ll be signed out everywhere except:\n' +
        "•‎ Devices you use to verify that it's you when you sign in.\n" +
        "•‎ Devices with third-party apps that you've given account access.",
    },
    {
      id: 3,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
    {
      id: 4,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
    {
      id: 5,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
    {
      id: 6,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/profile/settings' />
        <p className={style.title}>{t('support')}</p>
      </div>
      {items.map(({ id, title, text }) => (
        <FaqItem key={id} title={title} text={text} />
      ))}
      <p className={style.request}>{t('cant_find_answer')}</p>
      <Link to='/profile/request' className={style.link}>
        {t('submit_request')}
      </Link>
    </div>
  );
};

export default SupportMobile;
