import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import useWindowSize from '../../../../hooks/useWindowSize';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import OpenTradeContent from './OpenTradeContent/OpenTradeContent';

/**
 * Represents OpenTradeModal component
 * @returns {JSX.Element}
 */
const OpenTradeModal = () => {
  const { isLaptop } = useWindowSize();
  const { t } = useTranslation();

  const {
    modal: {
      openTrade: { isOpen, close },
    },
  } = useRootModel();
  return (
    <>
      {isOpen && (
        <Modal
          wrapperClassname={style.modalWrapper}
          handleClose={close}
          header={isLaptop}
          headerTitle={t('open')}
        >
          <ModalBody className={style.modal}>
            <OpenTradeContent handleClose={close} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(OpenTradeModal);
