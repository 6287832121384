import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

import style from './FavoritesMobileItem.module.scss';
import FavoritesButton from '../../../../components/ui/FavoritesButton/FavoritesButton';
import string from '../../../../util/string';
import { ReactComponent as ArrowRight } from '../../../../assets/image/common/arrowNextPage.svg';

/**
 * Represents Instrument item in Favorites on Mobile version
 * @param {JSX.Element} Icon - instrument icon
 * @param {string} alias - instrument title
 * @param {string} symbol - instrument code
 * @param {string} price - instrument price
 * @param {string} percent - instrument percent
 * @param {boolean} isFavorite - instrument favorite state
 * @param {function} toggleFavorite - onClick function
 * @param {boolean} isLoading - is loading state
 * @returns {JSX.Element}
 */
const FavoritesMobileItem = ({
  icon: Icon,
  alias,
  symbol,
  price,
  percent,
  isFavorite,
  toggleFavorite,
  isLoading,
}) => {
  return (
    <Link className={style.columnMobile} to={`products/crypto/${symbol}`}>
      <div className={style.instrumentWrapper}>
        <FavoritesButton onClick={toggleFavorite} isFavorite={isFavorite} isLoading={isLoading} />
        <i className={style.instrumentIcon}>{Icon ? <Icon /> : null}</i>
        <p className={style.title}>{alias}</p>
      </div>
      <div className={style.priceBlock}>
        <div>
          <p className={style.price}>{string.formatNumber(price)}</p>
          <p className={cn(style.percent, { [style.priceUp]: percent > 0 })}>
            {numeral(percent).format('0.00')}%
          </p>
        </div>
        <ArrowRight />
      </div>
    </Link>
  );
};

export default FavoritesMobileItem;
