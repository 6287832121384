import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './PendingRightSide.module.scss';
import { useRootModel } from '../../../models/RootStore';

import ButtonWithArrows from '../../ui/ButtonWithArrows/ButtonWithArrows';
import PendingRightSideContent from './PendingRightSideContent/PendingRightSideContent';
import Badge from '../../ui/Badge/Badge';

/**
 * Represents a Pending orders block on right panel on Main Page.
 * @returns {JSX.Element}
 */
const PendingRightSide = () => {
  const { t } = useTranslation();
  const {
    finance: {
      pendingTrades: { getPendingTrades, allTradesAmount, items },
    },
  } = useRootModel();
  const [openedList, setOpenedList] = useState(true);

  useEffect(() => {
    getPendingTrades();
  }, []);

  const handleToggle = () => {
    setOpenedList(!openedList);
  };

  const isTrades = !!allTradesAmount;
  return (
    items.length > 0 && (
      <div className={style.pendingOrders}>
        <div className={style.titleWrapper}>
          <ButtonWithArrows
            text={t('pending_orders')}
            handleToggle={handleToggle}
            openedList={openedList}
          />
          {isTrades && <Badge amount={allTradesAmount} className={style.notifications} />}
        </div>
        {openedList && <PendingRightSideContent />}
      </div>
    )
  );
};

export default observer(PendingRightSide);
