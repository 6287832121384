import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import style from './FlowOfFundsMobile.module.scss';
import FlowOfFundsMobileItem from './FlowOfFundsMobileItem/FlowOfFundsMobileItem';
import { useRootModel } from '../../../../models/RootStore';
import NotFoundMobile from '../../../components/NotFoundMobile/NotFoundMobile';
import Preloader from '../../../../components/ui/Preloader/Preloader';

/**
 * Represents Flow of Funds component in Mobile version
 * @returns {JSX.Element}
 */

const FlowOfFundsMobile = () => {
  const {
    finance: {
      flowOfFunds: { items, getFlowOfFunds, isLoading },
    },
  } = useRootModel();

  useEffect(() => {
    getFlowOfFunds();
  }, []);

  if (isLoading)
    return (
      <div className={style.preloader}>
        <Preloader />
      </div>
    );

  return (
    <>
      {items?.length > 0 ? (
        <div className={style.content}>
          {items.map(({ amount, balance, date, operation, tradeCodeId }, i) => (
            <FlowOfFundsMobileItem
              key={`${date + i}`}
              date={date}
              operation={operation}
              title={tradeCodeId}
              balance={balance}
              amount={amount}
            />
          ))}
        </div>
      ) : (
        <NotFoundMobile />
      )}
    </>
  );
};

export default observer(FlowOfFundsMobile);
