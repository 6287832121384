import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import style from './DepositMoney.module.scss';
import useWindowSize from '../../hooks/useWindowSize';

import { ReactComponent as Copy } from '../../assets/image/common/copy.svg';
import CurrencyInput from '../CurrencyInput/CurrencyInput';

/**
 * Represents Deposit money component
 * @returns {JSX.Element}
 * @param {CSSStyleRule} className - className for QrBlock
 */

const DepositMoney = ({ className }) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const [walletNumber] = useState('bc1qf5zp3kg9cr8g7ftuwm546cj5hzy746tqr73taa');
  const [isCopy, setIsCopy] = useState(false);
  const inputAreaRef = useRef(null);

  const copyCodeToClipboard = async () => {
    await navigator.clipboard.writeText(walletNumber);
    setIsCopy(true);

    setTimeout(() => setIsCopy(false), 3000);
  };

  return (
    <form className={style.depositForm}>
      <div className={style.inputWrapper}>
        <CurrencyInput />
        <div>
          <p className={style.textLabel}>{t('comission')}</p>
          <p className={style.commissionInput}>5%</p>
        </div>
      </div>
      <div className={style.walletBlock}>
        {isMobile ? (
          <p className={style.textLabel}>BTC address</p>
        ) : (
          <p className={style.textLabel}>Your BTC deposit address</p>
        )}
        <div>
          <div className={style.depositInput}>
            <input
              className={style.wallet}
              type='text'
              value={walletNumber}
              readOnly
              ref={inputAreaRef}
            />
            {!isMobile && (
              <button type='button' className={style.copyButton} onClick={copyCodeToClipboard}>
                <Copy className={cn(style.iconCopy, { [style.iconCopyDone]: isCopy })} />
              </button>
            )}
          </div>
          <p className={style.tip}>{isCopy && t('copied')}</p>
        </div>
      </div>
      <div className={cn(style.qrBlock, className)}>
        <QRCode size={115} value={walletNumber} />
        <p className={style.qrText}>Only send BTC to this address, 3 confirmation(s) required.</p>
      </div>
    </form>
  );
};

export default observer(DepositMoney);
