import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './Copyrights.module.scss';

const Copyrights = () => {
  const { t } = useTranslation();
  return <p className={style.rights}>&copy; 2021 i88.io. {t('rights')}</p>;
};

export default Copyrights;
