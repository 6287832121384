import React from 'react';
import { types } from 'mobx-state-tree';
import AuthUserModel from './AuthUserModel';
import SettingsModel from './SettingsModel/SettingsModel';
import FinanceServiceModel from './FinanceServiceModel';
import ModalModel from './ModalModel';
import SearchModel from './SearchModel';
import ChatModel from './ChatModel/ChatModel';
import MarketModel from './MarketModel/MarketModel';

/**
 * @typedef {Object} RootStore
 * @property {AuthUserModel} user
 * @property {SettingsModel} settings
 * @property {Object} finance
 * @property {Object} modal
 * @property {Object} search
 * @property {Object} chat
 */
const RootStore = types.model('RootStore', {
  user: types.optional(AuthUserModel, {}),
  settings: types.optional(SettingsModel, {}),
  finance: types.optional(FinanceServiceModel, {}),
  modal: types.optional(ModalModel, {}),
  search: types.optional(SearchModel, {}),
  chat: types.optional(ChatModel, {}),
  market: types.optional(MarketModel, {}),
});

const initialState = RootStore.create({});

export const rootStore = initialState;

/**
 * RootStore context
 * @type {React.Context<RootStore | null>}
 */
export const RootStoreContext = React.createContext(null);

export function useRootModel() {
  const store = React.useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
