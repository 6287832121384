import React from 'react';
import { observer } from 'mobx-react';

import { useRootModel } from '../../../../../../models/RootStore';

import LimitProfitsAndLost from '../../../../../LimitProfitsAndLost/LimitProfitsAndLost';
import GeneralInformationTop from './GeneralInformationTop/GeneralInformationTop';

/**
 * Represents General Information tab in Active Trades content
 * @returns {JSX.Element}
 */
const GeneralInformation = () => {
  const {
    finance: {
      activeTrades: { activeTrade },
    },
  } = useRootModel();

  const { amount } = activeTrade;

  return (
    <div>
      <GeneralInformationTop />
      <LimitProfitsAndLost isButton amount={amount} />
    </div>
  );
};

export default observer(GeneralInformation);
