import React from 'react';
import style from './DropdownAndSearchBlock.module.scss';
import { ReactComponent as Search } from '../../../../assets/image/common/searchOnMain.svg';
import { useRootModel } from '../../../../models/RootStore';
// eslint-disable-next-line max-len
import AllInstrumentsDropdownMobile from './AllInstrumentsDropdownMobile/AllInstrumentsDropdownMobile';

/**
 * Represents Dropdown and Search block component in Mobile version
 * @returns {JSX.Element}
 */

const DropdownAndSearchBlock = () => {
  const {
    modal: { search },
  } = useRootModel();

  return (
    <div className={style.dropdownAndSearchBlock}>
      <AllInstrumentsDropdownMobile />
      <button type='button' className={style.transparentButton} onClick={search.open}>
        <Search />
      </button>
    </div>
  );
};

export default DropdownAndSearchBlock;
