import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../GeneralPhone.module.scss';
import emptyFunction from '../../../../../util/emptyFunction';
import useFormikApp from '../../../../../hooks/useFormikApp';
import formikUtil from '../../../../../util/formikUtil';
import { CODE_LENGTH, SMS_CODE } from '../../../../../util/validationSchema';
import useSendCodeState from '../../../../../hooks/useSendCodeState';
import { useRootModel } from '../../../../../models/RootStore';
import {
  ENTER_CODE_RESTORE_SECONDS,
  GENERAL_SEND_CODE_RESTORE_SECONDS,
} from '../../../../../constant/commonConstants';
import AlertContext from '../../../../../context/alert/alertContext';

import TextInput from '../../../../../components/ui/TextInput/TextInput';
import Button from '../../../../../components/ui/Button/Button';
import ButtonCountdown from '../../../../../components/ui/ButtonCountdown/ButtonCountdown';
import TimeCountdown from '../../../../../components/TimeCountdown/TimeCountdown';

/**
 * Represents phone verified component for GeneralInformation
 * @param {function} sendCode - handleSendCode
 * @param {function} setIsPhoneChanged - setIsPhoneChanged
 * @param {function} setIsVerifyCodeSent - setIsVerifyCodeSent
 * @returns {JSX.Element}
 */
const GeneralPhoneVerify = ({ sendCode, setIsPhoneChanged, setIsVerifyCodeSent }) => {
  const {
    setSecondsRestoreSendCodeGeneral,
    secondsRestoreSendCodeGeneral,
    secondsRestoreEnterCode,
    setSecondsRestoreEnterCode,
  } = useSendCodeState();

  const alert = useContext(AlertContext);
  const { t } = useTranslation();
  const {
    user: { verifyPhoneConfirm },
  } = useRootModel();

  const formik = useFormikApp([SMS_CODE], emptyFunction);

  const onSubmit = async () => {
    try {
      await verifyPhoneConfirm(formik.values[SMS_CODE]);

      setIsPhoneChanged(false);
      setIsVerifyCodeSent(false);
      alert.show(t('successful'), 'success');
    } catch (e) {
      alert.show(t(e.message));
    } finally {
      setSecondsRestoreEnterCode(ENTER_CODE_RESTORE_SECONDS);
    }
  };

  const handleChangeCode = (e) => {
    formikUtil.checkByCode(e, formik);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') onSubmit();
  };

  const handleSendCode = () => {
    try {
      sendCode();
      setSecondsRestoreSendCodeGeneral(GENERAL_SEND_CODE_RESTORE_SECONDS);
    } catch (e) {
      alert.show(e.message);
    }
  };

  return (
    <>
      <div className={style.code}>
        <p className={style.text}>
          {t('sms_sent')}
          <br />
          {t('you_will_receive_sms')}
        </p>

        <ButtonCountdown
          text={`${t('resend')}${secondsRestoreSendCodeGeneral > 0 ? ` ${t('in')}` : ''}`}
          onClick={handleSendCode}
          seconds={secondsRestoreSendCodeGeneral}
          handleChangeSeconds={setSecondsRestoreSendCodeGeneral}
          buttonClassName={style.buttonCountdown}
        />

        <div className={style.codeInputWrapper}>
          <p className={style.codeLabel}>{t('sms')}</p>
          <TextInput
            id={SMS_CODE}
            placeholder={t('code')}
            onKeyDown={handleKeyDown}
            onChange={handleChangeCode}
            formik={formik}
            inputMode='numeric'
            maxLength={CODE_LENGTH}
            autoComplete='nope'
            rightIcon={
              secondsRestoreEnterCode
                ? () => (
                    <TimeCountdown
                      seconds={secondsRestoreEnterCode}
                      onChange={setSecondsRestoreEnterCode}
                    />
                  )
                : null
            }
            rightIconClassName={style.time}
          />
        </div>
      </div>

      <Button
        size='small'
        text={t('verify_phone')}
        onClick={onSubmit}
        marginBottom
        disabled={!formik.isValid || secondsRestoreEnterCode}
        width='full'
      />
    </>
  );
};

export default GeneralPhoneVerify;
