import React, { useState } from 'react';
import cn from 'classnames';
import style from './TabsForModal.module.scss';
import TabsForModalContent from './TabsForModalContent/TabsForModalContent';
import TabsForModalItem from './TabsForModalItem/TabsForModalItem';

/**
 * @typedef {Object} TabComponent
 * @property {number} id - tab id
 * @property {string} tabLabel - tab label
 * @property {JSX.Element} component - Component for render
 */

/**
 * Represents tabs for modal component
 * @param {Array<TabComponent>} tabs - array of tabs - [{ id, tabLabel, component }]
 * @param {CSSStyleRule} className - className for wrapper
 * @param {CSSStyleRule} itemClassName - className for item
 * @param {CSSStyleRule} textClassName - className for text
 * @returns {JSX.Element}
 */

const TabsForModal = ({ tabs, className, itemClassName, textClassName }) => {
  const [activeTab, setActiveTab] = useState(1);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  const activeItem = tabs?.find(({ id }) => id === activeTab);
  return (
    <article className={style.tabs}>
      <div className={cn(style.buttonsAndTitleWrapper, className)}>
        <ul className={style.tabList}>
          {tabs.map(({ tabLabel, id }) => (
            <li key={id}>
              <TabsForModalItem
                id={id}
                activeTab={activeTab}
                label={tabLabel}
                onClick={onClickTabItem}
                itemClassName={itemClassName}
                textClassName={textClassName}
              />
            </li>
          ))}
        </ul>
      </div>
      <TabsForModalContent component={activeItem?.component} />
    </article>
  );
};

export default TabsForModal;
