import axios from 'axios';
import i18n from '../translations/i18n';
import { COLOR, DEFAULT_INTERVAL, DEFAULT_THEME, THEME_LIGHT } from '../constant/commonConstants';
import OpenTradeIcon from '../assets/image/common/openTradeIcon.svg';
import error from './error';

const FullscreenIcon = `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path class="fullscreen-arrows" d="M4.48787 0.512132C4.67686 0.323142 4.54301 0 4.27574
0H0.3C0.134315 0 0 0.134315 0 0.3V4.27574C0 4.54301 0.323143
4.67686 0.512132 4.48787L1.8 3.2L4.28794 5.78008C4.40443 5.90089
4.59736 5.90264 4.71602 5.78398L5.78398 4.71602C5.90264 4.59736
5.90089 4.40443 5.78008 4.28794L3.2 1.8L4.48787 0.512132ZM11.7243
0C11.457 0 11.3231 0.323143 11.5121 0.512132L12.8 1.8L10.2199
4.28794C10.0991 4.40443 10.0974 4.59736 10.216 4.71602L11.2879
5.78787C11.405 5.90503 11.595 5.90503 11.7121 5.78787L14.2 3.3L15.494
4.52212C15.6853 4.70281 16 4.56718 16 4.30402V0.3C16 0.134315 15.8657
0 15.7 0H11.7243ZM11.7121 10.2199C11.5956 10.0991 11.4026 10.0974
11.284 10.216L10.2121 11.2879C10.095 11.405 10.095 11.595 10.2121
11.7121L12.7 14.2L11.4779 15.494C11.2972 15.6853 11.4328 16 11.696
16H15.7C15.8657 16 16 15.8657 16 15.7V11.7243C16 11.457 15.6769 11.3231
15.4879 11.5121L14.2 12.8L11.7121 10.2199ZM4.71213 10.2121C4.59497 10.095
4.40503 10.095 4.28787 10.2121L1.8 12.7L0.505987 11.4779C0.314667
11.2972 0 11.4328 0 11.696V15.7C0 15.8657 0.134315 16 0.3 16H4.27574C4.54301 16 4.67686
15.6769 4.48787 15.4879L3.2 14.2L5.78008 11.7121C5.90089 11.5956 5.90264 11.4026 5.78398
11.284L4.71213 10.2121Z" />
</svg>
`;

const createOpenTradesButton = (tvWid, callback) => {
  const button = tvWid.createButton({ align: 'right' });
  const text = i18n.t('open_trade_position');
  const img = document.createElement('img');
  img.src = OpenTradeIcon;
  button.append(img);
  button.append(text);

  button.style.backgroundColor = COLOR.primary;
  button.style.color = COLOR.white;
  button.style.display = 'flex';
  button.style.gridGap = '0.5rem';
  button.style.padding = '0.5rem';
  button.style.borderRadius = '3px';
  button.style.marginRight = '0.5rem';
  button.style.marginLeft = '0.5rem';
  button.style.cursor = 'pointer';
  button.id = 'open-trade-button';

  button.addEventListener('click', callback);

  return button;
};

const createFullscreenButton = (tvWid, callback) => {
  const button = tvWid.createButton({ align: 'right' });
  button.classList.add('fullscreen-button');
  button.innerHTML = FullscreenIcon;
  button.addEventListener('click', callback);
  return button;
};

const getLayouts = async (userId, symbol) => {
  try {
    return (
      await axios.get(`/services/trading/api/users/${userId}/layouts`, {
        params: { symbolCode: symbol },
      })
    ).data;
  } catch (err) {
    throw new Error(err.message);
  }
};

const setActiveSymbol = async (symbol, interval, theme, userId, isMobile) => {
  try {
    const { tvWidget } = window;
    if (tvWidget && userId && !isMobile) {
      const data = await getLayouts(userId, symbol);
      const {
        // eslint-disable-next-line camelcase
        _options: { save_load_adapter },
      } = tvWidget;
      save_load_adapter.charts = data;

      await tvWidget?.getSavedCharts(async (records) => {
        if (records.length) {
          tvWidget.loadChartFromServer(records[0]);
          if (theme === THEME_LIGHT) {
            tvWidget.changeTheme('Light', { disableUndo: true });
          }
        } else {
          await tvWidget.setSymbol(symbol, interval);
        }
      });
      return;
    }
    await tvWidget?.setSymbol(symbol, interval);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err.message);
    const message = err.response?.data.errorCode || err.message;
    error.errorHandler(message);
    // throw new Error(message);
  }
};

const changeSymbol = async (
  activeSymbol,
  activeInterval = DEFAULT_INTERVAL,
  theme = DEFAULT_THEME,
  userId = '',
  isMobile,
) => {
  await setActiveSymbol(activeSymbol, activeInterval, theme, userId, isMobile);
};

const saveLayout = async (userId, data) => {
  try {
    return (await axios.post(`/services/trading/api/users/${userId}/layouts`, data)).data;
  } catch (err) {
    throw new Error(err.message);
  }
};

const removeLayout = async (userId, layoutId) => {
  try {
    await axios.delete(`/services/trading/api/users/${userId}/layouts/${layoutId}`);
  } catch (err) {
    throw new Error(err.message);
  }
};

const changeLayout = async (userId, layoutId, chartData) => {
  try {
    await axios.put(`/services/trading/api/users/${userId}/layouts/${layoutId}`, chartData);
  } catch (err) {
    throw new Error(err.message);
  }
};

const getTemplates = async (userId) => {
  if (!userId) return [];
  try {
    const { data } = await axios.get(`/services/trading/api/users/${userId}/templates`);
    return data.map(({ content }) => ({ ...JSON.parse(content) }));
  } catch (err) {
    throw new Error(err.message);
  }
};

const saveTemplate = async (userId, name, content) => {
  try {
    await axios.post(`/services/trading/api/users/${userId}/templates`, { name, content });
  } catch (err) {
    throw new Error(err.message);
  }
};

const removeTemplate = async (userId, name) => {
  try {
    await axios.delete(`/services/trading/api/users/${userId}/templates/${name}`);
  } catch (err) {
    throw new Error(err.message);
  }
};

export default {
  createOpenTradesButton,
  createFullscreenButton,
  getLayouts,
  saveLayout,
  removeLayout,
  changeLayout,
  setActiveSymbol,
  changeSymbol,
  getTemplates,
  saveTemplate,
  removeTemplate,
};
