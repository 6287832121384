import React, { lazy, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import './SVGColor.scss';
import style from './App.module.scss';

import withSuspense from './HOC/withSuspense';
import { useRootModel } from './models/RootStore';

import AlertState from './context/alert/AlertState';
import Preloader from './components/ui/Preloader/Preloader';
import Alert from './components/ui/Alert/Alert';
import AppModals from './components/AppModals/AppModals';

import MainApp from './MainApp/MainApp';
import Login from './pages/Login/Login';
const Verification = lazy(() => import('./pages/Verification/Verification'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));

/**
 * App component
 * @returns {JSX.Element}
 */

const App = () => {
  const [initialization, setInitialization] = useState(true);

  const {
    user: { getUser },
  } = useRootModel();

  useEffect(() => {
    const fetchData = async () => {
      await getUser();
      setInitialization(false);
    };
    fetchData();
  }, []);

  if (initialization)
    return (
      <div className={style.fullscreen}>
        <Preloader />
      </div>
    );

  return (
    <AlertState>
      <AppModals />
      <Alert />
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/registration' component={Login} />
        <Route exact path='/verification' component={withSuspense(Verification)} />
        <Route exact path='/forgot-password' component={withSuspense(ForgotPassword)} />
        <Route exact path='/reset-password/:resetKey' component={withSuspense(ResetPassword)} />
        <Route component={MainApp} />
      </Switch>
    </AlertState>
  );
};

export default observer(App);
