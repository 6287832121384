import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './WithdrawMoneyMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import WithdrawMoney from '../../../../components/WithdrawMoney/WithdrawMoney';

/**
 * Represents Withdraw Money in Mobile version
 * @returns {JSX.Element}
 */

const WithdrawMoneyMobile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/balance' />
        <p className={style.title}>{t('withdraw_money')}</p>
      </div>
      <p className={style.text}>{t('withdraw')}</p>
      <WithdrawMoney />
    </div>
  );
};

export default WithdrawMoneyMobile;
