import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import style from './CurrencyInput.module.scss';
import useOnClick from '../../hooks/useOnClick';

import { ReactComponent as ArrowUp } from '../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/common/arrowDown.svg';
import { ReactComponent as Bitcoin } from '../../assets/image/common/bitcoinCurrency.svg';
// eslint-disable-next-line max-len
import { ReactComponent as BitcoinGold } from '../../assets/image/common/bitcoinGoldCurrency.svg';
import { ReactComponent as Cosmos } from '../../assets/image/common/cosmosCurrency.svg';
import { ReactComponent as Qutum } from '../../assets/image/common/qutumCurrency.svg';
import { ReactComponent as Avalance } from '../../assets/image/common/avalanceCurrency.svg';

const options = [
  { id: 0, value: 'bitcoin', label: 'Bitcoin', icon: Bitcoin },
  { id: 1, value: 'bitcoinGold', label: 'BitcoinGold', icon: BitcoinGold },
  { id: 2, value: 'cosmos', label: 'Cosmos', icon: Cosmos },
  { id: 3, value: 'qutum', label: 'Qutum', icon: Qutum },
  { id: 4, value: 'avalance', label: 'Avalance', icon: Avalance },
];

/**
 * Represents Currency input component
 * @returns {JSX.Element}
 */

const CurrencyInput = () => {
  const { t } = useTranslation();
  const [currentCurrency, setCurrentCurrency] = useState(options[0]);
  const [showContent, setShowContent] = useState(false);
  const ref = useRef();

  const handleOnClick = () => {
    setShowContent(!showContent);
  };

  const handleClick = (id) => {
    setCurrentCurrency(options[id]);
    setShowContent(false);
  };

  useOnClick(ref, () => setShowContent(false));

  return (
    <div>
      <p className={style.currencyTitle}>{t('currency')}</p>
      <div className={style.currencyContainer} ref={ref}>
        <div
          className={style.currencyButton}
          role='button'
          tabIndex={-1}
          onClick={handleOnClick}
          onKeyDown={handleOnClick}
        >
          <div className={style.titleWrapper}>
            {options.map(({ id, icon: Icon }) => {
              return currentCurrency.id === id ? <Icon key={id} /> : null;
            })}
            <p className={style.label}>{currentCurrency.label}</p>
          </div>
          <i className={style.icon}>{showContent ? <ArrowUp /> : <ArrowDown />}</i>
        </div>
        <CSSTransition
          in={showContent}
          timeout={700}
          classNames={{
            enter: style.enter,
            enterActive: style.enterActive,
            enterDone: style.enterDone,
            exit: style.exit,
            exitActive: style.exitActive,
            exitDone: style.exitDone,
          }}
          unmountOnExit
        >
          <div className={style.currencyContent}>
            {options.map(({ id, icon: Icon, label }) => (
              <button
                type='button'
                className={style.optionButton}
                key={id}
                onClick={() => handleClick(id)}
              >
                <Icon />
                <p className={style.title}>{label}</p>
              </button>
            ))}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default CurrencyInput;
