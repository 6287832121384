import React, { useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import style from './Languages.module.scss';
import getAnimationClasses from '../../../../util/getAnimationClasses';
import { useRootModel } from '../../../../models/RootStore';
import { LANGUAGES } from '../../../../constant/commonConstants';
import AlertContext from '../../../../context/alert/alertContext';

import { ReactComponent as LanguagesIcon } from '../../../../assets/image/common/languages.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/image/common/arrowDown.svg';
import MenuItem from '../../../../components/ui/MenuItem/MenuItem';
import LanguageItem from './LanguageItem/LanguageItem';
import i18n from '../../../../translations/i18n';

/**
 * Represents Languages list
 * @returns {JSX.Element}
 */
const Languages = () => {
  const alert = useContext(AlertContext);
  const { t } = useTranslation();

  const {
    settings: { language, changeLanguage },
    user: { setUserLanguage, isAuthorized },
  } = useRootModel();

  const listRef = useRef(null);
  const currentLanguage = LANGUAGES[language];

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);

  const onClick = async (lang) => {
    let langKey;
    Object.entries(LANGUAGES).forEach((langItem) => {
      if (langItem.includes(lang)) {
        [langKey] = langItem;
      }
    });
    try {
      await changeLanguage(langKey);
      await i18n.changeLanguage(langKey);
      if (isAuthorized) {
        setUserLanguage(langKey);
      }
    } catch (e) {
      alert.show(t(e.message));
    } finally {
      handleClose();
    }
  };

  return (
    <div className={style.languages}>
      <button className={style.button} type='button' onClick={handleToggleOpen}>
        <MenuItem
          text={currentLanguage}
          iconLeft={LanguagesIcon}
          elementRight={ArrowDownIcon}
          elementRightClassName={style.rightElement}
        />
      </button>

      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames={getAnimationClasses(style)}
        unmountOnExit
      >
        <ul ref={listRef} className={style.list}>
          {Object.values(LANGUAGES).map((lang) => (
            <LanguageItem
              key={lang}
              language={lang}
              onClick={onClick}
              isActive={lang === currentLanguage}
            />
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default observer(Languages);
