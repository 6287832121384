import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import style from './ActiveSession.module.scss';
import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import useWindowSize from '../../../hooks/useWindowSize';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';

import Button from '../../../components/ui/Button/Button';

/**
 * Represents an active sessions component
 * @returns {JSX.Element}
 */
const ActiveSession = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const alert = useContext(AlertContext);
  const {
    user: { terminateActiveSessions },
  } = useRootModel();

  const handleOnClick = async () => {
    try {
      await terminateActiveSessions();
    } catch (e) {
      // todo error
      alert.show(t(e.message));
    }
  };
  return (
    <ErrorBoundary>
      <div className={style.activeSession}>
        <p className={style.text}>{t('terminated')}</p>
        <Button
          color='danger'
          text={t('logout_from_all')}
          size='medium'
          onClick={handleOnClick}
          width={isMobile ? 'full' : 'normal'}
          className={style.button}
        />
      </div>
    </ErrorBoundary>
  );
};

export default ActiveSession;
