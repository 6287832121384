import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './CurrentBalanceModal.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import CurrentBalance from '../../../CurrentBalance/CurrentBalance';

/**
 * Represents CurrentBalanceModal
 * @returns {JSX.Element}
 */
const CurrentBalanceModal = () => {
  const { t } = useTranslation();
  const {
    modal: {
      currentBalance: { isOpen, close },
    },
  } = useRootModel();

  return (
    <>
      {isOpen && (
        <Modal
          header
          headerTitle={t('current_balance')}
          handleClose={close}
          wrapperClassname={style.currentBalance}
          headerClassName={style.header}
        >
          <ModalBody className={style.body}>
            <CurrentBalance />
            <Link to='/balance/deposit' className={style.depositButton} onClick={close}>
              {t('deposit')}
            </Link>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(CurrentBalanceModal);
