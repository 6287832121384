import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { widget as Widget } from '../../charting_library-data/charting_library';
import datafeed from '../../charting_library-data/source/datafeed';

import style from './TVChart.module.scss';

import { THEME_LIGHT, DEFAULT_SYMBOL, DEFAULT_INTERVAL } from '../../constant/commonConstants';
import { useRootModel } from '../../models/RootStore';
import chartUtils from '../../util/chartUtils';
import eventSource from '../../eventSource/eventSource';
import Error from '../Error/Error';
import AlertContext from '../../context/alert/alertContext';
import useWindowSize from '../../hooks/useWindowSize';
// import Preloader from '../ui/Preloader/Preloader';

let fullMode = false;
const toggleFullMode = (full) => {
  fullMode = full === undefined ? !fullMode : full;
};

/**
 * Represents TVChart component
 * @param {string} symbol - active symbol
 * @param {string} interval - active interval
 * @param {string} containerId - containerId
 * @param {boolean} autosize - autosize
 * @param {boolean} fullscreen - fullscreen
 * @param {string} userId - userId
 * @param {string} theme - current theme
 * @param {boolean} isOpenTradeButton - if no need open trade button pass false
 * @param {boolean} isFullscreenButton - if no need fullscreen button pass false
 * @param {CSSStyleRule} className - className
 * @param {CSSStyleRule} wrapperClassName - container className
 * @param {boolean} needToCloseStream - if no need to close stream pass false
 * @param {string} language - current language
 * @returns {JSX.Element}
 */
const TVChart = ({
  symbol = DEFAULT_SYMBOL,
  interval = DEFAULT_INTERVAL,
  containerId = 'tv_chart_container',
  autosize,
  fullscreen,
  userId,
  theme,
  isOpenTradeButton = true,
  isFullscreenButton = true,
  className,
  wrapperClassName,
  needToCloseStream = true,
  language,
}) => {
  const [tvWidget, setTvWidget] = useState(null);
  const { isDesktop, isMobile } = useWindowSize();
  const {
    user: { userData },
    finance: { activeSymbol, activeInterval, graphError },
    modal: { openTrade },
    settings,
  } = useRootModel();
  const alert = useContext(AlertContext);
  // const [initialization, setInitialization] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // setInitialization(true);
      await chartUtils.changeSymbol(
        activeSymbol,
        activeInterval,
        theme || settings.theme,
        userId || userData?.id,
        isMobile,
      );
      // setTimeout(() => setInitialization(false), 2500);
    };
    fetchData();
  }, [activeSymbol]);

  if (!activeSymbol) return <Error />;

  const defaultProps = {
    symbol,
    interval,
    containerId,
    libraryPath: '/charting_library/',
    chartsStorageUrl: '',
    chartsStorageApiVersion: '1.1',
    clientId: 'trading',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  useEffect(() => {
    if (graphError) return null;
    let openTradeButtonElement;
    let tvWid;

    const fullscreenButtonCallback = async () => {
      if (fullMode) {
        await document.exitFullscreen();
        toggleFullMode(false);
        openTradeButtonElement.parentElement.parentElement.style.display = 'none';
      } else {
        tvWid.startFullscreen();
        toggleFullMode(true);
        openTradeButtonElement.parentElement.parentElement.style.display = 'flex';
      }
    };

    const load = async () => {
      const disabledFeatures = !isDesktop
        ? [
            'timeframes_toolbar',
            'left_toolbar',
            'legend_widget',
            'header_symbol_search',
            'header_compare',
            'header_indicators',
            'header_undo_redo',
            'header_saveload',
            'header_fullscreen_button',
            'header_screenshot',
            'header_settings',
            'symbol_search_hot_key',
          ]
        : [];
      const widgetOptions = {
        symbol,
        datafeed,
        interval: interval || defaultProps.interval,
        container: containerId || defaultProps.containerId,
        library_path: defaultProps.libraryPath,
        libraryPath: defaultProps.libraryPath,
        locale: language || 'en',
        disabled_features: [
          'use_localstorage_for_settings',
          'header_fullscreen_button',
          'header_symbol_search',
          'symbol_search_hot_key',
          ...disabledFeatures,
        ],
        enabled_features: [
          !isDesktop ? '' : 'study_templates',
          'side_toolbar_in_fullscreen_mode',
          'hide_left_toolbar_by_default',
          'header_in_fullscreen_mode',
        ],
        charts_storage_url: defaultProps.chartsStorageUrl,
        charts_storage_api_version: defaultProps.chartsStorageApiVersion,
        client_id: defaultProps.clientId,
        user_id: userId || defaultProps.userId,
        fullscreen: fullscreen || defaultProps.fullscreen,
        autosize: autosize === false ? autosize : defaultProps.autosize,
        studies_overrides: defaultProps.studiesOverrides,
        theme: theme === THEME_LIGHT ? 'Light' : 'Dark',
        custom_css_url: '../../source/TVChart.css',
        save_load_adapter: {
          charts: userId ? await chartUtils.getLayouts(userId, symbol) : [],
          studyTemplates: [],
          drawingTemplates: [],
          getAllCharts() {
            const { charts } = this;
            return Promise.resolve(charts);
          },
          async saveChart(chartData) {
            const { charts } = this;
            const chart = charts.find((ch) => ch.id === chartData.id);
            try {
              if (chart) {
                await chartUtils.changeLayout(userId, chartData.id, chartData);
                return Promise.resolve(chartData.id);
              }
              if (!chartData.id) {
                chartData.id = Math.random().toString();
              }
              chartData.timestamp = new Date().valueOf();

              const data = { ...chartData, resolution: interval };
              const response = await chartUtils.saveLayout(userId, data);

              charts.push(response);
              return Promise.resolve(response.id);
            } catch (err) {
              alert.show(err.message);
              return Promise.reject();
            }
          },
          removeChart(id) {
            try {
              const { charts } = this;
              for (let i = 0; i < charts.length; i += 1) {
                if (charts[i].id === id) {
                  charts.splice(i, 1);
                  chartUtils.removeLayout(userId, charts[i].id);
                  return Promise.resolve();
                }
              }
            } catch (err) {
              alert.show(err.message);
            }
            return Promise.reject();
          },
          getChartContent(id) {
            const { charts } = this;
            for (let i = 0; i < charts.length; i += 1) {
              if (charts[i].id === id) {
                return Promise.resolve(charts[i].content);
              }
            }
            return Promise.reject();
          },
          async getAllStudyTemplates() {
            try {
              if (!userId) return Promise.resolve([]);
              const templates = await chartUtils.getTemplates(userId);
              return Promise.resolve(templates);
            } catch (err) {
              alert.show(err.message);
              return Promise.reject();
            }
          },
          async getStudyTemplateContent(studyTemplateData) {
            const templates = await chartUtils.getTemplates(userId);
            for (let i = 0; i < templates.length; i += 1) {
              if (templates[i].name === studyTemplateData.name) {
                return Promise.resolve(templates[i].content);
              }
            }
            return Promise.reject();
          },
          async saveStudyTemplate(studyTemplateData) {
            try {
              await chartUtils.saveTemplate(
                userId,
                studyTemplateData.name,
                JSON.stringify(studyTemplateData),
              );
              return Promise.resolve();
            } catch (err) {
              alert.show(err.message);
              return Promise.reject();
            }
          },
          async removeStudyTemplate(studyTemplateData) {
            try {
              await chartUtils.removeTemplate(userId, studyTemplateData.name);
              return Promise.resolve();
            } catch (err) {
              alert.show(err.message);
              return Promise.reject();
            }
          },
          getDrawingTemplates() {
            const { drawingTemplates } = this;
            return Promise.resolve(drawingTemplates.map((template) => template.name));
          },
        },
      };

      tvWid = new Widget(widgetOptions);

      tvWid.onChartReady(() => {
        tvWid.headerReady().then(() => {
          if (isFullscreenButton) {
            chartUtils.createFullscreenButton(tvWid, fullscreenButtonCallback, theme);
          }
          if (isOpenTradeButton) {
            const openTradesCallback = async () => {
              openTrade.open();
              await document.exitFullscreen();
            };
            openTradeButtonElement = chartUtils.createOpenTradesButton(tvWid, openTradesCallback);
            openTradeButtonElement.parentElement.parentElement.style.display = 'none';
          }
          tvWid.getSavedCharts((records) => {
            setTimeout(() => {
              if (records.length) {
                tvWid?.loadChartFromServer(records[0]);
              }
              tvWid?.changeTheme(theme === THEME_LIGHT ? 'Light' : 'Dark', {
                disableUndo: true,
              });
            });
          });
        });
        // setInitialization(false);
      });

      if (!window.tvWidget) {
        window.tvWidget = tvWid;
      }

      setTvWidget(tvWid);
    };

    setTimeout(load);

    const fullscreenCallback = () => {
      if (document.fullscreenElement) return;
      if (openTradeButtonElement) {
        openTradeButtonElement.parentElement.parentElement.style.display = 'none';
      }
      toggleFullMode(false);
    };
    document.addEventListener('fullscreenchange', fullscreenCallback);

    return async () => {
      if (needToCloseStream) {
        await eventSource.closeSymbolStream();
        if (window.tvWidget) {
          await window.tvWidget.remove();
          delete window.tvWidget;
        }
      }
      document.removeEventListener('fullscreenchange', fullscreenCallback);
    };
  }, [language, userId, graphError]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const changeTheme = tvWidget?._innerWindow()?.changeTheme;
    if (changeTheme) {
      changeTheme.call(tvWidget, theme === THEME_LIGHT ? 'Light' : 'Dark', { disableUndo: true });
    }
  }, [theme, tvWidget, graphError]);

  // if (graphError) return <Preloader className={style.error} />;

  return (
    <div className={cn(style.tvChartWrapper, wrapperClassName)}>
      {/* {initialization && <Preloader className={style.loader} />} */}
      <div id={containerId || defaultProps.containerId} className={cn(style.tvChart, className)} />
    </div>
  );
};

export default observer(TVChart);
