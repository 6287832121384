import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import style from './Price.module.scss';
import TextInput from '../../../../../ui/TextInput/TextInput';
import NumberInput from '../../../../../ui/NumberInputWithDropdown/NumberInputWithDropdown';
import { useRootModel } from '../../../../../../models/RootStore';
import PriceInput from '../../../../../ui/PriceInput/PriceInput';
import { ReactComponent as Coin } from '../../../../../../assets/image/common/coin.svg';

/**
 * Represents Price Tab in Modal after click Open Trade Button
 * @returns {JSX.Element}
 */

const Price = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const {
    finance: {
      trade: {
        setAmount: collectAmount,
        setMultiplier: collectMultiplier,
        setPendingPrice,
        multipliedSum,
        maxMultiplier,
      },
    },
  } = useRootModel();

  const handleMultiplayerChange = (e) => {
    const { value } = e.target;

    if ((/^[0-9]+$/.test(value) && value <= maxMultiplier) || value === '') {
      setMultiplier(value);
    }
  };

  const handleMultiplayerBlur = () => {
    if (multiplier === '0' || multiplier === '') {
      setMultiplier(1);
    }
  };

  useEffect(() => {
    collectAmount(+amount);
    collectMultiplier(+multiplier);
  }, [multiplier, amount]);

  const handleChange = (value) => {
    setAmount(value);
  };

  useEffect(() => {
    return () => {
      setPendingPrice(null);
    };
  }, []);

  return (
    <div className={style.container}>
      <form>
        <PriceInput id='priceInputAmount' isLabel labelTitle={t('price')} />
        <NumberInput
          id='priceTradeAmount'
          onChange={handleChange}
          label
          labelTitle={t('trade_amount')}
        />
        <div className={style.wrapper}>
          <div className={style.label}>
            <label htmlFor='multiplayerPrice'>{t('multiplier')}</label>
            <p />
          </div>
          <TextInput
            id='multiplayerPrice'
            value={multiplier}
            name='multiplayer'
            onChange={handleMultiplayerChange}
            onBlur={handleMultiplayerBlur}
            inputClassName={style.input}
            leftIcon={() => 'x' || null}
            leftIconClassName={style.currencyIcon}
            inputMode='numeric'
            maxLength={3}
          />
        </div>
        <div className={style.wrapper}>
          <div className={style.label}>
            <p>{t('multiplied_sum')}</p>
            <p />
          </div>
          <TextInput
            id='multipliedSummPrice'
            inputClassName={style.inputDisabled}
            leftIcon={Coin}
            leftIconClassName={style.currencyIcon}
            inputMode='numeric'
            placeholder={numeral(multipliedSum).format('0,0.00')}
            disabled
          />
        </div>
      </form>
    </div>
  );
};

export default observer(Price);
