import { types, flow, getRoot, getParent } from 'mobx-state-tree';
import axios from 'axios';

import eventSourceUtil from '../../eventSource/eventSource';
import error from '../../util/error';
import sort from '../../util/sort';
import { DEFAULT_CATEGORY, DEFAULT_INTERVAL, DEFAULT_SYMBOL } from '../../constant/commonConstants';

import ActiveTradesModel from './TradesModels/ActiveTradesModel';
import Period from '../common/PeriodModel';
import PendingTradesModel from './TradesModels/PendingTradesModel';
import FavoriteTradesModel from './TradesModels/FavoriteTradesModel';
import InstrumentItemModel from './InstrumentItemModel';
import TradeModel from './TradeModel';
import ClosedTradesModel from './TradesModels/ClosedTradesModel';
import FlowOfFundsModel from './TradesModels/FlowOfFundsModel';
import NewsItemModel from './NewsItemModel';

const FinanceServiceModel = types
  .model('FinanceServiceModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    graphError: types.optional(types.string, ''),
    items: types.array(InstrumentItemModel),
    activeItem: types.optional(InstrumentItemModel, {}),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'asc'),
    activeSymbol: types.optional(types.string, DEFAULT_SYMBOL),
    activeInterval: types.optional(types.string, DEFAULT_INTERVAL),
    period: Period,
    traderSentiment: types.optional(types.number, 0),
    trade: types.optional(TradeModel, {}),
    activeTrades: types.optional(ActiveTradesModel, {}),
    pendingTrades: types.optional(PendingTradesModel, {}),
    favoriteTrades: types.optional(FavoriteTradesModel, {}),
    closedTrades: types.optional(ClosedTradesModel, {}),
    flowOfFunds: types.optional(FlowOfFundsModel, {}),
    currentCategory: types.optional(types.string, DEFAULT_CATEGORY),
    newsItems: types.array(NewsItemModel),
  })
  .actions((finance) => ({
    setError(err) {
      finance.error = err;
    },
    clearError() {
      finance.error = '';
    },
    setGraphError(err) {
      finance.graphError = err;
    },
    clearGraphError() {
      finance.graphError = '';
    },
    setIsLoading(loading) {
      finance.isLoading = loading;
    },
    setActiveSymbol(symbol) {
      if (finance.activeSymbol === symbol) return;
      finance.activeSymbol = String(symbol);
      const { getItem, getUpdateItem } = finance;
      getItem();
      getUpdateItem();
    },
    setActiveCategory(category) {
      finance.activeCategory = category;
    },
    setItems(items) {
      finance.items = items;
    },
    setCurrentCategory(category) {
      finance.currentCategory = category;
    },
    getData: flow(function* getData(category = DEFAULT_CATEGORY) {
      const { clearError, setItems, setError, setIsLoading, setCurrentCategory, setActiveSymbol } =
        finance;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`/services/trading/api/symbols/${category}`);
        setItems(data.map((item) => ({ ...item, oldPrice: item.lp })));
        setCurrentCategory(category);
        setActiveSymbol(data[0]?.s || DEFAULT_SYMBOL);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    updateData(data) {
      data.forEach((dataItem) => {
        const item = finance.items.find((i) => i.s === dataItem.s);
        item?.update(dataItem);
      });
    },
    getUpdateData() {
      const { search } = getParent(finance);
      const {
        clearError,
        setError,
        updateData,
        updateItem,
        // activeTrades,
        // favoriteTrades,
        // pendingTrades,
      } = finance;
      try {
        clearError();
        const eventSource = eventSourceUtil.createEventSource();
        eventSource.onmessage = async (e) => {
          const data = JSON.parse(e.data);
          const updatedArray = finance.items.map((i) => {
            const newInstrument = data.find((instrument) => instrument.s === i.s);
            const { item } = finance;

            if (newInstrument) {
              const newData = { ...i, ...newInstrument, alias: i.alias, oldPrice: i.lp };
              if (newInstrument.s === item?.s) {
                updateItem({ ...item, ...newData });
              }
              return { ...i, ...newInstrument, oldPrice: i.lp };
            }
            return { ...i, oldPrice: i.lp };
          });

          const handler = async (i) => {
            // await favoriteTrades.updateTrade(i);
            // await activeTrades.updateTrade(i);
            // await pendingTrades.updateTrade(i);
            await search.updateData(i);
          };
          updatedArray.forEach(await handler);
          await updateData(updatedArray);
        };
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      }
    },
    setSortBy(key) {
      finance.sortBy = key;
    },
    setSortDirection(direction) {
      finance.sortDirection = direction;
    },
    switchSortDirection() {
      finance.sortDirection = finance.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    closeDataStream() {
      eventSourceUtil.closeStream();
    },
    getItems: flow(function* getItems(filter) {
      const { setItems, setActiveSymbol, getData, setError, setIsLoading, clearError } = finance;
      setIsLoading(true);
      clearError();
      try {
        if (filter === 'crypto') {
          getData('crypto');
          return;
        }
        const {
          user: { isAuthorized },
        } = getRoot(finance);
        const { data } = yield axios.get(
          `/services/trading/api/symbols/top?criteria=${filter}&isAuthenticated=${isAuthorized}`,
        );
        if (data) {
          setItems(data);
          setActiveSymbol(data[0]?.s || DEFAULT_SYMBOL);
        }
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      }
    }),
    getItem: flow(function* getItem(_category) {
      const {
        activeSymbol,
        setError,
        items,
        setIsLoading,
        updateItem,
        clearError,
        clearGraphError,
        setGraphError,
        currentCategory,
      } = finance;

      const instrument = items.find((i) => i.s === activeSymbol);
      if (!instrument || !activeSymbol) return null;
      setIsLoading(true);
      clearError();
      clearGraphError();
      try {
        const category = _category || instrument?.type || currentCategory || DEFAULT_CATEGORY;
        const { data } = yield axios.get(
          `/services/trading/api/symbols/${category}/${activeSymbol}`,
        );
        updateItem(data);
        return data;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        setGraphError(message);
        error.errorHandler(message);
        setTimeout(() => finance.getItem(_category), 3000);
        return null;
      } finally {
        setIsLoading(false);
      }
    }),
    getUpdateItem(symbol) {
      if (!finance.item || !finance.item.type) return;
      const eventSourcePrev = eventSourceUtil.getEventSourceSymbol();
      const messageFunction =
        eventSourcePrev && eventSourcePrev.onmessage ? eventSourcePrev.onmessage : null;
      const event = eventSourceUtil.createEventSourceSymbol(
        symbol || finance.activeSymbol,
        finance.item.type,
      );
      event.onmessage = (e) => {
        if (messageFunction) messageFunction(e);
        const data = JSON.parse(e.data);
        const { item } = finance;
        const newData = { ...data, oldPrice: item?.oldPrice || data.lp };
        item?.update({ ...item, ...newData });
      };
    },
    clearData() {
      finance.setItems([]);
    },
    addItem(item) {
      finance.items = [...finance.items, item];
    },
    updateItem(data) {
      const { items, addItem } = finance;
      const item = items.find((i) => i.s === data.s);
      if (item) {
        item.update(data);
        return;
      }
      addItem(data);
    },
    closeItemStream() {
      eventSourceUtil.closeSymbolStream();
    },
    setActivePeriod(period) {
      finance.period = period;
    },
    getTraderSentiment: flow(function* getTraderSentiment() {
      try {
        const { currentCategory, activeSymbol } = finance;
        const { data } = yield axios.get(
          `/services/trading/api/symbols/${currentCategory}/${activeSymbol}/sentiment`,
        );
        finance.traderSentiment = data;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        finance.error = message;
        error.errorHandler(message);
      }
    }),
    getNews: flow(function* getNews() {
      try {
        const { activeSymbol } = finance;
        const { data } = yield axios.get(
          `/services/trading/api/symbols/news?symbolCode=${activeSymbol}`,
        );
        finance.newsItems = data;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        finance.error = message;
        error.errorHandler(message);
      }
    }),
  }))
  .views((finance) => ({
    get sortedData() {
      const { items, sortDirection, sortBy } = finance;
      if (!sortBy) return items;

      return sort.sortArrayBy(items, sortDirection, sortBy);
    },
    get activeHigh() {
      const { item, period } = finance;
      if (!item) return 0;
      const { h, hw, hm, hy } = item;
      const value = {
        day: h,
        week: hw,
        month: hm,
        year: hy,
      };
      return value[period];
    },
    get activeLow() {
      const { item, period } = finance;
      if (!item) return 0;
      const { l, lw, lm, ly } = item;
      const value = {
        day: l,
        week: lw,
        month: lm,
        year: ly,
      };
      return value[period];
    },
    get pricePercent() {
      const { activeLow, activeHigh, item } = finance;
      return ((item?.lp - activeLow) * 100) / (activeHigh - activeLow);
    },
    get item() {
      const { items, activeSymbol, getItem } = finance;
      const currentItem = items.find((i) => i.s === activeSymbol);
      if (currentItem) return currentItem;
      return getItem();
    },
  }));

export default FinanceServiceModel;
