import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './StatusInfoMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import StatusInfoMain from './StatusInfoMain/StatusInfoMain';
import StatusInfoSwitch from './StatusInfoSwitch/StatusInfoSwitch';
import { ReactComponent as InfoIcon } from '../../../../assets/image/common/info.svg';
import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody/ModalBody';
import LoyaltyRulesMobile from './LoyaltyRulesMobile/LoyaltyRulesMobile';

/**
 * Represents Loyalty statuses component in Mobile version
 * @returns {JSX.Element}
 */

const StatusInfoMobile = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton url='/profile/settings' />
        <p className={style.title}>{t('loyalty_statuses')}</p>
        <button type='button' className={style.info} onClick={handleOpenModal}>
          <InfoIcon />
        </button>
      </div>
      <StatusInfoMain />
      <StatusInfoSwitch />

      {showModal && (
        <Modal handleClose={handleCloseModal} wrapperClassname={style.modalWrapper}>
          <ModalBody className={style.modal}>
            <LoyaltyRulesMobile handleClose={handleCloseModal} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default StatusInfoMobile;
