import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import style from './BackButton.module.scss';
import { ReactComponent as BackIcon } from '../../../assets/image/common/arrowBack.svg';

/**
 * Represents BackButton for mobile
 * @param {string} url - url for return
 * @param {function} onClick - click handler
 * @param {CSSStyleRule} className - className for the link
 * @param {string} text - button text
 * @returns {JSX.Element}
 */

const BackButton = ({ url, onClick, className, text }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Link to={url || pathname} className={cn(style.backButton, className)} onClick={onClick}>
      <BackIcon />
      <span>{text ?? t('back')}</span>
    </Link>
  );
};

export default BackButton;
