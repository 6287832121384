import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import style from './Logo.module.scss';
import { ReactComponent as LogoIcon } from '../../../assets/image/common/logo.svg';

/**
 * Represents a logo
 * @param {CSSStyleRule} className - link className
 * @param {boolean} isLarge - is logo large
 * @returns {JSX.Element}
 */
const Logo = ({ className, isLarge = false }) => {
  return (
    <Link to='/' className={className}>
      <i className={cn(style.logoLink, { [style.big]: isLarge }, className)}>
        <LogoIcon />
      </i>
    </Link>
  );
};

export default Logo;
