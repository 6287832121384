import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import style from './AppDatePicker.module.scss';
import string from '../../../util/string';
import date from '../../../util/date';
import { ERROR_TEXT_DATE_NOT_VALID } from '../../../util/validationSchema';
import { DEFAULT_LANGUAGE } from '../../../constant/commonConstants';
import { useRootModel } from '../../../models/RootStore';

import DropdownWithInput from '../DropdownWithInput/DropdownWithInput';

/**
 * Represents App Date Picker
 * @param {function} onChange - on change handler
 * @param {Date} value - date
 * @returns {JSX.Element}
 */
const AppDatePicker = ({ onChange, value, className }) => {
  const { t } = useTranslation();
  const { getDays, getMonths, getMothTitle, VALID_START_DATE } = date;
  const {
    user: { user: userData },
    settings: { language },
  } = useRootModel();

  const [birthDate, setBirthDate] = useState(new Date(value || VALID_START_DATE));
  const [isError, setIsError] = useState(false);

  const langKey = userData?.langKey || language || DEFAULT_LANGUAGE;
  const years = date.getYears();
  const months = getMonths(langKey);
  const days = getDays(birthDate.getMonth(), birthDate.getFullYear());

  // todo Error message
  useEffect(() => {
    setIsError(birthDate > VALID_START_DATE);
  }, [birthDate]);

  useEffect(() => {
    if (onChange) onChange(birthDate);
  }, [birthDate]);

  // todo add check for Asian languages
  const isValidNumber = (val) => /^\d+$/.test(val);
  const isValidString = (val) => /^[a-zA-Z]+$/.test(val);

  const handleChangeDay = (day) => {
    const year = birthDate.getFullYear();
    const month = birthDate.getMonth();
    setBirthDate(new Date(year, month, day));
  };

  const handleChangeMonth = (month) => {
    const year = birthDate.getFullYear();
    const day = birthDate.getDate();
    setBirthDate(new Date(year, months.indexOf(string.toTitleString(month)), day));
  };

  const handleChangeYear = (year) => {
    const month = birthDate.getMonth();
    const day = birthDate.getDate();
    setBirthDate(new Date(year, month, day));
  };

  return (
    <div className={style.appDatePicker}>
      <p className={style.labelTitle}>{t('birth')}</p>
      <div className={cn(style.dateOfBirthButtonsWrapper, className)}>
        <DropdownWithInput
          value={birthDate.getDate()}
          onChange={handleChangeDay}
          handleValidate={isValidNumber}
          inputMode='numeric'
        >
          {days}
        </DropdownWithInput>

        <DropdownWithInput
          value={getMothTitle(birthDate.getMonth() + 1, langKey)}
          onChange={handleChangeMonth}
          handleValidate={isValidString}
          filter
        >
          {months}
        </DropdownWithInput>

        <DropdownWithInput
          value={birthDate.getFullYear()}
          onChange={handleChangeYear}
          handleValidate={isValidNumber}
          inputMode='numeric'
        >
          {years}
        </DropdownWithInput>
      </div>
      {isError && <p className={style.error}>{ERROR_TEXT_DATE_NOT_VALID}</p>}
    </div>
  );
};

export default observer(AppDatePicker);
