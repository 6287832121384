import React, { useRef } from 'react';
import cn from 'classnames';

import style from './Modal.module.scss';
import useExecutorForKey from '../../hooks/useExecutorForKey';
import useOnClick from '../../hooks/useOnClick';

import ModalHeader from './ModalHeader/ModalHeader';
import Portal from '../ui/Portal/Portal';

/**
 * Represents Modal popup
 * @param {function} handleClose - close handler
 * @param {boolean} header - need to have header
 * @param {string} headerTitle - title for header
 * @param {JSX.Element} children - modal children
 * @param {CSSStyleRule} wrapperClassname - className for wrapper
 * @param {CSSStyleRule} headerClassName - className for header
 * @returns {JSX.Element}
 */
const Modal = ({
  handleClose,
  header = false,
  headerTitle,
  children,
  wrapperClassname,
  headerClassName,
}) => {
  const modalRef = useRef(null);

  useExecutorForKey('Escape', handleClose);
  useOnClick(modalRef, handleClose);

  return (
    <Portal>
      <div className={style.modalLayout}>
        <div className={cn(style.modal, wrapperClassname)} ref={modalRef}>
          {header && (
            <ModalHeader
              title={headerTitle}
              handleClose={handleClose}
              wrapperClassName={headerClassName}
            />
          )}
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
